import React, { Component,useState } from "react";
import {FormLabel,FormGroup,Container,Row,Col,InputGroup,Form,Navbar, Nav, Alert,Button,Card,CardGroup,FormControl,Modal,Table} from "../../../../node_modules/react-bootstrap";
import {Tabs, Tab} from 'react-bootstrap-tabs';
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import '../../../assets/css/Style.css'
import {CommonController} from "../../../Controllers/CommonController";
import { FormCheckBox } from "../../CustomComponents/FormCheckBox";
import { FormInputs } from "../../CustomComponents/FormInput";
import { AuthStatus } from "../Common/AuthStatus"
import * as Utils from '../../../js/Utils.js';
import "react-datepicker/dist/react-datepicker.css";
import { IHotelParameter, Istate } from "../../../Model/IHotel";
import { HotelController } from "../../../Controllers/HotelController";
import DatePicker from 'react-datepicker';
import validator from 'validator';
import dateFormat from 'dateformat';
import DATE_DIFF from 'date-diff-js';
import { alert, confirm } from 'react-bootstrap-confirmation';
import { SubmitButton } from "../Common/SubmitButton";
import { format } from 'date-fns'
export class Hotel extends Component<IHotelParameter,Istate> {
    
    constructor(props:IHotelParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: {sessionTA: sessionStorage.getItem('ta'), hotelId: 0, hotel: '', city: '', ratePerNight: this.formatCurrency(0), tax: this.formatCurrency(0), incidentals: this.formatCurrency(0), total: this.formatCurrency(0), scAmount: 0, scDescription: '', bcdGenerated: false, bcdValidated: false, RLN: '', bcdGeneratedEditMode: false, deleteClicked: false, traveller: [], resv: [], comment: [], iTravelerCnt: 0, review: false, isReadOnly:false},
            selectfields:{hotelBillId:'-1',hotelBillDesc:''},
            checkfields: {nonDisputable:true, addSplCharges:false},
            errors:{travellerId:'', hotelBillId:'', hotel: '', city: '', checkIn: '', checkOut:'', ratePerNight:'', scAmount: '', scDescription: ''},
            loading:false,
            errMsg:"",
            modal: {modalSpinnerShow: false},
            BillingType:[],
            TATravelerList:[],
            TATravelerTable:[],
            travellerId:[],
            hotelId:[],
            hotelBooking: [],
            traveller: [],
            checkIn:new Date(new Date().toISOString()),
            checkOut:new Date(new Date().toISOString()),
        }   
        this.onSubmit = this.onSubmit.bind(this);   
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);   
        this.handleSelectMultipleChange = this.handleSelectMultipleChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleChangeCheckInDate = this.handleChangeCheckInDate.bind(this);
        this.handleChangeCheckOutDate = this.handleChangeCheckOutDate.bind(this);
        //this.convertToManual = this.convertToManual.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal});
        let hotelController = new HotelController();
        hotelController.getBillingType(sessionStorage.getItem("ta_is_corporate_card")!).then(jsonBillingType => {
            this.setState({                           
                BillingType : JSON.parse(JSON.stringify(jsonBillingType))
            });
            //console.log(this.state.BillingType);
            if(sessionStorage.getItem("ta_is_corporate_card") === "true"){
                let selectfields = this.state.selectfields;
                this.state.BillingType.map(prop => {
                    selectfields["hotelBillId"] = prop['id'];
                    selectfields["hotelBillDesc"] = prop["name"];
                })
                this.setState({selectfields});
            }
        }).catch(error => {
            modal['modalSpinnerShow']=false;
            this.setState({ modal});
            console.error("There was an error", error);
        })
        var data={
            id: '',
            view: 'hotel',
            bInner: 'false',
            sessionTA: this.state.fields.sessionTA
        }
        let commonController = new CommonController();
        commonController.getTATraveler(data).then(jsonTravellers => {
            this.setState({                           
                TATravelerList : JSON.parse(JSON.stringify(jsonTravellers)),
                TATravelerTable : JSON.parse(JSON.stringify(jsonTravellers)),
            });
            this.state.TATravelerTable.map((prop, key) => {
                let fields = this.state.fields;
                fields["traveller"][key] = prop['travelerId'];
                fields["resv"][key] = prop["RLN"];
                fields["comment"][key] = prop["specialInstructions"];
                this.setState({fields});
            })
            //console.log("TATravelerList, ",this.state.TATravelerList)
        }).catch(error => {
            modal['modalSpinnerShow']=false;
            this.setState({ modal});
            console.error("There was an error loading Traveler..., " + error)
        });
        this.pageLoad();
    }

    pageLoad(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        this.setState({ hotelBooking:[], traveller:[]})
        let hotelController = new HotelController();
        hotelController.getHotelBookingHotelId(this.state.fields.hotelId,this.state.fields.sessionTA).then(jsonHotelId => {
            this.setState({
                hotelId: JSON.parse(JSON.stringify(jsonHotelId))
            })
            //console.log("HotelId", this.state.hotelId)
            if(this.state.hotelId.length < 1){
                modal['modalSpinnerShow']=false;
                this.setState({ modal})
            }
            this.state.hotelId.map(prop => {
                hotelController.getBooking(this.state.fields.sessionTA,prop["hotelId"]).then(jsonHotelBooking => {
                    this.setState({                           
                        hotelBooking : [...this.state.hotelBooking, ...(JSON.parse(JSON.stringify(jsonHotelBooking)))] 
                    });
                    //console.log("hotelBooking", this.state.hotelBooking)
                }).catch(error => {
                    modal['modalSpinnerShow']=false;
                    this.setState({ modal});
                    console.error("There was an error loading hotel booking...",error)
                });
                let commonController = new CommonController();
                var data1 = {
                    id: prop["hotelId"],
                    view: "hotel",
                    bInner: true,
                    sessionTA: this.state.fields.sessionTA
                }
                commonController.getTATraveler(data1).then(jsonTravellers => {
                    this.setState({                           
                        traveller : [...this.state.traveller, ...(JSON.parse(JSON.stringify(jsonTravellers)))]
                    });
                    //console.log("traveller", this.state.traveller)
                    modal['modalSpinnerShow']=false;
                    this.setState({ modal});
                }).catch(error => {
                    modal['modalSpinnerShow']=false;
                    this.setState({ modal});
                    console.error("There was an error loading traveller..., " + error)
                });
            })
        }).catch(error => {
            modal['modalSpinnerShow']=false;
            this.setState({ modal});
            console.error("There was an error loading HotelId...",error)
        });
    }

    handleChange(e:any){
        let fields = this.state.fields;
        if (e.currentTarget.name === 'scAmount'){
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        else{
            if (e.currentTarget.value.trim().length > 0){
                fields[e.currentTarget.name] = e.currentTarget.value;
            }
            else{
                fields[e.currentTarget.name] = e.currentTarget.value.trim();
            }
        }
        this.setState({ fields });
    }

    handleFormChange = (i,e:any) => {
        let fields = this.state.fields;
        if (e.currentTarget.value.trim().length > 0)
        {
            fields[e.currentTarget.name][i] = e.currentTarget.value;
        }
        else
        {
            fields[e.currentTarget.name][i] = e.currentTarget.value.trim();
        }
        this.setState({ fields });
    }

    handleSelectChange(e:any) {
        let selectfields = this.state.selectfields;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        if(e.currentTarget.name === 'hotelBillId'){
            selectfields["hotelBillName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
            if(e.currentTarget.options[e.currentTarget.selectedIndex].value === "ALL"){
                alert("All incidentals will be paid regardless of the dollar amount on the TA.",{title:"eTA - Hotel"});
            }
            else if(e.currentTarget.options[e.currentTarget.selectedIndex].value === "RAT"){
                alert("Please notify your traveler(s) that a personal credit card or cash deposit will usually be required by the hotel at Check-In in order to cover any incidentals.",{title:"eTA - Hotel"})
            }
        }
        this.setState({ selectfields });
    }

    handleSelectMultipleChange(e:any){
        var options = e.currentTarget.options;
        var value : string[] = [];
        var element:HTMLElement | null;
        
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                element=document.getElementById("div"+options[i].value) as HTMLElement
                if (element !== null){
                    element.style.display = "";
                }
                value.push(options[i].value);
            }
            else{
                element=document.getElementById("div"+options[i].value) as HTMLElement
                if (element !== null){
                    element.style.display = "none";
                }
            }
        }
        
        if(e.currentTarget.name === 'travellerId'){
            this.setState({travellerId: value});
        }
    }

    handleCheckChange(e:any){
        let checkfields = this.state.checkfields;
        let fields = this.state.fields;
        if (e.currentTarget.name === "addSplCharges") {
            checkfields[e.currentTarget.name] = !this.state.checkfields.addSplCharges
        }
        else
        {
            checkfields[e.currentTarget.name] = !this.state.checkfields.nonDisputable;
        }
        const el:HTMLElement = document.getElementById('splCharges') as HTMLElement;
        if(checkfields["addSplCharges"]){
            el.style.display = "block";
        }
        else
        {
            fields["scDescription"] = "";
            fields["scAmount"] = 0;
            el.style.display = "none";
        }
        this.setState({
            checkfields,fields
        });
    }

    handleChangeCheckInDate = date => {
       // var today = new Date();
        this.setState({
          checkIn: date
        });
if(date!=null){

    const today = new Date(); // Current date
    const selectedDate = new Date(date); // Convert the selected date to a Date object

    // Standardize to UTC for date-only comparison (ignores time)
    const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
    const selectedDateUTC = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));


        // if (format(today, "MM/dd/yyyy") > format(date, "MM/dd/yyyy")) {
        //     let errors = this.state.errors;
        //     errors["checkIn"] = "The date you have entered has already past";
        //   } 
          
        if (todayUTC > selectedDateUTC) {
            let errors = this.state.errors;
            errors["checkIn"] = "The date you have entered has already past";
        }
          
          else {
            this.clearError();
          }
        }

    }

    handleChangeCheckOutDate = date => {
       // var today = new Date();
        this.setState({
          checkOut: date
        });
        if(date!=null){
        // if (format(today, "MM/dd/yyyy") > format(date, "MM/dd/yyyy")) {
        //     let errors = this.state.errors;
        //     errors["checkOut"] = "The date you have entered has already past";
        //   } 
          
        const today = new Date(); // Current date
        const selectedDate = new Date(date); // Convert the selected date to a Date object
    
        // Standardize to UTC for date-only comparison (ignores time)
        const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
        const selectedDateUTC = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));
    
              
            if (todayUTC > selectedDateUTC) {
                let errors = this.state.errors;
                errors["checkOut"] = "The date you have entered has already past";
            }
          else {
            this.clearError();
          } }
    }

    clearError(){
        let errors = this.state.errors;
        errors["travellerId"] = "";
        errors["hotelBillId"] = "";
        errors["hotel"] = "";
        errors["city"] = "";
        errors["checkIn"] = "";
        errors["checkOut"] = "";
        errors["ratePerNight"] = "";
        errors["numberOfDays"] = "";
        errors["scAmount"] = "";
        errors["scDescription"] = "";
        this.setState({ errors });
    }

    validateForm() {
        this.clearError();
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        let errors = this.state.errors;
        let modal = this.state.modal;
        let formIsValid = true;
        //console.log(selectfields["hotelBillId"])
        //console.log(selectfields["hotelBillDesc"])
        if(this.state.travellerId.length === 0){
            formIsValid = false;
            this.state.errors["travellerId"] = "Please select traveller";
        }
        if(selectfields["hotelBillId"] === "-1"){
            formIsValid = false;
            errors["hotelBillId"] = "Please Select a Billing Type";
        }
        if(!fields["hotel"]){
            formIsValid = false;
            errors["hotel"] = "Please Enter a Hotel";
        }
        if(!fields["city"]){
            formIsValid = false;
            errors["city"] = "Please Enter a City";
        }

        // console.log(this.state.checkIn,"yyyy/mm/dd","testdate")
        if(!validator.isDate(dateFormat(this.state.checkIn,"yyyy/mm/dd"))){
            formIsValid = false;
            errors["checkIn"] = "Please enter a valid date";
        }
        if(!validator.isDate(dateFormat(this.state.checkOut,"yyyy/mm/dd"))){
            formIsValid = false;
            errors["checkOut"] = "Please enter a valid date";
        }
        // if(validator.isBefore(dateFormat(this.state.checkIn,"yyyy/mm/dd")) && dateFormat(this.state.checkIn,"yyyy/mm/dd") != dateFormat(Date.now(),"yyyy/mm/dd")){
        //     formIsValid = false;
        //     errors["checkIn"] = "The date you have entered has already past";
        // }
        // if(validator.isBefore(dateFormat(this.state.checkOut,"yyyy/mm/dd")) && dateFormat(this.state.checkOut,"yyyy/mm/dd") != dateFormat(Date.now(),"yyyy/mm/dd")){
        //     formIsValid = false;
        //     errors["checkOut"] = "The date you have entered has already past";
        // }
        if(!validator.isAfter(dateFormat(this.state.checkOut,"yyyy/mm/dd"),dateFormat(this.state.checkIn,"yyyy/mm/dd")) && dateFormat(this.state.checkOut,"yyyy/mm/dd") != dateFormat(this.state.checkIn,"yyyy/mm/dd")){
            formIsValid = false;
            errors["checkOut"] = "The Check Out date must be greater than the Check In date";
        }
        var msg = Utils.validMoneyNotZero(fields["ratePerNight"].replace('$',''),"Please enter a valid rate");
        if(msg !== ""){
            formIsValid = false;
            errors["ratePerNight"] = msg;
        }
        /*
        if(fields["scDescription"] !== ""){
            msg = Utils.validMoney(fields["scAmount"],"Please Enter a Valid Special Charge");
            if(msg !== ""){
                formIsValid = false;
                errors["scAmount"] = msg;
            }
            else{
                if(parseFloat(fields["scAmount"]) === 0){
                    formIsValid = false;
                    errors["scAmount"] = "Please Enter a Special Charge";
                }
            }
        }
        msg = Utils.validMoney(fields["scAmount"],"Please Enter a Valid Special Charge");
        if(msg !== ""){
            formIsValid = false;
            errors["scAmount"] = msg;
        }
        else{
            if(parseFloat(fields["scAmount"]) !== 0 && fields["scDescription"] === ""){
                formIsValid = false;
                errors["scDescription"] = "Please Enter a Special Charge Explanation";
            }
            else{
                if(fields["scDescription"].length > 300){
                    formIsValid = false;
                    errors["scDescription"] = "Special Charge Explanation must be no more than 300 characters";
                }
            }
        }*/
        if(!formIsValid){
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
        }
        this.setState({ errors });
        return formIsValid;
    }

    onSubmit(e:any){
        
        e.preventDefault();
        let action = e.currentTarget.id;
        let fields = this.state.fields;
        let modal = this.state.modal;
        modal["modalSpinnerShow"] = true;
        fields["action"] = action;
        
        this.setState({ fields, modal })
        if(action === "copyResv"){
            this.userProcessCopy();
        }
        else if(action === "addResv"){
            this.cancelReview();
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
        }
        else{
            if(this.validateForm()){
                let fields = this.state.fields;
                fields["isReadOnly"] = false;
                var rate = parseFloat(fields["ratePerNight"].replace('$',''));
                fields["tax"] = rate * 0.3;
                if(fields["tax"] < rate * 0.3){
                    fields["tax"] = fields["tax"] + 1;
                }
                if(this.state.selectfields.hotelBillId === "ALL"){
                    fields["incidentals"] = rate * 0.3;
                    if(fields["incidentals"] < rate * 0.3){
                        fields["incidentals"] = fields["incidentals"] + 1;
                    }
                }
                else{
                    fields["incidentals"] = 0;
                }
                var datediff = DATE_DIFF(this.state.checkOut, this.state.checkIn, 'D').output;
                //console.log("rate",rate);
                //console.log("tax",fields["tax"]);
                //console.log("incidentals",fields["incidentals"]);
                //console.log("datediff",datediff);
                if(datediff === 0){
                    datediff = 1;
                }
                fields["total"] = (rate + fields["tax"] + fields["incidentals"]) * datediff;
                //console.log("total",fields["total"]);
                this.setState({ fields });
                if(action === "saveResv"){
                    this.userProcessInsert();
                }
                else if(action === "saveCopyResv"  || action === "updateResv"){
                    const el:HTMLElement = document.getElementById('convertBCDToManual') as HTMLElement;
                    el.style.display = "none";
                    this.userProcessUpdate();
                }
            }
        }
    }

    userProcessInsert(){
        let modal = this.state.modal;
        let hotelController = new HotelController();
        var insertData = {
            authreqId: this.state.fields.sessionTA,
            hotelBillId: this.state.selectfields.hotelBillId,
            name: this.state.fields.hotel,
            city: this.state.fields.city,
            inDate:format(this.state.checkIn, "MM/dd/yyyy") ,
            outDate: format(this.state.checkOut, "MM/dd/yyyy"),    
            nonDisputable: this.state.checkfields.nonDisputable,
            rate: parseFloat(this.state.fields.ratePerNight.replace('$','')),
            tax: this.state.fields.tax,
            incidentals: this.state.fields.incidentals,
            total: this.state.fields.total,
            scAmount: (this.state.checkfields.addSplCharges)?this.state.fields.scAmount:0,
            scDesc: (this.state.checkfields.addSplCharges)?this.state.fields.scDescription:""   
        };
        //console.log("insert Data", insertData);
        hotelController.userProcessInsert(insertData).then(response => {
            //console.log("insert response", response);
            let fields = this.state.fields;
            fields["hotelId"] = response.hotelId;
            this.setState({ fields });
            this.bookingMap("");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
    }

    userProcessCopy(){
        let modal = this.state.modal;
        const copyId = this.state.fields.hotelId;
        let hotelController = new HotelController();
        var copyData = {
            authreqId: this.state.fields.sessionTA,
            copyId: copyId,   
        };
        //console.log("copyResv data", copyData);
        hotelController.userProcessCopy(copyData).then(response => {
            //console.log("copy response", response);
            let fields = this.state.fields;
            fields["hotelId"] = response.hotelId;
            this.setState({ fields });
            this.bookingMap(copyId);
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
    }

    userProcessUpdate(){
        let modal = this.state.modal;
        let hotelController = new HotelController();
        var updateData = {
            hotelBillId: this.state.selectfields.hotelBillId,
            name: this.state.fields.hotel,
            city: this.state.fields.city,
            inDate: format(this.state.checkIn, "MM/dd/yyyy"),
            outDate: format(this.state.checkOut, "MM/dd/yyyy"),
            nonDisputable: this.state.checkfields.nonDisputable,
            rate: parseFloat(this.state.fields.ratePerNight.replace('$','')),
            tax: this.state.fields.tax,
            incidentals: this.state.fields.incidentals,
            total: this.state.fields.total,
            scAmount: (this.state.checkfields.addSplCharges)?this.state.fields.scAmount:0,
            scDesc: (this.state.checkfields.addSplCharges)?this.state.fields.scDescription:"",
            hotelId: this.state.fields.hotelId   
        };
        //console.log("update Data Request", updateData);
        hotelController.userProcessUpdate(updateData).then(response => {
            //console.log("update response", response);
            this.bookingMap("");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
    }

    bookingMap(copyId){
        let modal = this.state.modal;
        var travellerIdStr = '';
        this.state.travellerId.map(prop => {
            if(travellerIdStr === ''){
                travellerIdStr = prop;
            }
            else{
                travellerIdStr = travellerIdStr + ',' + prop;
            }
        })
        //console.log("travellerIdStr", travellerIdStr);
        var bookingMapData = {
            travellerId: travellerIdStr,
            hotelId: this.state.fields.hotelId,
            copyId: copyId
        }
        //console.log("hotel bookingMapData", bookingMapData);
        let hotelController = new HotelController();
        hotelController.bookingMap(bookingMapData).then(response => {
            //console.log("hotel Booking map response", response);
            if(copyId === ''){
                this.state.travellerId.map((prop,key) => {
                    var i = this.state.fields.traveller.indexOf(parseInt(prop));
                    var updateData = {
                        travellerId: prop,
                        hotelId: this.state.fields.hotelId,
                        specialIns: this.state.fields.comment[i]
                    }
                    //console.log("hotel updatedata", updateData)
                    hotelController.updateSpecialIns(updateData).then(response => {
                        //console.log("hotel updateSpecialIns", response)
                        if(this.state.travellerId.length -1 === key){
                            if(this.state.fields.action === "saveCopyResv"){
                                let fields = this.state.fields;
                                fields["action"] = ''
                                this.setState({fields});
                                this.userProcessCopy();
                            }
                            else{
                                //console.log("Hotel Booking Id: ", this.state.fields.hotelId)
                                //alert("Hotel Booking completed successfully...")
                                this.cancelReview();
                                this.pageLoad();
                                modal["modalSpinnerShow"] = false;
                                this.setState({modal});
                            }
                        }
                    }).catch(error => {
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});
                        console.error("There was an error...", error);
                    })
                })
            }
            else
            {
                this.cancelReview();
                this.pageLoad();
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
            }
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
    }

    loadReview(hotelId){
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        this.clearError();
        let fields = this.state.fields;
        fields["review"] = true;
        fields["hotelId"] = hotelId;
        fields["action"] = "review";
        this.setState({ fields, modal });
        this.getBooking(hotelId, true);
        this.getTATraveler(hotelId, true);
    }

    cancelReview(){
        const el:HTMLElement = document.getElementById('convertBCDToManual') as HTMLElement;
        el.style.display = "none";
        this.clearError();
        let fields = this.state.fields;
        fields["review"] = false;
        fields["isReadOnly"] = false;
        this.setState({fields});
        this.clearBookingEntryfields();
        this.setState({TATravelerTable: this.state.TATravelerList, travellerId:[]});
        this.state.TATravelerList.map((prop, key) => {
            let fields = this.state.fields;
            fields["traveller"][key] = prop['travelerId'];
            fields["resv"][key] = prop["RLN"];
            fields["comment"][key] = prop["specialInstructions"];
            this.setState({fields});
        })

    }

    async deleteBooking(hotelId){
        let modal = this.state.modal;
        var flag = await confirm("Do you want to continue?",{title:"Confirm Delete", okText:"Yes", cancelText:"No"})
        //console.log("delete flag", flag);
        if(flag){
            let commonController = new CommonController();
            commonController.deleteAuthReq("","",hotelId,"","").then(response => {
                //console.log("deleteBooking", response)
                //console.log("Delete Action Id: ", hotelId)
                //alert("Delete action completed...")
                this.pageLoad();
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error...", error);
            })
        }
    }

    formatCurrency(amount){
        var floatamount = parseFloat(amount);
        return floatamount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    getBooking(hotelId, editMode){
        let modal = this.state.modal;
        let hotelController = new HotelController();
        hotelController.getBooking(this.state.fields.sessionTA, hotelId).then(jsonHotelBooking => {
            //console.log("Review :", jsonHotelBooking);
            this.fillBookingDetails(jsonHotelBooking, editMode);
        }).catch(error => {
            modal['modalSpinnerShow'] = false;
            this.setState(modal);
            console.error("There was an error loading Personal Contact..., " + error)
        });
    }  
    retrieveControls(){
        
        var taStatus = sessionStorage.getItem("ta_status_desc");
        var bcdResubmit = false;
        var IsReadonly = true;

        // if BCD generated reservation
        if (this.state.fields.bcdGeneratedEditMode && this.state.fields.action !== "deleteResv" && this.state.fields.hotelId !== 0 && (this.state.fields.action !== "updateResv" && this.state.fields.action !== ""))
        {   
            const el:HTMLElement = document.getElementById('convertBCDToManual') as HTMLElement;
            el.style.display = "block";

            let fields = this.state.fields;
            let commonController = new CommonController();
            commonController.getApproverHistoryBCD(this.state.fields.sessionTA).then(response => {
                
                bcdResubmit = (response.success)?true:false;
               
                // if TA is in BCD resubmit status 
	            if(taStatus === "Re-Submit" && bcdResubmit){
                    IsReadonly = false;
                    fields['isReadOnly'] = false;
                }

                if(IsReadonly){
                    fields['isReadOnly'] = true;
                }
                this.setState({fields})
            }).catch(error => {
                console.error("There was an error...");
            })
        }
        else
        {
            const el:HTMLElement = document.getElementById('convertBCDToManual') as HTMLElement;
            el.style.display = "none";
        }
    }
    async convertToManual(hotelId,authReqId){
        let modal = this.state.modal;
        
        var flag = await confirm("Are you sure you want to convert this CTM retrieved reservation to Manual? Click Yes to convert reservation, Click No to cancel.",{title:"Confirm Conversion", okText:"Yes", cancelText:"No"})
        if(flag){

            let hotelController = new HotelController();
            hotelController.updateBCDToManual(hotelId,authReqId).then(response => {
                let fields = this.state.fields;
                fields["isReadOnly"] = false;
                this.setState({fields});
                const el:HTMLElement = document.getElementById('convertBCDToManual') as HTMLElement;
                el.style.display = "none";
                //console.log("updateBCDToManual Response", response)
            }).catch(error => {
                modal['modalSpinnerShow']=false;
                this.setState({ modal});
                console.error("There was an error...", error);
            })
        }
    }
    getTATraveler(hotelId, bInner){
        let modal = this.state.modal;
        var data={
            id: hotelId,
            view:'hotel',
            bInner: bInner,
            sessionTA: this.state.fields.sessionTA
        }
        let commonController = new CommonController();
        commonController.getTATraveler(data).then(jsonTravellers => {
            //console.log("JsonTravellers", jsonTravellers);
            var travellerId: any= [];
            jsonTravellers.map(prop => {
                travellerId.push(prop["travelerId"]);
            })
            this.setState({travellerId : travellerId, TATravelerTable : JSON.parse(JSON.stringify(jsonTravellers))})
            /*
            var tempTraveller : Array<{"id": string; "travellerId": string; "isDH": string; "address": string; "city": string; "stateProvinceId": string; "zip": string; "phone": string; "ssn": string; "type": string; "issuer": string; "callLetters": string; "travelPurpose": string; "fedId": string; "radioLetterReceived": string; "overrideReceived": string; "preapprovalReceived": string; "firstName": string; "lastName": string; "name": string; "formOnFile": string; "travelRestrictions": string; "RLN": string; "airfareAmount": string; "feeAmount": string; "totalAmount": string; "bit": boolean; "specialInstructions": string}> = [];
            jsonTravellers.map((item,i) => {
                var count = 0;
                this.state.TATravelerTable.map((prop,key) => {
                    if(item["travelerId"] != prop["travelerId"]){
                        count = count + 1;
                    }
                    if(key + 1 === this.state.TATravelerTable.length){
                        if(count >= 1){
                            tempTraveller.push(prop);
                        }
                    }
                })
            })
            //console.log("tempTraveller", tempTraveller)
            tempTraveller.map(prop => jsonTravellers.push(prop));
            this.setState({                           
                TATravelerTable : jsonTravellers
            });
*/
            //console.log("**********************-1")
            //console.log("before TATravelerTable:", this.state.TATravelerTable);
            //console.log("before TATravelerList:", this.state.TATravelerList);
            this.state.TATravelerList.map((item,i) => {
                var filterTraveller = jsonTravellers.filter(f=>f.travelerId == item.travelerId).length;
                
                //console.log("****************-outside Length:"+filterTraveller, i)
                if(filterTraveller === 0){
                    //console.log("****************-inside")
                    //console.log("****************-inside TravellerID:", item.travelerId)
                    this.state.TATravelerTable.push(item);
                }
            });           
            //console.log("after TATravelerTable:", this.state.TATravelerTable);
            //console.log("**********************-2")
            this.state.TATravelerTable.map((prop, key) => {
                let fields = this.state.fields;
                fields["traveller"][key] = prop['travelerId'];
                fields["resv"][key] = prop["RLN"];
                fields["comment"][key] = prop["specialInstructions"];
                this.setState({fields});
            })
            modal['modalSpinnerShow'] = false;
            this.setState(modal);
        }).catch(error => {
            modal['modalSpinnerShow'] = false;
            this.setState(modal);
            console.error("There was an error loading Traveler..., " + error)
        });
    }

    clearBookingEntryfields(){
        let fields = this.state.fields;
        let selectfields = this.state.fields;
        let checkfields = this.state.fields;
        fields['hotelId'] = 0;
        if(sessionStorage.getItem("ta_is_corporate_card") === "false"){
            selectfields['hotelBillId'] = "-1";
            selectfields['hotelBillDesc'] = "";   
        }
        fields['hotel'] = "";
        fields['city'] = "";
        fields['ratePerNight'] = this.formatCurrency(0);
        fields['tax'] = this.formatCurrency(0);
        fields['incidentals'] = this.formatCurrency(0);
        fields['total'] = this.formatCurrency(0);
        checkfields['nonDisputable'] = true;
        fields['scAmount'] = this.formatCurrency(0);
        fields['scDescription'] = "";
        fields['bcdGenerated'] = false;
        fields['bcdValidated'] = false;
        fields['RLN'] = "";
        this.setState({ fields, selectfields, checkfields, checkIn: new Date(new Date().toISOString()), checkOut: new Date(new Date().toISOString())});
    }

    fillBookingDetails(hotelBooking, editMode:boolean){
        let fields = this.state.fields;
        let selectfields = this.state.fields;
        let checkfields = this.state.fields;
        if (hotelBooking.length > 0){            
            hotelBooking.map((prop, key) => {
                if(key === 0){
                    fields['hotelId'] = prop["hotelId"];
                    selectfields['hotelBillId'] = prop["hotelBillId"];
                    selectfields['hotelBillDesc'] = prop["hotelBillDesc"];
                    fields['hotel'] = prop["hotel"];
                    fields['city'] = prop["city"];
                    fields['ratePerNight'] = this.formatCurrency(prop["rate"]);
                    fields['tax'] = this.formatCurrency(prop["tax"]);
                    fields['incidentals'] = this.formatCurrency(prop["incidentals"]);
                    fields['total'] = this.formatCurrency(prop["total"]);
                    checkfields['nonDisputable'] = prop["nonDisputable"];
                    if (prop["scDescription"] !== "" || prop["scAmount"] > 0){
                        checkfields["addSplCharges"] = true;
                    }
                    else
                    {
                        checkfields["addSplCharges"] = false;
                    }
                    fields['scAmount'] = this.formatCurrency(prop["scAmount"]);
                    fields['scDescription'] = prop["scDescription"];
                    fields['bcdGenerated'] = prop["bcdGenerated"];
                    fields['bcdValidated'] = prop["bcdValidated"];
                    fields['RLN'] = prop["RLN"];
                    if (editMode){
                        fields['bcdGeneratedEditMode'] = prop["bcdGenerated"]
                    }
                    if (this.state.fields.action === 'review'){
                        this.retrieveControls();
                    }
                    var inDate, outDate;
                    inDate = prop["inDate"].substring(0,10).split('-')
                    inDate = inDate[1] + '-' + inDate[2] + '-' + inDate[0]
                    outDate = prop["outDate"].substring(0,10).split('-')
                    outDate = outDate[1] + '-' + outDate[2] + '-' + outDate[0]
                    this.setState({ fields, selectfields, checkfields, checkIn: new Date(inDate), checkOut: new Date(outDate)});
                    const el:HTMLElement = document.getElementById('splCharges') as HTMLElement;
                    if(checkfields["addSplCharges"]){
                        el.style.display = "block";
                    }
                    else
                    {
                        fields["scDescription"] = "";
                        fields["scAmount"] = 0;
                        el.style.display = "none";
                    }
                }
            })
        }
        else{
            this.clearBookingEntryfields();
            if (editMode){
                fields['bcdGeneratedEditMode'] = 0;
            }
            this.setState({ fields});
        }
    }

    loadTravelerReservation(){
        return <div id="divReservation" style={{width: '100%'}}>
            <Row>
                <Col sm={12}>
                    {this.state.hotelId.map((prop,key) => {
                        var count = 0;
                        return this.state.fields.hotelId != prop["hotelId"] ?(
                            <table>
                                <tbody>
                                    {this.loadTravelerReservationHeader()}
                                    {this.state.hotelBooking.map((el, i) => {
                                        if(prop["hotelId"] === el["hotelId"]){
                                            count = count + 1;
                                        }
                                        var name = '';
                                        this.state.traveller.map((item,j) => {
                                            if(el["hotelId"] === item["id"]){
                                                if(name === ''){
                                                    name = item["name"];
                                                }
                                                else{
                                                    name = name +" / "+ item["name"];
                                                }
                                            }
                                        })
                                        return prop["hotelId"] === el["hotelId"] && count <= 1 ? this.loadTravelerReservationBody(el,i,name) : <tr></tr>
                                    })}
                                </tbody>
                            </table>
                        )
                        : <></>     
                    })}
                </Col>
            </Row>
        </div>
    }

    loadTravelerReservationHeader(){
        return <tr style={{width:"100%", borderTop:"1px solid navy"}}> 
            <td style={{width:"10%"}}><b>Billing Type</b></td>
            <td style={{width:"10%"}}><b>Non-Disputable</b></td>           
            <td style={{width:"7.5%"}}><b>Hotel</b></td>
            <td style={{width:"7.5%"}}><b>City</b></td>
            <td style={{width:"7.5%"}}><b>Check In</b></td>
            <td style={{width:"7.5%"}}><b>Check Out</b></td>
            <td style={{width:"10%"}}><b>Rate</b></td>
            <td style={{width:"10%"}}><b>Traveler</b></td>
            <td style={{width:"7.5%"}}><b>Resv Type</b></td>
            <td style={{width:"10%"}}><b>Resv #</b></td>
            <td style={{width:"12.5%"}}></td>
        </tr>
    }

    loadTravelerReservationBody(prop, key, name){
        return <tr>
            <td style={{borderTop: "0px"}}>{prop["hotelBillDesc"]}</td>
            <td style={{borderTop: "0px"}}>{(prop["nonDisputable"])?"Yes":"No"}</td>
            <td style={{borderTop: "0px"}}>{prop["hotel"]}</td>
            <td style={{borderTop: "0px"}}>{prop["city"]}</td>
            <td style={{borderTop: "0px"}}>{Utils.FormatDate(prop["inDate"])}</td>
            <td style={{borderTop: "0px"}}>{Utils.FormatDate(prop["outDate"])}</td>
            <td style={{borderTop: "0px"}}>{this.formatCurrency(prop["rate"])}</td>
            <td>{name}</td>
            <td style={{borderTop: "0px"}}>{(!prop["bcdGenerated"])?"Manual":"CTM"}</td>
            <td style={{borderTop: "0px"}}>{prop["RLN"]}</td>
            <td style={{textAlign:"right"}}>
                <Button type="button" variant="danger" className="btn btn-danger btn-sm" value="Delete" title="Click to delete this reservation" id="buttonDelete" name="buttonDelete" onClick={(e) => this.deleteBooking(prop["hotelId"])}>Delete</Button> &nbsp;
                <Button type="button" variant="secondary" className="btn btn-secondary btn-sm" style={{marginLeft:"2px"}} value="Review" title="Click to edit this reservation" id="buttonReview" name="buttonReview" onClick={(e) => this.loadReview(prop["hotelId"])}>Review</Button>
            </td>
        </tr>
    }
    displayConvertBCDToManual(){
        const { loading } = this.state;
        return <div id="convertBCDToManual" style={{display:"none", border:"1px solid #E3E3E3", marginTop:"10px", padding:"5px"}} className="text-primary font-size-xs small">
                If this CTM retrieved reservation includes multiple rates for different nights, click the Convert to Manual button to edit this reservation for the first rate period. Then, use the Copy Resv function to complete the additional rate night reservations.
                <span style={{marginLeft:"15px"}}><Button id="convertToManual" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.convertToManual(this.state.fields.hotelId,this.state.fields.sessionTA)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Convert To Manual</span>}
                    </Button>
                </span>
        </div>
    }
    buttonControl(){
        const { loading } = this.state;
        if(this.state.fields.hotelId !== 0 && this.state.fields.review && !this.state.fields.isReadOnly){
            return <Row>
                <Col sm={3}></Col>
                <Col sm={6}>
                    <Button id="updateResv" style={{ marginRight: "5px"}} type="submit" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Save Resv</span>}
                    </Button>
                    <Button id="addResv" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Add Resv</span>}
                    </Button>
                    <Button id="copyResv" style={{ marginRight: "5px"}} type="submit" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Copy Resv</span>}
                    </Button>
                    <Button id="saveCopyResv" style={{ marginRight: "5px"}} type="submit" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Save & Copy Resv</span>}
                    </Button>
                    <Button id="deleteResv" type="submit" size="sm" disabled={loading} style={{ display:"none",marginRight: "5px" }} onClick={(e) => this.deleteBooking(this.state.fields.hotelId)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Delete Resv</span>}
                    </Button>
                    <Button id="cancelReview" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.cancelReview()}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Cancel Review</span>}
                    </Button>
                </Col>
                <Col sm={3}></Col>
            </Row>
        }
        else if(this.state.fields.hotelId !== 0 && this.state.fields.review && this.state.fields.isReadOnly){
            return <Row>
                <Col sm={3}></Col>
                <Col sm={6}>
                    <Button id="updateResv" style={{ marginRight: "5px"}} type="submit" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" , color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{marginRight: "5px", color:"#487afa"}} >wait...</span>}
                        {!loading && <span style={{marginRight: "5px"}}>Save Resv</span>}
                    </Button>
                    <Button id="cancelReview" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.cancelReview()}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Cancel Review</span>}
                    </Button>
                </Col>
                    <Col sm={3}></Col>
                </Row>
                
        }
        else{
            return <Row>
                <Col sm={5}></Col>
                <Col sm={2}>
                    <Button id="saveResv" style={{ marginRight: "5px"}} type="submit" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Save Resv</span>}
                    </Button>
                </Col>
                <Col sm={5}></Col>
            </Row>
        }
        
    }

    displayTravelerMsg(){
        let fields=this.state.fields;
        if (this.state.TATravelerList.length === 0){ fields["iTravelerCnt"] = -1; }
        
        if (fields["iTravelerCnt"] > 0 || fields["iTravelerCnt"] <= 0){
            if(this.state.fields.iTravelerCnt === -1){
                 return <div id="divTravelerMsg" className="text-dark font-size-xs small">Click on the Traveler tab to add a traveler.</div>
            }
            else
            {
                return <div id="divTravelerMsg" style={{color:"red",textAlign:"center"}} className="text-dark font-size-xs small">Click on a traveler to add to reservation and enter airfare amount.</div>
            }
        }
        else
        {
            return <></>
        }
    }

    tableSpecialCharge(text){
	    return <Row id="splCharges" style={{display:"none"}}>
            <Col sm={12}>         
                <table style={{border:"1px",padding:"0px"}}>
                    <tr>
                        <td>
                            <table style={{border:"1px",padding:"0px",borderSpacing:"2px"}}>
                                <tr>
                                    <td colSpan={2} style={{textAlign:"center",fontSize: "12px"}}>FOR SPECIAL CHARGES ONLY</td>
                                </tr>
                                <tr>
                                    <td style={{width:"85%",fontSize: "12px"}}>Description {text}</td>
                                    <td style={{width:"15%",alignSelf:"right",fontSize: "12px"}}>Amount</td>
                                </tr>
                                <tr>
                                    <td style={{alignSelf:"top"}}>
                                        <FormControl style={{height: 'auto'}} size="sm" id="scDescription" as="textarea" name="scDescription" className="form-control input-sm" value={this.state.fields.scDescription} onChange={(e) => this.handleChange(e)} ></FormControl>
                                        {this.state.errors.scDescription !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.scDescription}</div> : <></> }
                                    </td>
                                    <td style={{alignSelf:"top",textAlign:"right"}}>
                                        <FormControl style={{height: 'auto',textAlign:"right"}} maxLength={13} size="sm" id="scAmount" type="text" name="scAmount" className="form-control input-sm" value={this.state.fields.scAmount} onChange={(e) => this.handleChange(e)} ></FormControl>
                                        {this.state.errors.scAmount !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.scAmount}</div> : <></> }
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </Col>
        </Row>
    }

    formTraveller(prop, key){
        return <tr id={"div"+prop['travelerId']} style={{ display: !prop['bit'] ? "none" : ""}}>
            <td style={{padding: '0px',width:'250px'}}><FormLabel style={{height: 'auto'}}>{prop['name']}</FormLabel></td>
            <td style={{padding: '0px',width:'150px'}}><FormLabel style={{height: 'auto'}}>{prop['RLN']}</FormLabel></td>
            <td style={{padding: '0px',width:'200px'}}><FormControl style={{height: 'auto'}} size="sm" type="text" id="comment" name="comment" className="form-control input-sm" value={this.state.fields.comment[key]} onChange={(e) => this.handleFormChange(key, e)}></FormControl></td>
        </tr>    
    }

    formControl = () => {
        return<div style={{borderBottom:"1px solid #E3E3E3"}}>
            <Row>
                <Col sm={2}>
                    <Form.Group>
                        <Form.Label>Billing Type<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                        <Form.Control as="select" id="hotelBillId" name="hotelBillId" style={{backgroundColor: (this.state.fields.isReadOnly) ? "yellow" : "#ffffff"}} value={this.state.selectfields.hotelBillId} className="form-control form-control-sm" onChange={this.handleSelectChange}>
                            <option value={-1}>{"Select Billing Type"}</option>
                            {this.state.BillingType.map((prop, key) => {
                                if(prop["id"] !== "-1"){
                                    return(
                                        <option selected={true} value={prop["id"]}>{prop["name"]}</option>
                                    )
                                }
                            })}
                        </Form.Control>
                        {this.state.errors.hotelBillId !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.hotelBillId}</div> : <></> }
                    </Form.Group>
                </Col>
                <Col sm={2}>
                    <FormInputs
                        properties={{id:"hotel",label:"Hotel",type:"text",name:"hotel",className:"form-control input-sm form-control-sm",maxlength:50,value:this.state.fields.hotel,onChange:this.handleChange,disabled:(this.state.fields.isReadOnly)?true:false}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.hotel}
                    />
                </Col>
                <Col sm={2}> 
                    <FormInputs
                        properties={{id:"city",label:"City",type:"text",name:"city",className:"form-control input-sm form-control-sm",maxlength:50,value:this.state.fields.city,onChange:this.handleChange,disabled:(this.state.fields.isReadOnly)?true:false}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.city}
                    />
                </Col>
                <Col sm={2}>
                    <Form.Label>CheckIn(MM/DD/YYYY)<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label> 
                    <Form.Group>             
                        <DatePicker style={{fontSize:"12px"}} 
                        Id="checkIn"
                        selected={this.state.checkIn}
                        onChange={this.handleChangeCheckInDate}
                        isClearable
                        showMonthDropdown
                        showYearDropdown                            
                        className="datePicker form-control form-control-sm"
                        disabled={(this.state.fields.isReadOnly)?true:false}/> 
                    </Form.Group>
                    {this.state.errors.checkIn !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.checkIn}</div> : <></> } 
                </Col>
                <Col sm={2}>
                    <Form.Label>CheckOut(MM/DD/YYYY)<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                    <Form.Group>                         
                        <DatePicker style={{fontSize:"12px"}} 
                        Id="checkOut"
                        selected={this.state.checkOut}
                        onChange={this.handleChangeCheckOutDate}
                        isClearable
                        showMonthDropdown
                        showYearDropdown                            
                        className="datePicker form-control form-control-sm"
                        disabled={(this.state.fields.isReadOnly)?true:false}/>  
                    </Form.Group>
                    {this.state.errors.checkOut !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.checkOut}</div> : <></> } 
                </Col>
                <Col sm={2}></Col>
            </Row>
            <Row>
                <Col sm={2}></Col>
                <Col sm={2}>
                    <FormCheckBox
                        properties={{label:'Non-Disputable',id:"nonDisputable",name:"nonDisputable",type:"checkbox",disabled:true,className:"form-control-md",checked:this.state.checkfields.nonDisputable}}
                    />
                </Col>
                <Col sm={6} className="align-self-center" style={{textAlign: "justify"}}><div className="text-danger font-size-xs small">All hotels are now Non-Disputable.  Please be aware that the Business Unit will be responsible for the entire Hotel Invoice regardless of the amounts indicated in eTA</div></Col>
                <Col sm={2}></Col>
            </Row>
            <Row>
                <Col sm={2}></Col>
                <Col sm={2}>
                    <FormInputs
                        properties={{id:"ratePerNight",label:"Rate Per Night",type:"text",isDoller:false,name:"ratePerNight",className:"form-control input-sm form-control-sm",maxlength:50,value:this.state.fields.ratePerNight,onChange:this.handleChange,style:{"backgroundColor": this.state.fields.isReadOnly ? "yellow" : "#ffffff"}}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.ratePerNight}
                    />
                </Col>
                <Col sm={2}>
                    <FormLabel>Tax (30%)</FormLabel>
                    <div className="text-dark font-size-xs small">{this.state.fields.tax}</div>
                </Col>
                <Col sm={2}>
                    <FormLabel>Incidentals (30%)</FormLabel>
                    <div className="text-dark font-size-xs small">{this.state.fields.incidentals}</div>
                </Col>
                <Col sm={2}>
                    <FormLabel>Per Room Total</FormLabel>
                    <div className="text-dark font-size-xs small">{this.state.fields.total}</div>
                </Col>
                <Col sm={2}></Col>
            </Row>
        </div>
    }

    displayHotel = () => {     
        return <div>
            {(!this.state.fields.isReadOnly)?<>
            <Row>
                <Col sm={12} style={{textAlign:"right"}}>
                <Button id="print" type="button" size="sm" onClick={() => window.print()} >
                    Print
                </Button>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <div style={{fontSize:'7pt',fontWeight:'bold'}}><b>MANUAL RESERVATIONS ONLY</b><span className="text-danger" style={{fontSize:'16px'}}>*</span><br/>Select Traveler</div>
                    <Form.Group>
                        <Form.Control as="select" multiple={true} id="travellerId" name="travellerId" value={this.state.travellerId} className="form-control form-control-sm" onChange={this.handleSelectMultipleChange}>
                            {this.state.TATravelerList.map((prop, key) => {
                                return(
                                    <option selected={true} value={prop["travelerId"]}>{prop["name"] + ' ' + prop["RLN"]}</option>
                                )
                            })}
                        </Form.Control>
                        <div style={{fontSize:'7pt',fontWeight:'bold'}} >(Ctrl to select multiple)</div>
                        {this.state.errors["travellerId"] !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors["travellerId"]}</div> : <></> }
                    </Form.Group>
                </Col>
                <Col sm={12}></Col>
            </Row>        
            <div style={{borderBottom: "1px solid rgb(227, 227, 227)",marginBottom:"15px"}}></div></> 
            :<></>}
            {this.formControl()}
            <Row>
                <Col sm={5}>
                    <div style={{color: "#FF4A55", marginTop:"15px"}}>
                        <FormCheckBox
                            properties={{label:"Add Special Charges",id:"addSplCharges",name:"addSplCharges",type:"checkbox",className:"form-control-md",onChange:this.handleCheckChange,checked:this.state.checkfields.addSplCharges}}
                        />
                    </div>
                </Col>             
            </Row>  
            <Row>
            <Col sm={9}>
                <div style={{width: '100%', marginTop:'0px'}}>
                    <Table id="travelerTable" hover>                    
                        <thead style={{backgroundColor:'#00B0F0', fontSize: 'large', height:'11px'}}>
                        <tr>
                            <th>Traveler</th>
                            <th>Resv #</th>
                            <th>Comment</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.TATravelerTable.map((prop, key) =>
                            this.formTraveller(prop, key)
                        )}
                        </tbody>
                    </Table>
                </div>
            </Col>
            <Col sm={3}>
                    {this.tableSpecialCharge("(Meals, Incidentals, Parking, etc.)")}
            </Col>
        </Row>
        {this.buttonControl()}    
        {this.displayConvertBCDToManual()}
        {/* commented as iTravellerCount not needed 
            {this.displayTravelerMsg()}*/}
        <div id="divClicktoReview" style={{marginTop:"15px"}}>
            {(this.state.hotelBooking.length > 0) ? 
                <Row>
                    <Col sm={12}>
                        <div className="text-yellow font-size-xs small" style={{backgroundColor:"navy",color: "yellow",textAlign:"center"}}>Click the Review button to complete each traveler's reservation</div>
                    </Col>
                </Row> : ""}
        </div>
        {this.loadTravelerReservation()}
        <div id="divReviewComplete" style={{borderTop:(this.state.hotelBooking.length > 0) ?"1px solid navy": "0px"}}>
            {(this.state.hotelBooking.length > 0) ? 
                <Row>
                    <Col sm={12}>
                        <div className="text-danger font-size-xs small" style={{textAlign:"center",fontStyle:"bold"}}>REVIEW/COMPLETED TRAVEL SECTION</div>
                    </Col>
                </Row> : ""}
        </div>
        </div>  
    }

    codingTab(){
        if(sessionStorage.getItem("page") === "submitter" && parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && (parseInt(sessionStorage.getItem("ta_tier_code")!) !== 0 || sessionStorage.getItem("ta_is_corporate_card") === "true")){
            return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for summary page" label="Print"></Tab>
        }
        if(sessionStorage.getItem("page") === "approver" || sessionStorage.getItem("page") === "submitter"){
            if(parseInt(sessionStorage.getItem("ta_status_id")!) !== 1 || (parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && parseInt(sessionStorage.getItem("ta_tier_code")!) === 0 || sessionStorage.getItem("ta_is_corporate_card") === "false")){
                return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for coding page" label="Coding"></Tab>
            }    
        }     
    }
    decisionTabTAHistoryResubmit(){
        
        if(sessionStorage.getItem("ta_status_desc") === "Re-Submit"){
           return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision"></Tab>           
        }
        else
        {
           return <Tab headerClass="nav-link disabled" style={{color:"white"}} disabled></Tab>
        }
    }
    redirectToPage(tabLabel){
        switch (tabLabel) {
            case 'Traveler':
                this.props.history.push('/admin/traveller');
                break;
            case 'Air / Rail':
                this.props.history.push('/admin/airrail');
                break;
            case 'Hotel':
                this.props.history.push('/admin/hotel');
                break;
            case 'Rental Car':
                this.props.history.push('/admin/rentalcar');
                break;
            case 'Car Service':
                this.props.history.push('/admin/carservice');
                break;
            case 'Print':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:""}});
                break;
            case 'Coding':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:""}});
                break;
            case 'Decision':
                this.props.history.push({pathname:'/admin/decision',state:{from:"tahistory"}});
                break;
        }
    }

    render() {
        const{ loading } = this.state;
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <Card.Body>
                            <Form autoComplete='off'>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <AuthStatus/>
                                <section style={{marginTop:"10px"}}>
                                    <div style={{textAlign:"right"}}>
                                        <SubmitButton 
                                            loading={this.state.loading} 
                                            redirect={this.props.history}
                                        />
                                    </div>
                                    <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={2} mountOnExit>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for traveler page" label="Traveler"></Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for air/rail page" label="Air / Rail"></Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for hotel page" label="Hotel">{this.displayHotel()}</Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for rental car page" label="Rental Car"></Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for car service page" label="Car Service"></Tab>
                                        {this.codingTab()}
                                        {this.decisionTabTAHistoryResubmit()}
                                    </Tabs>
                                </section>
                            </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}