import React, { Component, useState } from "react";
import { IAirRailParameter, Istate, ITask } from "../../../Model/IAirRail";
import {
  FormLabel,
  FormGroup,
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Navbar,
  Nav,
  Alert,
  Button,
  Card,
  CardGroup,
  FormControl,
  Modal,
  Table,
} from "../../../../node_modules/react-bootstrap";
import { format } from "date-fns";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { FormModalSpinner } from "../../CustomComponents/FormModalSpinner";
import "../../../assets/css/Style.css";
import { CommonController } from "../../../Controllers/CommonController";
import { AirRailController } from "../../../Controllers/AirRailController";
import { FormInputs } from "../../CustomComponents/FormInput";
import { AuthStatus } from "../Common/AuthStatus";
import * as Utils from "../../../js/Utils.js";
import DatePicker from "react-datepicker";
import validator from "validator";
import dateFormat from "dateformat";
import { alert, confirm } from "react-bootstrap-confirmation";
import "react-datepicker/dist/react-datepicker.css";
import { SubmitButton } from "../Common/SubmitButton";
import { FormCheckBox } from "../../CustomComponents/FormCheckBox";

export class AirRail extends Component<IAirRailParameter, Istate> {
  constructor(props: IAirRailParameter) {
    super(props);
    const userLogin = sessionStorage.getItem("userLogin");
    let loggedIn = true;
    if (userLogin == null) {
      loggedIn = false;
    }
    
    this.state = {
      fields: {
        sessionTA: sessionStorage.getItem("ta"),
        airId: 0,
        traveller: [],
        resv: [],
        comment: [],
        airfare: [],
        total: [],
        recordLocator: "",
        scAmount: 0,
        scDesc: "",
        bcd_generated_editMode: 0,
        action: "",
        review: false,
        deleteClicked: false,
        isReadOnly: false,
      },
      checkfields: { addSplCharges: false },
      errors: { travellerId: "" },
      loading: false,
      errMsg: "",
      modal: {
        modalSpinnerShow: false,
        modalBody: "",
        modalShow: false,
        modalTAShow: false,
        modalTitle: "",
        modalTATitle: "",
      },
      TATravelerTable: [],
      TATravelerList: [],
      travellerId: [],
      mapTraveller: [],
      airId: [],
      airBooking: [],
      traveller: [],
      AirLineCodes: [],
      TravelClassCodes: [],
      travelDate: new Date(new Date().toISOString()),
      values: [
        {
          airId: 0,
          recordLocator: "",
          legId: 0,
          airlineId: "-1",
          airlineDesc: "",
          travelClassId: "-1",
          travelClassDesc: "",
          travelDate: new Date(new Date().toISOString()),
          fromLocation: "",
          fromTime: "",
          from_AM_PM: "",
          toLocation: "",
          toTime: "",
          to_AM_PM: "",
          scAmount: "",
          scDescription: "",
          BCDGenerated: false,
          BCDValidated: false,
          legProcess: "legInsert",
          totalAmount: 0,
          active: false,
        },
      ],
      deleteLeg: [],
      legErrors: [
        {
          airId: "",
          recordLocator: "",
          legId: "",
          airlineId: "",
          airlineDesc: "",
          travelClassId: "",
          travelClassDesc: "",
          travelDate: "",
          fromLocation: "",
          fromTime: "",
          from_AM_PM: "",
          toLocation: "",
          toTime: "",
          to_AM_PM: "",
          scAmount: "",
          scDescription: "",
          BCDGenerated: false,
          BCDValidated: false,
          totalAmount: 0,
        },
      ],
      arrTraveller: [],
      arrResv: [],
      arrComment: [],
      arrAirfare: [],
      arrTotal: [],
        
    };
    
    //alert(new Date(new Date().toLocaleDateString()))
    ////console.log('travel date',new Date(new Date().toLocaleDateString()));

    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleTimeFormat = this.handleTimeFormat.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleChangeTravelDateEvent =
      this.handleChangeTravelDateEvent.bind(this);
    this.handleSelectMultipleChange =
      this.handleSelectMultipleChange.bind(this);
    this.handleSelectMultipleChangeEvent =
      this.handleSelectMultipleChangeEvent.bind(this);
    this.handleOptionChangeEvent = this.handleOptionChangeEvent.bind(this);
  }

  componentWillMount() {
    
    let modal = this.state.modal;
    modal["modalSpinnerShow"] = true;
    this.setState({ modal });
    let commonController = new CommonController();
    var data = {
      id: "",
      view: "air",
      bInner: "false",
      sessionTA: this.state.fields.sessionTA,
    };
    let airRailController = new AirRailController();
    airRailController
      .getAirLineCodes()
      .then((jsonLabel) => {
        this.setState({
          AirLineCodes: JSON.parse(JSON.stringify(jsonLabel)),
        });
      })
      .catch((error) => {
        console.error(
          "There was an error loading getAirLineCodes..., " + error
        );
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
      });
    airRailController
      .getTravelClassCodes()
      .then((jsonLabel) => {
        this.setState({
          TravelClassCodes: JSON.parse(JSON.stringify(jsonLabel)),
        });
      })
      .catch((error) => {
        console.error(
          "There was an error loading getTravelClassCodes..., " + error
        );
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
      });
    commonController
      .getTATraveler(data)
      .then((jsonTravellers) => {
        //console.log(jsonTravellers,"jsonTravellers1232")

        this.setState({
          TATravelerList: JSON.parse(JSON.stringify(jsonTravellers)),
          TATravelerTable: JSON.parse(JSON.stringify(jsonTravellers)),
        });
        this.state.TATravelerTable.map((prop, key) => {
          let fields = this.state.fields;
          fields["traveller"][key] = prop["travelerId"];
          fields["resv"][key] = prop["RLN"];
          fields["comment"][key] = prop["specialInstructions"];
          fields["airfare"][key] = prop["airfareAmount"];
          fields["total"][key] = prop["totalAmount"];
          this.setState({ fields });
        });
        //console.log("TATravelerList, ", this.state.TATravelerList);
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
      })
      .catch((error) => {
        console.error("There was an error loading Traveler..., " + error);
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
      });
    this.pageLoad();
  }

  pageLoad() {
    let modal = this.state.modal;
    modal["modalSpinnerShow"] = true;
    this.setState({ modal });
    this.setState({ airBooking: [], traveller: [] });
    let airRailController = new AirRailController();
    let commonController = new CommonController();
    airRailController
      .getAirBookingAirId(this.state.fields.airId, this.state.fields.sessionTA)
      .then((jsonAirId) => {
        this.setState({
          airId: JSON.parse(JSON.stringify(jsonAirId)),
        });
        //console.log("AirId", this.state.airId);
        this.state.airId.map((prop, key) => {
          let airRailController = new AirRailController();
          var data = {
            air_id: prop["airId"],
            leg_id: "",
            sessionTA: this.state.fields.sessionTA,
          };
          airRailController
            .getBooking(data)
            .then((jsonAirBooking) => {
              this.setState({
                airBooking: [
                  ...this.state.airBooking,
                  ...JSON.parse(JSON.stringify(jsonAirBooking)),
                ],
              });
              //console.log("AirBooking", this.state.airBooking);
              var data1 = {
                id: prop["airId"],
                view: "air",
                bInner: true,
                sessionTA: this.state.fields.sessionTA,
              };
              commonController
                .getTATraveler(data1)
                .then((jsonTravellers) => {
                  this.setState({
                    traveller: [
                      ...this.state.traveller,
                      ...JSON.parse(JSON.stringify(jsonTravellers)),
                    ],
                  });
                  //console.log("traveller", this.state.traveller);
                  modal["modalSpinnerShow"] = false;
                  this.setState({ modal });
                })
                .catch((error) => {
                  console.error(
                    "There was an error loading traveller..., " + error
                  );
                });
            })
            .catch((error) => {
              console.error(
                "There was an error loading air booking..., " + error
              );
            });
        });
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
      })
      .catch((error) => {
        console.error("There was an error loading traveller..., " + error);
      });
  }

  addLeg() {
    if (this.state.fields.airId === 0) {
      this.setState((prevState) => ({
        values: [
          ...prevState.values,
          {
            airId: 0,
            recordLocator: "",
            legId: 0,
            airlineId: "-1",
            airlineDesc: "",
            travelClassId: "-1",
            travelClassDesc: "",
            travelDate: new Date(new Date().toISOString()),
            fromLocation: "",
            fromTime: "",
            from_AM_PM: "",
            toLocation: "",
            toTime: "",
            to_AM_PM: "",
            scAmount: "",
            scDescription: "",
            BCDGenerated: false,
            BCDValidated: false,
            legProcess: "legInsert",
            totalAmount: 0,
            active: true,
          },
        ],
      }));
      this.setState((prevState) => ({
        legErrors: [
          ...prevState.legErrors,
          {
            airId: "",
            recordLocator: "",
            legId: "",
            airlineId: "",
            airlineDesc: "",
            travelClassId: "",
            travelClassDesc: "",
            travelDate: "",
            fromLocation: "",
            fromTime: "",
            from_AM_PM: "",
            toLocation: "",
            toTime: "",
            to_AM_PM: "",
            scAmount: "",
            scDescription: "",
            BCDGenerated: false,
            BCDValidated: false,
            totalAmount: 0,
            legProcess: "",
          },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        values: [
          ...prevState.values,
          {
            airId: 0,
            recordLocator: "",
            legId: 0,
            airlineId: "-1",
            airlineDesc: "",
            travelClassId: "-1",
            travelClassDesc: "",
            travelDate: new Date(new Date().toISOString()),
            fromLocation: "",
            fromTime: "",
            from_AM_PM: "",
            toLocation: "",
            toTime: "",
            to_AM_PM: "",
            scAmount: "",
            scDescription: "",
            BCDGenerated: false,
            BCDValidated: false,
            legProcess: "legInsert",
            totalAmount: 0,
            active: true,
          },
        ],
      }));
      this.setState((prevState) => ({
        legErrors: [
          ...prevState.legErrors,
          {
            airId: "",
            recordLocator: "",
            legId: "",
            airlineId: "",
            airlineDesc: "",
            travelClassId: "",
            travelClassDesc: "",
            travelDate: "",
            fromLocation: "",
            fromTime: "",
            from_AM_PM: "",
            toLocation: "",
            toTime: "",
            to_AM_PM: "",
            scAmount: "",
            scDescription: "",
            BCDGenerated: false,
            BCDValidated: false,
            totalAmount: 0,
            legProcess: "",
          },
        ],
      }));
    }
    //console.log("addLeg:", this.state.values);
  }

  deleteLeg(e, i) {
    //
    var getValue = this.state.values[i].airId;
    //console.log(getValue);
    //if(this.state.fields.airId === 0){
    if (this.state.values[i].airId === 0) {
      const values = [...this.state.values];
      const legErrors = [...this.state.legErrors];
      values[i]["active"] = false;
      values.splice(i, 1);
      legErrors.splice(i, 1);
      
      this.setState({ values, legErrors });
      //console.log("deleteLeg:", this.state.values);
      //alert if trying to delete zero th delete leg.
      if (i === 0 && this.state.values.length === 1)
        alert("A reservation must have at least one leg");
    } else {
      const values = [...this.state.values];
      const legErrors = [...this.state.legErrors];
      //values[i]["active"] = true;
      //values[i]["legProcess"] = "legDelete";
      //this.setState(prevState => ({ deleteLeg: [...prevState.deleteLeg, { airId:values[i]["airId"], recordLocator:values[i]["recordLocator"], legId:values[i]["legId"], airlineId:values[i]["airlineId"], airlineDesc:values[i]["airlineDesc"], travelClassId:values[i]["travelClassId"], travelClassDesc:values[i]["travelClassDesc"], travelDate:values[i]["travelDate"], fromLocation:values[i]["fromLocation"], fromTime:values[i]["fromTime"], from_AM_PM:values[i]["from_AM_PM"], toLocation:values[i]["toLocation"], toTime:values[i]["toTime"], to_AM_PM:values[i]["to_AM_PM"], scAmount:values[i]["scAmount"], scDescription:values[i]["scDescription"], BCDGenerated:values[i]["BCDGenerated"], BCDValidated:values[i]["BCDValidated"], legProcess:'legDelete', totalAmount:values[i]["totalAmount"], active: true }]}))
      this.state.deleteLeg.push({
        airId: values[i]["airId"],
        recordLocator: values[i]["recordLocator"],
        legId: values[i]["legId"],
        airlineId: values[i]["airlineId"],
        airlineDesc: values[i]["airlineDesc"],
        travelClassId: values[i]["travelClassId"],
        travelClassDesc: values[i]["travelClassDesc"],
        travelDate: values[i]["travelDate"],
        fromLocation: values[i]["fromLocation"],
        fromTime: values[i]["fromTime"],
        from_AM_PM: values[i]["from_AM_PM"],
        toLocation: values[i]["toLocation"],
        toTime: values[i]["toTime"],
        to_AM_PM: values[i]["to_AM_PM"],
        scAmount: values[i]["scAmount"],
        scDescription: values[i]["scDescription"],
        BCDGenerated: values[i]["BCDGenerated"],
        BCDValidated: values[i]["BCDValidated"],
        legProcess: "legDelete",
        totalAmount: values[i]["totalAmount"],
        active: true,
      });
      values.splice(i, 1);
      legErrors.splice(i, 1);
      this.setState({ values, legErrors });
      //console.log("deleteLeg:", this.state.deleteLeg);
    }
  }

  handleChange(e: any) {
    let fields = this.state.fields;
    if (e.currentTarget.name === "scAmount") {
      fields[e.currentTarget.name] = e.currentTarget.value;
    } else {
      if (e.currentTarget.value.trim().length > 0) {
        fields[e.currentTarget.name] = e.currentTarget.value;
      } else {
        fields[e.currentTarget.name] = e.currentTarget.value.trim();
      }
    }
    this.setState({ fields });
  }
  handleCheckChange = (e: any) => {
    
    let checkfields = this.state.checkfields;
    let fields = this.state.fields;
    if (e.currentTarget.name === "addSplCharges") {
      checkfields[e.currentTarget.name] = !this.state.checkfields.addSplCharges;
    }
    const el: HTMLElement = document.getElementById(
      "splCharges"
    ) as HTMLElement;
    if (checkfields["addSplCharges"]) {
      el.style.display = "block";
    } else {
      fields["scDesc"] = "";
      fields["scAmount"] = 0;
      el.style.display = "none";
    }
    this.setState({
      checkfields,
      fields,
    });
  };
  handleChangeEvent(e, index) {
    
    const { name, value } = e.target;
    const values = [...this.state.values];
    values[index][name] = value;
    //if(this.state.fields.airId !== 0){
    if (values[index]["airId"] !== 0) {
      //values[index]["active"] = true;
      values[index]["legProcess"] = "legUpdate";
    }
    this.setState({ values });
    //console.log("handleChangeEvent:", this.state.values);
  }

  handleTimeFormat(e, i) {
    const { name } = e.target;
    const values = [...this.state.values];
    if (!(values[i][name].indexOf(":") > 0) && values[i][name] !== "") {
      values[i][name] = values[i][name] + ":00";
    }
    //if(this.state.fields.airId !== 0){
    if (values[i]["airId"] !== 0) {
      //values[i]["active"] = true;
      values[i]["legProcess"] = "legUpdate";
    }
    this.setState({ values });
  }

  handleFormChange = (i, e: any) => {
    
    let fields = this.state.fields;
    if (e.currentTarget.value.trim().length > 0) {
      fields[e.currentTarget.name][i] = e.currentTarget.value;
    } else {
      fields[e.currentTarget.name][i] = e.currentTarget.value.trim();
    }
    this.setState({ fields });
    //console.log("Onchange Event:" + e.currentTarget.name, fields);
  };

  // handleChangeTravelDateEvent = (date, index) => {
  //   // console.log(date, "hid");
  //   if(date!=null){
  //   var today = new Date();
  //   const values = [...this.state.values];
  //   values[index]["travelDate"] = Utils.FormatDateTime(date);
  //   if (values[index]["airId"] !== 0) {
  //     //values[index]["active"] = true;
  //     values[index]["legProcess"] = "legUpdate";
  //   }
  //   this.setState({ values });
    
  //   if (format(today, "MM/dd/yyyy") > format(date, "MM/dd/yyyy")) {
  //       this.state.legErrors[0]["travelDate"] = "The date you have entered has already past";
  //     } else {
  //       this.clearError();
  //       //console.log("clear")
  //     }}else{
  //     const values = [...this.state.values];
  //     values[index]["travelDate"] = date;
  //     this.setState({ values });}

  // };

  handleChangeTravelDateEvent = (date, index) => {
    // console.log(date, "hid");

    if (date != null) {
        const today = new Date(); // Current date
        const selectedDate = new Date(date); // Convert the selected date to a Date object

        // Standardize to UTC for date-only comparison (ignores time)
        const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
        const selectedDateUTC = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));

        const values = [...this.state.values];
        values[index]["travelDate"] = Utils.FormatDateTime(selectedDate); 
        if (values[index]["airId"] !== 0) {
            values[index]["legProcess"] = "legUpdate"; 
        }

       
        this.setState({ values });

        if (todayUTC > selectedDateUTC) {
            console.log("The selected date is in the past.");
            debugger;
            const legErrors = [...this.state.legErrors];
            legErrors[0]["travelDate"] = "The date you have entered has already passed";
            this.setState({ legErrors }); 
        } else {
            console.log("The selected date is valid.");
            this.clearError();
        }
    } else {
        
        const values = [...this.state.values];
        values[index]["travelDate"] = date; 
        this.setState({ values });
    }
};


  handleSelectMultipleChange(e: any) {
    
    var options = e.currentTarget.options;
    var value: string[] = [];
    var element: HTMLElement | null;
    //Clear mapTraveller
    //this.state.travellerId.splice(0,this.state.travellerId.length);
    this.state.mapTraveller.splice(0, this.state.mapTraveller.length);
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        element = document.getElementById(
          "div" + options[i].value
        ) as HTMLElement;
        if (element !== null) {
          element.style.display = "";
        }
        value.push(options[i].value);
        if (this.state.fields.action === "review") {
          this.state.TATravelerTable.filter(
            (f) => f.travelerId == options[i].value
          ).map((prop) => {
            this.state.mapTraveller.push({
              travelerId: prop["travelerId"],
              RLN: prop.RLN,
              specialInstructions: prop.specialInstructions,
              airfareAmount: prop.airfareAmount,
              totalAmount: prop.totalAmount,
              mapStatus: "",
            });
          });
        } else {
          this.state.TATravelerTable.filter(
            (f) => f.travelerId == options[i].value
          ).map((prop) => {
            this.state.mapTraveller.push({
              travelerId: prop["travelerId"],
              RLN: prop.RLN,
              specialInstructions: prop.specialInstructions,
              airfareAmount: prop.airfareAmount,
              totalAmount: prop.totalAmount,
              mapStatus: "mapInsert",
            });
          });
        }
      } else {
        element = document.getElementById(
          "div" + options[i].value
        ) as HTMLElement;
        if (element !== null) {
          element.style.display = "none";
        }
      }
    }

    if (e.currentTarget.name === "traveler_id") {
      this.setState({ travellerId: value });
      //console.log("travellerId", this.state.travellerId);
    }
    //console.log("Fields", this.state.fields);
  }

  handleSelectMultipleChangeEvent(e, index) {
    
    const { name, value } = e.target;
    const values = [...this.state.values];
    values[index][name] = value;
    if (e.currentTarget.name === "airlineId") {
      values[index]["airlineDesc"] =
        e.currentTarget.options[e.currentTarget.selectedIndex].text;
    } else {
      values[index]["travelClassDesc"] =
        e.currentTarget.options[e.currentTarget.selectedIndex].text;
    }
    //if(this.state.fields.airId !== 0){
    if (values[index]["legId"] !== null) {
      if (values[index]["legId"] !== 0) {
        values[index]["legProcess"] = "legUpdate";
      }
    }
    if (
      values[index]["legProcess"] === "" &&
      (values[index]["legId"] === null || values[index]["legId"] === 0)
    ) {
      values[index]["legProcess"] = "legInsert";
    }
    this.setState({ values });
    //console.log("handleSelectMultipleChangeEvent:", this.state.values);
  }

  handleOptionChangeEvent(e, index) {
    
    const { name, value } = e.target;
    const values = [...this.state.values];
    let currentTargetId = e.currentTarget.id;
    if (currentTargetId === "lOrigAM") {
      values[index]["from_AM_PM"] = "AM";
    } else if (currentTargetId === "lOrigPM") {
      values[index]["from_AM_PM"] = "PM";
    } else if (currentTargetId === "lDestAM") {
      values[index]["to_AM_PM"] = "AM";
    } else if (currentTargetId === "lDestPM") {
      values[index]["to_AM_PM"] = "PM";
    }

    if (values[index]["airId"] !== 0) {
      //values[index]["active"] = true;
      values[index]["legProcess"] = "legUpdate";
    }
    this.setState({ values });
    //console.log("handleOptionChangeEvent:", this.state.values);
  }

  formatCurrency(amount) {
    var floatamount = parseFloat(amount);
    return floatamount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  async getBooking(airId, legId) {
    let modal = this.state.modal;
    let airRailController = new AirRailController();
    var data = {
      air_id: airId,
      leg_id: legId,
      sessionTA: this.state.fields.sessionTA,
    };
    await airRailController
      .getBooking(data)
      .then(async (jsonAirBooking) => {
        let fields = this.state.fields;
        let checkfields = this.state.checkfields;
        fields["airId"] = airId;
        if (jsonAirBooking.length > 0) {
          if (
            jsonAirBooking[0].scDescription !== "" ||
            jsonAirBooking[0].scAmount > 0
          ) {
            checkfields["addSplCharges"] = true;
          } else {
            checkfields["addSplCharges"] = false;
          }
          fields["scDesc"] = jsonAirBooking[0].scDescription;
          fields["scAmount"] = jsonAirBooking[0].scAmount;
          fields["bcd_generated_editMode"] = jsonAirBooking[0].BCDGenerated;
        } else {
          fields["bcd_generated_editMode"] = false;
        }
        this.setState({ fields, checkfields });
        if (this.state.fields.action === "review") {
          this.retrieveControls();
        }
        const el: HTMLElement = document.getElementById(
          "splCharges"
        ) as HTMLElement;
        if (checkfields["addSplCharges"]) {
          el.style.display = "block";
        } else {
          fields["scDesc"] = "";
          fields["scAmount"] = 0;
          el.style.display = "none";
        }
        var mapBooking = JSON.parse(JSON.stringify(jsonAirBooking));
        //console.log("mapBooking:", mapBooking);
        var legId = "";
        this.state.values.splice(0, this.state.values.length);
        this.state.legErrors.splice(0, this.state.legErrors.length);
        //set active in traveller
        var travelDate;
        await mapBooking.map((prop, key) => {
          if (legId !== prop.legId) {
            legId = prop.legId;
            travelDate = prop["travelDate"].substring(0, 10).split("-");
            travelDate =
              travelDate[1] + "-" + travelDate[2] + "-" + travelDate[0];
            this.state.values.push({
              airId: prop.airId,
              recordLocator: prop.recordLocator,
              legId: prop.legId,
              airlineId: prop.airlineId,
              airlineDesc: prop.airlineDesc,
              travelClassId: prop.travelClassId,
              travelClassDesc: prop.travelClassDesc,
             // travelDate: new Date(travelDate).toLocaleDateString(),
              travelDate:travelDate,
              fromLocation: prop.fromLocation,
              fromTime: prop.fromTime,
              from_AM_PM: prop.from_AM_PM,
              toLocation: prop.toLocation,
              toTime: prop.toTime,
              to_AM_PM: prop.to_AM_PM,
              scAmount: prop.scAmount,
              scDescription: prop.scDescription,
              BCDGenerated: prop.BCDGenerated,
              BCDValidated: prop.BCDValidated,
              legProcess: "",
              totalAmount: prop.totalAmount,
              active: true,
            });
            this.state.legErrors.push({
              airId: "",
              recordLocator: "",
              legId: "",
              airlineId: "",
              airlineDesc: "",
              travelClassId: "",
              travelClassDesc: "",
              travelDate: "",
              fromLocation: "",
              fromTime: "",
              from_AM_PM: "",
              toLocation: "",
              toTime: "",
              to_AM_PM: "",
              scAmount: "",
              scDescription: "",
              BCDGenerated: false,
              BCDValidated: false,
              totalAmount: 0,
              legProcess: "",
            });
          }
        });
        //console.log("Deleted Air Review Values:", this.state.values);
        //console.log("Review-Values:", this.state.values);
        //console.log("Review:", jsonAirBooking);
      })
      .catch((error) => {
        modal["modalSpinnerShow"] = false;
        this.setState(modal);
        console.error("There was an error loading air booking..., " + error);
      });
  }

  timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }
  retrieveControls() {
    
    var taStatus = sessionStorage.getItem("ta_status_desc");
    var bcdResubmit = false;
    var IsReadonly = true;

    // if BCD generated reservation
    if (
      this.state.fields.bcd_generated_editMode &&
      this.state.fields.action !== "deleteResv" &&
      this.state.fields.airId !== 0 &&
      this.state.fields.action !== "updateResv" &&
      this.state.fields.action !== ""
    ) {
      let fields = this.state.fields;
      let commonController = new CommonController();
      commonController
        .getApproverHistoryBCD(this.state.fields.sessionTA)
        .then((response) => {
          
          bcdResubmit = response.success ? true : false;

          // if TA is in BCD resubmit status
          if (taStatus === "Re-Submit" && bcdResubmit) {
            IsReadonly = false;
            fields["isReadOnly"] = false;
          }

          if (IsReadonly) {
            fields["isReadOnly"] = true;
          }
          this.setState({ fields });
        })
        .catch((error) => {
          console.error("There was an error...");
        });
    }
  }
  async getTATraveller(airId) {
    let modal = this.state.modal;
    let commonController = new CommonController();
    var data = {
      id: airId,
      view: "air",
      bInner: true,
      sessionTA: this.state.fields.sessionTA,
    };
    
    await commonController
      .getTATraveler(data)
      .then(async (jsonTravellers) => {
        
//        console.log("JsonTravellers", jsonTravellers);
        var travellerId: any = [];
        //Clear mapTraveller
        this.state.mapTraveller.splice(0, this.state.mapTraveller.length);
        jsonTravellers.map((prop) => {
          
          travellerId.push(prop["travelerId"]);
          this.state.mapTraveller.push({
            travelerId: prop["travelerId"],
            RLN: prop.RLN,
            specialInstructions: prop.specialInstructions,
            airfareAmount: prop.airfareAmount,
            totalAmount: prop.totalAmount,
            mapStatus: "",
          });
        });

        this.setState({
          travellerId: travellerId,
          TATravelerTable: JSON.parse(JSON.stringify(jsonTravellers)),
        });
        
        //console.log("**********************-1");
        await this.state.TATravelerList.map((item, i) => {
          var filterTraveller = jsonTravellers.filter(
            (f) => f.travelerId == item.travelerId
          ).length;
          
          //console.log("****************-outside Length:" + filterTraveller, i);
          if (filterTraveller === 0) {
            //console.log("****************-inside");
            
            this.state.TATravelerTable.push(item);
          }
        });
        //console.log("**********************-2");
        //console.log("getTATraveler - Traveller:", this.state.travellerId);
        //console.log("getTATraveler - mapTraveller:", this.state.mapTraveller);
        /*
            var tempTraveller : Array<{"id": string; "travellerId": string; "isDH": string; "address": string; "city": string; "stateProvinceId": string; "zip": string; "phone": string; "ssn": string; "type": string; "issuer": string; "callLetters": string; "travelPurpose": string; "fedId": string; "radioLetterReceived": string; "overrideReceived": string; "preapprovalReceived": string; "firstName": string; "lastName": string; "name": string; "formOnFile": string; "travelRestrictions": string; "RLN": string; "airfareAmount": string; "feeAmount": string; "totalAmount": string; "bit": boolean; "specialInstructions": string}> = [];
            await jsonTravellers.map((item,i) => {
                var count = 0;
                this.state.TATravelerTable.map((prop,key) => {
                    if(item["travelerId"] !== prop["travelerId"]){
                        count = count + 1;
                    }
                    if(key + 1 === this.state.TATravelerTable.length){
                        if(count >= 1){
                            tempTraveller.push(prop);
                        }
                    }
                })
            })
            //console.log("before tempTraveller", tempTraveller)
        
            //Commented
            //tempTraveller.map(prop => jsonTravellers.push(prop));
            //console.log("After tempTraveller", tempTraveller)
            */
        //this.setState({
        //TATravelerTable : jsonTravellers
        //});

        await this.state.TATravelerTable.map((prop, key) => {
          let fields = this.state.fields;
          fields["traveller"][key] = prop["travelerId"];
          fields["resv"][key] = prop["RLN"];
          fields["comment"][key] = prop["specialInstructions"];
          fields["airfare"][key] = prop["airfareAmount"];
          fields["total"][key] = prop["totalAmount"];
          this.setState({ fields });
        });

        modal["modalSpinnerShow"] = false;
        this.setState(modal);
      })
      .catch((error) => {
        modal["modalSpinnerShow"] = false;
        this.setState(modal);
        console.error("There was an error loading Traveler..., " + error);
      });
  }

  async loadReview(airId) {
    let modal = this.state.modal;
    let fields = this.state.fields;
    modal["modalSpinnerShow"] = true;
    fields["review"] = true;
    fields["airId"] = airId;
    fields["action"] = "review";
    this.setState({ fields, modal });
    this.clearError();
    this.state.travellerId.splice(0, this.state.travellerId.length);
    const priority1 = await this.getBooking(airId, "");
    const priority2 = await this.getTATraveller(airId);
    //console.log("loadReview - fields: ", this.state.fields);
    Promise.all([priority1, priority2])
      .then((values) => {
        //console.log("Promise", values);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  clearArrayState() {
    this.state.arrTraveller.splice(0, this.state.arrTraveller.length);
    this.state.arrResv.splice(0, this.state.arrResv.length);
    this.state.arrComment.splice(0, this.state.arrComment.length);
    this.state.arrAirfare.splice(0, this.state.arrAirfare.length);
    this.state.arrTotal.splice(0, this.state.arrTotal.length);
    let fields = this.state.fields;
    fields["traveller"].splice(0, this.state.fields.traveller.length);
    fields["resv"].splice(0, this.state.fields.resv.length);
    //fields["comment"].splice(0, this.state.fields.comment.length);
    //fields["airfare"].splice(0, this.state.fields.airfare.length);
    //fields["total"].splice(0, this.state.fields.total.length);
    this.setState({ fields });
    //fields["resv"][key] = prop["RLN"];
    //fields["comment"][key] = prop["specialInstructions"];
    //fields["airfare"][key] = prop["airfareAmount"];
    //fields["total"][key] = prop["totalAmount"];
  }
  async deleteBooking(airId) {
    var flag = await confirm("Do you want to continue?", {
      title: "Confirm Delete",
      okText: "Yes",
      cancelText: "No",
    });
    //console.log("delete flag", flag);
    if (flag) {
      let fields = this.state.fields;
      let commonController = new CommonController();
      commonController
        .deleteAuthReq("", airId, "", "", "")
        .then((response) => {
          fields["deleteClicked"] = true;
          this.setState({ fields });
          //console.log("deleteBooking", response);
          //console.log("Delete Action Id: ", airId);
          //alert("Delete action completed...")
          this.pageLoad();
        })
        .catch((error) => {
          console.error("There was an error...", error);
        });
    }
  }

  cancelReview() {
    this.clearError();
    //console.log("travellerId:", this.state);
    this.resetPageLoad();
    let fields = this.state.fields;
    fields["airId"] = 0;
    fields["scAmount"] = 0;
    fields["scDesc"] = "";
    fields["review"] = false;
    fields["isReadOnly"] = false;
    this.setState({ fields });
    this.setState({
      TATravelerTable: this.state.TATravelerList,
      travellerId: [],
    });
    this.state.TATravelerList.map((prop, key) => {
      let fields = this.state.fields;
      fields["traveller"][key] = prop["travelerId"];
      fields["resv"][key] = prop["RLN"];
      fields["comment"][key] = prop["specialInstructions"];
      fields["airfare"][key] = prop["airfareAmount"];
      fields["total"][key] = prop["totalAmount"];
      this.setState({ fields });
    });
  }

  resetPageLoad() {
    this.state.values.splice(0, this.state.values.length);
    this.state.deleteLeg.splice(0, this.state.deleteLeg.length);
    this.setState({
      values: [
        {
          airId: 0,
          recordLocator: "",
          legId: 0,
          airlineId: "-1",
          airlineDesc: "",
          travelClassId: "-1",
          travelClassDesc: "",
          travelDate: new Date(new Date().toISOString()),
          fromLocation: "",
          fromTime: "",
          from_AM_PM: "",
          toLocation: "",
          toTime: "",
          to_AM_PM: "",
          scAmount: "",
          scDescription: "",
          BCDGenerated: false,
          BCDValidated: false,
        },
      ],
      legErrors: [
        {
          airId: "",
          recordLocator: "",
          legId: "",
          airlineId: "",
          airlineDesc: "",
          travelClassId: "",
          travelClassDesc: "",
          travelDate: "",
          fromLocation: "",
          fromTime: "",
          from_AM_PM: "",
          toLocation: "",
          toTime: "",
          to_AM_PM: "",
          scAmount: "",
          scDescription: "",
          BCDGenerated: false,
          BCDValidated: false,
          totalAmount: 0,
          legProcess: "",
        },
      ],
    });
  }

  clearError() {
    this.state.errors.travellerId = "";
    this.state.legErrors.map((prop, key) => {
      prop["airlineId"] = "";
      prop["travelClassId"] = "";
      prop["travelDate"] = "";
      prop["fromLocation"] = "";
      prop["fromTime"] = "";
      prop["toLocation"] = "";
      prop["toTime"] = "";
      prop["to_AM_PM"] = "";
      prop["from_AM_PM"] = "";
    });
  }

  validateForm() {
    this.clearError();
    let fields = this.state.fields;
    let errors = this.state.errors;
    let modal = this.state.modal;
    let formIsValid = true;
    //console.log("ValidateForm Values: ", this.state.values);
    this.state.values.map((prop, key) => {
      
      if (this.state.travellerId.length === 0) {
        formIsValid = false;
        this.state.errors["travellerId"] = "Please select traveller";
      }
      if (prop["airlineId"] === "-1") {
        formIsValid = false;
        this.state.legErrors[key]["airlineId"] = "Please select Air/Rail";
      }
      if (prop["travelClassId"] === "-1") {
        formIsValid = false;
        this.state.legErrors[key]["travelClassId"] = "Please select Class";
      }
      if (!validator.isDate(dateFormat(prop["travelDate"], "yyyy/mm/dd"))) {
        formIsValid = false;
        this.state.legErrors[key]["travelDate"] = "Please enter a valid date";
      }
      // if(validator.isBefore(dateFormat(prop["travelDate"],"yyyy/mm/dd")) && dateFormat(prop["travelDate"],"yyyy/mm/dd") !== dateFormat(Date.now(),"yyyy/mm/dd")){
      //     formIsValid = false;
      //     this.state.legErrors[key]["travelDate"] = "The date you have entered has already past";
      // }
      if (!prop["fromLocation"]) {
        formIsValid = false;
        this.state.legErrors[key]["fromLocation"] = "Please enter location";
      }
      if (!prop["toLocation"]) {
        formIsValid = false;
        this.state.legErrors[key]["toLocation"] = "Please enter location";
      }
      if (!Utils.validTime(prop["fromTime"])) {
        formIsValid = false;
        this.state.legErrors[key]["fromTime"] = "Please enter a valid time";
      }
      var regEx = new RegExp("(((0[1-9])|(1[0-2])):([0-5][0-9]))");
      if (!regEx.test(prop["fromTime"])) {
        formIsValid = false;
        this.state.legErrors[key]["fromTime"] = "Please enter a valid time";
      }
      if (!Utils.validTime(prop["toTime"])) {
        formIsValid = false;
        this.state.legErrors[key]["toTime"] = "Please enter a valid time";
      }
      if (!regEx.test(prop["toTime"])) {
        formIsValid = false;
        this.state.legErrors[key]["toTime"] = "Please enter a valid time";
      }
      if (prop["from_AM_PM"] === "") {
        formIsValid = false;
        this.state.legErrors[key]["from_AM_PM"] = "Please select AM or PM";
      }
      if (prop["to_AM_PM"] === "") {
        formIsValid = false;
        this.state.legErrors[key]["to_AM_PM"] = "Please select AM or PM";
      }
    });
    let msg = "";
    var stopDupAlert = false;

    //console.log("Air Rail fields", this.state.fields);
    var isValidAirFare = true;
    for (let airTraveller of this.state.travellerId) {
      
      if (isValidAirFare) {
        let index = this.state.fields.traveller.findIndex(
          (x) => x == airTraveller
        );
        if (
          this.state.fields.airfare[index] === "" ||
          this.state.fields.airfare[index] <= 0
        ) {
          formIsValid = false;
          isValidAirFare = false;
          stopDupAlert = true;
          alert("Please Enter a Valid Amount");
        }
        msg = Utils.validMoney(
          this.state.fields.airfare[index].toString(),
          "Please Enter a Valid Amount"
        );
        if (msg !== "") {
          formIsValid = false;
          isValidAirFare = false;
          if (!stopDupAlert) {
            alert(msg);
          }
        } else {
          if (parseFloat(this.state.fields.airfare[index]) === 0) {
            formIsValid = false;
            isValidAirFare = false;
            if (!stopDupAlert) {
              alert("Please Enter a Valid Amount");
            }
          }
        }
      }
    }
    /*
        if(fields["scDescription"] !== ""){
            msg = Utils.validMoney(fields["scAmount"],"Please Enter a Valid Special Charge");
            if(msg !== ""){
                formIsValid = false;
                errors["scAmount"] = msg;
            }
            else{
                if(parseFloat(fields["scAmount"]) === 0){
                    formIsValid = false;
                    errors["scAmount"] = "Please Enter a Special Charge";
                }
            }
        }
        msg = Utils.validMoney(fields["scAmount"],"Please Enter a Valid Special Charge");
        if(msg !== ""){
            formIsValid = false;
            errors["scAmount"] = msg;
        }
        else{
            if(parseFloat(fields["scAmount"]) !== 0 && fields["scDescription"] === ""){
                formIsValid = false;
                errors["scDescription"] = "Please Enter a Special Charge Explanation";
            }
        }
        */
    if (!formIsValid) {
      modal["modalSpinnerShow"] = false;
      this.setState({ modal });
    }
    this.setState({ errors });
    return formIsValid;
  }

  onSubmit(e: any) {
    e.preventDefault();
    let action = e.currentTarget.id;
    let fields = this.state.fields;
    let checkfields = this.state.checkfields;
    let modal = this.state.modal;

    //console.log("Step 1 mapping travellerId:", this.state.travellerId);
    //console.log("Step 1 mapping comment:", this.state.fields.comment);
    //console.log("Step 1 mapping airfare:", this.state.fields.airfare);

    try {
      fields["action"] = action;
      modal["modalSpinnerShow"] = true;
      //checkfields["addSplCharges"] = false;
      this.setState({ fields, modal });

      //const el:HTMLElement = document.getElementById('splCharges') as HTMLElement;
      //el.style.display = "none";

      if (action === "copyResv") {
        this.userProcessCopy();
      } else if (action === "addResv") {
        this.cancelReview();
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
      } else {
        if (this.validateForm()) {
          fields["isReadOnly"] = false;
          this.setState({ fields });
          if (action === "saveResv") {
            this.userProcessInsert();
          } else if (action === "saveCopyResv" || action === "updateResv") {
            this.userProcessUpdate();
          }
        }
      }
    } catch (ex) {
     // //console.log("There was an error in onSubmit...", ex.message);
      modal["modalSpinnerShow"] = false;
      this.setState({ modal });
    }
  }
  userProcessInsert() {
    let modal = this.state.modal;
    let airRailController = new AirRailController();
    var insertData = {
      userProcess: "insert",
      copyId: "",
      sessionTA: this.state.fields.sessionTA,
      recordLocator: this.state.fields.recordLocator,
      scAmount: this.state.checkfields.addSplCharges
        ? this.state.fields.scAmount
        : 0,
      scDesc: this.state.checkfields.addSplCharges
        ? this.state.fields.scDesc
        : "",
    };
    //console.log("insert data", insertData);
    airRailController
      .userProcessInsertCopy(insertData)
      .then((response) => {
        //console.log("insertAirBooking", response);
        let fields = this.state.fields;
        fields["airId"] = response.airId;
        this.setState({
          fields,
        });
        //console.log("Step 2 mapping travellerId:", this.state.travellerId);
        //console.log("Step 2 mapping comment:", this.state.fields.comment);
        //console.log("Step 2 mapping airfare:", this.state.fields.airfare);
        this.airLeg("", "userInsert");
      })
      .catch((error) => {
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
        console.error("There was an error...", error);
      });
  }

  userProcessCopy() {
    let modal = this.state.modal;
    const copyId = this.state.fields.airId;
    let airRailController = new AirRailController();
    var copyData = {
      userProcess: "copy",
      copyId: copyId,
      sessionTA: this.state.fields.sessionTA,
      recordLocator: "",
      scAmount: "",
      scDesc: "this.state.fields.scDesc",
    };
    //console.log("copy data", copyData);
    airRailController
      .userProcessInsertCopy(copyData)
      .then((response) => {
        //console.log("copyAirBooking", response);
        let fields = this.state.fields;
        fields["airId"] = response.airId;
        this.setState({
          fields,
        });
        this.airLeg(copyId, "");
      })
      .catch((error) => {
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
        console.error("There was an error...", error);
      });
  }

  userProcessUpdate() {
    let modal = this.state.modal;
    let airRailController = new AirRailController();
    var updateData = {
      airId: this.state.fields.airId,
      recordLocator: this.state.fields.recordLocator,
      scAmount: this.state.checkfields.addSplCharges
        ? this.state.fields.scAmount
        : 0,
      scDesc: this.state.checkfields.addSplCharges
        ? this.state.fields.scDesc
        : "",
    };
    //console.log("user process update data", updateData);
    airRailController
      .userProcessUpdate(updateData)
      .then((response) => {
        //console.log("user process update response", response);
        this.airLeg("", "");
      })
      .catch((error) => {
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
        console.error("There was an error...", error);
      });
  }

  async airLeg(copyId, userProcess) {
    let modal = this.state.modal;
    let airRailController = new AirRailController();
    
    //delete Legs
    if (this.state.deleteLeg.length > 0) {
      await this.state.deleteLeg.map(async (prop, key) => {
        
        var airLegData = {
          userProcess: userProcess,
          legProcess: prop["legProcess"],
          airId: this.state.fields.airId,
          legId: prop["legId"],
          airlineId: prop["airlineId"],
          travelClassId: prop["travelClassId"],
          travelDate: prop["travelDate"],
          fromLocation: prop["fromLocation"],
          fromTime: prop["fromTime"],
          fromAMPM: prop["from_AM_PM"],
          toLocation: prop["toLocation"],
          toTime: prop["toTime"],
          toAMPM: prop["to_AM_PM"],
          copyId: copyId,
        };
        //console.log("delete airLeg data", airLegData);
        await airRailController
          .insertAirLeg(airLegData)
          .then((response) => {
            //console.log("deleteAirLeg", response);
          })
          .catch((error) => {
            modal["modalSpinnerShow"] = false;
            this.setState({ modal });
            console.error("There was an error...", error);
          });
      });
    }
    //insert and update
    
    await this.state.values.map(async (prop, key) => {
      
      var airLegData = {
        userProcess: userProcess,
        legProcess: prop["legProcess"],
        airId: this.state.fields.airId,
        legId: prop["legId"],
        airlineId: prop["airlineId"],
        travelClassId: prop["travelClassId"],
        travelDate: prop["travelDate"],
        fromLocation: prop["fromLocation"],
        fromTime: prop["fromTime"],
        fromAMPM: prop["from_AM_PM"],
        toLocation: prop["toLocation"],
        toTime: prop["toTime"],
        toAMPM: prop["to_AM_PM"],
        copyId: copyId,
      };
      //console.log("insert airLeg data", airLegData);
      await airRailController
        .insertAirLeg(airLegData)
        .then(async (response) => {
          //console.log("insertAirLeg", response);
          if (this.state.values.length - 1 === key) {
            await this.bookingMap(copyId);
          }
        })
        .catch((error) => {
          modal["modalSpinnerShow"] = false;
          this.setState({ modal });
          console.error("There was an error...", error);
        });
    });
  }

  async bookingMap(copyId) {
    
    let modal = this.state.modal;
    let fields = this.state.fields;
    let airRailController = new AirRailController();
    var travellerIdStr = "";
    this.state.travellerId.map((prop) => {
      if (travellerIdStr === "") {
        travellerIdStr = prop;
      } else {
        travellerIdStr = travellerIdStr + "," + prop;
      }
    });
    //console.log("travellerIdStr", travellerIdStr);
    var airMapData = {
      travellerIdStr: travellerIdStr,
      airId: this.state.fields.airId,
      copyId: copyId,
    };
    //console.log("airMap Data", airMapData);
    await airRailController
      .insertAirMap(airMapData)
      .then(async (response) => {
        
        //console.log("insertAirMap", response);
        //console.log("insert state fields:", this.state.fields);
        await this.state.travellerId.map(async (prop, key) => {
          
          //console.log("begin Index:", prop);
          let index = this.state.fields.traveller.findIndex((x) => x == prop);
          //console.log("end Index:", index);
          var updateAirMapData = {
            airId: this.state.fields.airId,
            travellerId: prop,
            specialIns: this.state.fields.comment[index],
            airfareAmount: this.state.fields.airfare[index],
            feeAmount: 0,
            totalAmount: this.state.fields.airfare[index],
          };
          //console.log("updateAirMap Data", updateAirMapData);
          await airRailController
            .updateAirMap(updateAirMapData)
            .then((response) => {
              //console.log("updateAirMap", response);
              if (this.state.travellerId.length - 1 === key) {
                if (this.state.fields.action === "saveCopyResv") {
                  let fields = this.state.fields;
                  fields["action"] = "";
                  this.setState({ fields });
                  this.userProcessCopy();
                } else {
                  //alert("AirRail reservation completed successfully")
                  let fields = this.state.fields;
                  fields["airId"] = 0;
                  this.setState({
                    fields,
                    values: [
                      {
                        airId: 0,
                        recordLocator: "",
                        legId: 0,
                        airlineId: "-1",
                        airlineDesc: "",
                        travelClassId: "-1",
                        travelClassDesc: "",
                        travelDate: new Date(new Date().toISOString()),
                        fromLocation: "",
                        fromTime: "",
                        from_AM_PM: "",
                        toLocation: "",
                        toTime: "",
                        to_AM_PM: "",
                        scAmount: "",
                        scDescription: "",
                        BCDGenerated: false,
                        BCDValidated: false,
                      },
                    ],
                  });
                  this.cancelReview();
                  this.pageLoad();
                  modal["modalSpinnerShow"] = false;
                  this.setState({ modal });
                }
              }
            })
            .catch((error) => {
              modal["modalSpinnerShow"] = false;
              this.setState({ modal });
              console.error("There was an error...", error);
            });
        });
      })
      .catch((error) => {
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
        console.error("There was an error...", error);
      });
  }

  loadTravelerReservation() {
    //console.log("loadTraveler reservation airBooking:", this.state.airBooking);
    //console.log("loadTraveler reservation airId:", this.state.airId);
    //
    return (
      <div id="divReservation" style={{ width: "100%" }}>
        <Row>
          <Col sm={12}>
            {this.state.airId.map((prop, key) => {
              var count = 0;
              var prevAirLeg = "";
              var rowSpan = 0;
              var filterByDate =this.state.airBooking.filter((f) => f.airId == prop["airId"]);
                 const sortByDate = arr => {
                  const sorter = (a, b) => {
                     return new Date(a.travelDate).getTime() - new Date(b.travelDate).getTime();
                  }
                  arr.sort(sorter);
               };


               sortByDate(filterByDate);

              return this.state.fields.airId !== prop["airId"] ? (
                <table>
                  <tbody>
                    {this.loadTravelerReservationHeader(prop)}
                    {
                     filterByDate.map((el, i) => {
                        //console.log(el,"elFirst")
                        if (prevAirLeg !== el.legId) {
                          rowSpan = rowSpan + 1;
                          prevAirLeg = el.legId;
                          var name = "";
                          var RLN = "";
                          this.state.traveller.map((item, j) => {
                            if (el["airId"] === item["id"]) {
                              if (name === "") {
                                name = item["name"];
                              } else {
                                name = name + " / " + item["name"];
                              }
                              if (RLN === "") {
                                RLN = item["RLN"];
                              } else {
                                RLN = RLN + " / " + item["RLN"];
                              }
                            }
                          });
                          if (prop["airId"] === el["airId"]) {
                            count = count + 1;
                          }
                          
                          //console.log(el,"")
                          return prop["airId"] === el["airId"] ? (
                            this.loadTravelerReservationBody(
                              el,
                              i,
                              name,
                              RLN,
                              count,
                              rowSpan
                            )
                          ) : (
                            <tr></tr>
                          );
                        }
                      })}
                  </tbody>
                </table>
              ) : (
                <></>
              );
            })}
          </Col>
        </Row>
      </div>
    );
  }

  loadTravelerReservationHeader(prop) {
    return (
      <tr style={{ width: "100%", borderTop: "1px solid navy" }}>
        {sessionStorage.getItem("ta_expense_type_id") !== "2" &&
        sessionStorage.getItem("ta_expense_type_id") !== "11" ? (
          <>
            <td style={{ width: "10%" }}>
              <b>AirLine</b>
            </td>
            <td style={{ width: "10%" }}>
              <b>Class</b>
            </td>
            <td style={{ width: "5%" }}>
              <b>Departure</b>
            </td>
            <td style={{ width: "12.5%" }}>
              <b>Departing</b>
            </td>
            <td style={{ width: "12.5%" }}>
              <b>Arriving</b>
            </td>
          </>
        ) : (
          <td style={{ width: "50%" }}>
            <b>Record Locator</b>
          </td>
        )}
        <td style={{ width: "15%" }}>
          <b>Traveler</b>
        </td>
        <td style={{ width: "10%" }}>
          <b>Resv Type</b>
        </td>
        <td style={{ width: "12.5%" }}>
          <b>Resv #</b>
        </td>
        <td style={{ width: "12.5%" }}></td>
      </tr>
    );
  }

  loadTravelerReservationBody(prop, key, name, RLN, count, rowSpan) {
    return (
      <tr style={{ width: "100%" }}>
        {sessionStorage.getItem("ta_expense_type_id") !== "2" &&
        sessionStorage.getItem("ta_expense_type_id") !== "11" ? (
          <>
            <td style={{ width: "10%" }}>{prop["airlineDesc"]}</td>
            <td style={{ width: "10%" }}>{prop["travelClassDesc"]}</td>
            <td style={{ width: "5%" }}>
              {Utils.FormatDate(prop["travelDate"])}
            </td>
            <td style={{ width: "12.5%" }}>
              {prop["fromLocation"] +
                " " +
                prop["fromTime"] +
                " " +
                prop["from_AM_PM"]}
            </td>
            <td style={{ width: "12.5%" }}>
              {prop["toLocation"] +
                " " +
                prop["toTime"] +
                " " +
                prop["to_AM_PM"]}
            </td>
          </>
        ) : (
          <td style={{ width: "50%" }} colSpan={5}>
            {prop["recordLocator"]}
          </td>
        )}
        {count === 1 ? (
          <>
            <td style={{ width: "15%" }} rowSpan={rowSpan + 2}>
              {name}
            </td>
            <td style={{ width: "10%" }} rowSpan={rowSpan + 2}>
              {!prop["BCDGenerated"] ? "Manual" : "CTM"}
            </td>
            <td style={{ width: "12.5%" }} rowSpan={rowSpan + 2}>
              {RLN}
            </td>
            <td style={{ width: "12.5%" }} rowSpan={rowSpan + 2}>
              <Button
                type="button"
                variant="danger"
                className="btn btn-danger btn-sm"
                value="Delete"
                title="Click to delete this reservation"
                id="buttonDelete"
                name="buttonDelete"
                onClick={(e) => this.deleteBooking(prop["airId"])}
              >
                Delete
              </Button>{" "}
              &nbsp;
              <Button
                type="button"
                variant="secondary"
                className="btn btn-secondary btn-sm"
                style={{ marginLeft: "2px" }}
                value="Review"
                title="Click to edit this reservation"
                id="buttonReview"
                name="buttonReview"
                onClick={(e) => this.loadReview(prop["airId"])}
              >
                Review
              </Button>
            </td>
          </>
        ) : (
          <td style={{ width: "50%" }} colSpan={4}></td>
        )}
      </tr>
    );
  }

  displayTravelerMsg() {
    let fields = this.state.fields;
    if (this.state.TATravelerList.length === 0) {
      fields["iTravelerCnt"] = -1;
    }
    if (fields["iTravelerCnt"] > 0 || fields["iTravelerCnt"] <= 0) {
      if (this.state.fields.iTravelerCnt === -1) {
        return (
          <div id="divTravelerMsg" className="text-dark font-size-xs small">
            Click on the Traveler tab to add a traveler.
          </div>
        );
      } else {
        return (
          <div
            id="divTravelerMsg"
            style={{ color: "red", textAlign: "center" }}
            className="text-dark font-size-xs small"
          >
            Click on a traveler to add to reservation and enter airfare amount.
          </div>
        );
      }
    } else {
      return <></>;
    }
  }

  buttonControl() {
    const { loading } = this.state;
    if (
      this.state.fields.airId !== 0 &&
      this.state.fields.review &&
      !this.state.fields.isReadOnly
    ) {
      return (
        <Row>
          <Col sm={3}></Col>
          <Col sm={6}>
            <Button
              id="updateResv"
              style={{ marginRight: "5px" }}
              type="submit"
              size="sm"
              disabled={loading}
              onClick={(e) => this.onSubmit(e)}
            >
              {loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px", color: "#487afa" }}
                />
              )}
              {loading && (
                <span style={{ marginRight: "5px", color: "#487afa" }}>
                  wait...
                </span>
              )}
              {!loading && (
                <span style={{ marginRight: "5px" }}>Save Resv</span>
              )}
            </Button>
            <Button
              id="addResv"
              style={{ marginRight: "5px" }}
              type="button"
              size="sm"
              disabled={loading}
              onClick={(e) => this.onSubmit(e)}
            >
              {loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ color: "#487afa" }}
                />
              )}
              {loading && <span style={{ color: "#487afa" }}>wait...</span>}
              {!loading && <span>Add Resv</span>}
            </Button>
            <Button
              id="copyResv"
              style={{ marginRight: "5px" }}
              type="button"
              size="sm"
              disabled={loading}
              onClick={(e) => this.onSubmit(e)}
            >
              {loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ color: "#487afa" }}
                />
              )}
              {loading && <span style={{ color: "#487afa" }}>wait...</span>}
              {!loading && <span>Copy Resv</span>}
            </Button>
            <Button
              id="saveCopyResv"
              style={{ marginRight: "5px" }}
              type="button"
              size="sm"
              disabled={loading}
              onClick={(e) => this.onSubmit(e)}
            >
              {loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ color: "#487afa" }}
                />
              )}
              {loading && <span style={{ color: "#487afa" }}>wait...</span>}
              {!loading && <span>Save & Copy Resv</span>}
            </Button>
            <Button
              id="deleteResv"
              type="button"
              size="sm"
              disabled={loading}
              style={{ display: "none", marginRight: "5px" }}
              onClick={(e) => this.deleteBooking(this.state.fields.airId)}
            >
              {loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ color: "#487afa" }}
                />
              )}
              {loading && <span style={{ color: "#487afa" }}>wait...</span>}
              {!loading && <span>Delete Resv</span>}
            </Button>
            <Button
              id="cancelReview"
              style={{ marginRight: "5px" }}
              type="button"
              size="sm"
              disabled={loading}
              onClick={(e) => this.cancelReview()}
            >
              {loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ color: "#487afa" }}
                />
              )}
              {loading && <span style={{ color: "#487afa" }}>wait...</span>}
              {!loading && <span>Cancel Review</span>}
            </Button>
          </Col>
          <Col sm={3}></Col>
        </Row>
      );
    } else if (
      this.state.fields.airId !== 0 &&
      this.state.fields.review &&
      this.state.fields.isReadOnly
    ) {
      return (
        <Row>
          <Col sm={3}></Col>
          <Col sm={6}>
            <Button
              id="updateResv"
              style={{ marginRight: "5px" }}
              type="submit"
              size="sm"
              disabled={loading}
              onClick={(e) => this.onSubmit(e)}
            >
              {loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px", color: "#487afa" }}
                />
              )}
              {loading && (
                <span style={{ marginRight: "5px", color: "#487afa" }}>
                  wait...
                </span>
              )}
              {!loading && (
                <span style={{ marginRight: "5px" }}>Save Resv</span>
              )}
            </Button>
            <Button
              id="cancelReview"
              style={{ marginRight: "5px" }}
              type="button"
              size="sm"
              disabled={loading}
              onClick={(e) => this.cancelReview()}
            >
              {loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ color: "#487afa" }}
                />
              )}
              {loading && <span style={{ color: "#487afa" }}>wait...</span>}
              {!loading && <span>Cancel Review</span>}
            </Button>
          </Col>
          <Col sm={3}></Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col sm={5}></Col>
          <Col sm={2}>
            <Button
              id="saveResv"
              style={{ marginRight: "5px" }}
              type="submit"
              size="sm"
              disabled={loading}
              onClick={(e) => this.onSubmit(e)}
            >
              {loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ color: "#487afa" }}
                />
              )}
              {loading && <span style={{ color: "#487afa" }}>wait...</span>}
              {!loading && <span>Save Resv</span>}
            </Button>
          </Col>
          <Col sm={5}></Col>
        </Row>
      );
    }
  }

  //Common function
  tableSpecialCharge(text) {
    return (
      <Row id="splCharges" style={{ display: "none" }}>
        <Col sm={12} style={{ alignSelf: "right" }}>
          <table
            style={{ border: "1px", padding: "0px", borderSpacing: "2px" }}
          >
            <tr>
              <td colSpan={2} style={{ textAlign: "center", fontSize: "12px" }}>
                FOR SPECIAL CHARGES ONLY
              </td>
            </tr>
            <tr>
              <td style={{ width: "80%", fontSize: "12px" }}>
                Description {text}
              </td>
              <td
                style={{ width: "20%", alignSelf: "right", fontSize: "12px" }}
              >
                Amount
              </td>
            </tr>
            <tr>
              <td style={{ alignSelf: "top" }}>
                <FormControl
                  style={{ height: "auto" }}
                  size="sm"
                  id="scDesc"
                  as="textarea"
                  name="scDesc"
                  className="form-control input-sm"
                  value={this.state.fields.scDesc}
                  onChange={(e) => this.handleChange(e)}
                ></FormControl>
              </td>
              <td style={{ alignSelf: "top", textAlign: "right" }}>
                <tr>
                  <td>$</td>
                  <td>
                    <FormControl
                      style={{ height: "auto", textAlign: "right" }}
                      maxLength={13}
                      size="sm"
                      id="scAmount"
                      type="text"
                      name="scAmount"
                      className="form-control input-sm"
                      value={this.state.fields.scAmount}
                      onChange={(e) => this.handleChange(e)}
                    ></FormControl>
                  </td>
                </tr>
              </td>
            </tr>
          </table>
        </Col>
      </Row>
    );
  }

  formTraveller(prop, key) {
    return (
      <tr
        id={"div" + prop["travelerId"]}
        style={{ display: !prop["bit"] ? "none" : "" }}
      >
        <td style={{ padding: "0px", width: "250px" }}>
          <FormLabel style={{ height: "auto" }}>{prop["name"]}</FormLabel>
        </td>
        <td style={{ padding: "0px", width: "100px" }}>
          <FormLabel style={{ height: "auto" }}>{prop["RLN"]}</FormLabel>
        </td>
        <td style={{ padding: "0px", width: "200px" }}>
          <FormControl
            style={{ height: "auto" }}
            size="sm"
            type="text"
            id="comment"
            name="comment"
            className="form-control input-sm"
            value={this.state.fields.comment[key]}
            onChange={(e) => this.handleFormChange(key, e)}
          ></FormControl>
        </td>
        <td style={{ padding: "0px" }}>
          <tr>
            <td
              style={{
                padding: "0px",
                alignSelf: "right",
                width: "10px",
                border: "0px",
              }}
            >
              $
            </td>
            <td style={{ padding: "0px", width: "100px", border: "0px" }}>
              <FormControl
                style={{
                  height: "auto",
                  backgroundColor: this.state.fields.isReadOnly
                    ? "yellow"
                    : "#ffffff",
                }}
                size="sm"
                id="airfare"
                type="text"
                name="airfare"
                className="form-control input-sm"
                key={key}
                value={this.state.fields.airfare[key]}
                onChange={(e) => this.handleFormChange(key, e)}
              ></FormControl>
            </td>
          </tr>
        </td>
        <td style={{ padding: "0px" }}>
          <tr>
            <td
              style={{
                padding: "0px",
                alignSelf: "right",
                width: "10px",
                border: "0px",
              }}
            >
              $
            </td>
            <td style={{ padding: "0px", width: "100px", border: "0px" }}>
              <FormLabel style={{ height: "auto" }}>
                {prop["totalAmount"]}
              </FormLabel>
            </td>
          </tr>
        </td>
      </tr>
    );
  }

  /*shouldComponentUpdate(){
        //console.log("ShouldUpdate");
        this.createAirRailUI(true);
        return false;
    }
*/
  createAirRailUI(bStatus) {
    //console.log("UI", this.state.values);
    //console.log("legErrors:", this.state.legErrors);
    //console.log("this.state : ", this.state);
    
    if (this.state.values.length === 0) {
      
      this.setState((prevState) => ({
        values: [
          ...prevState.values,
          {
            airId: 0,
            recordLocator: "",
            legId: 0,
            airlineId: "-1",
            airlineDesc: "",
            travelClassId: "-1",
            travelClassDesc: "",
            travelDate: new Date(new Date().toISOString()),
            fromLocation: "",
            fromTime: "",
            from_AM_PM: "",
            toLocation: "",
            toTime: "",
            to_AM_PM: "",
            scAmount: "",
            scDescription: "",
            BCDGenerated: false,
            BCDValidated: false,
            legProcess: "legInsert",
            totalAmount: 0,
            active: true,
          },
        ],
      }));
      this.setState((prevState) => ({
        legErrors: [
          ...prevState.legErrors,
          {
            airId: "",
            recordLocator: "",
            legId: "",
            airlineId: "",
            airlineDesc: "",
            travelClassId: "",
            travelClassDesc: "",
            travelDate: "",
            fromLocation: "",
            fromTime: "",
            from_AM_PM: "",
            toLocation: "",
            toTime: "",
            to_AM_PM: "",
            scAmount: "",
            scDescription: "",
            BCDGenerated: false,
            BCDValidated: false,
            totalAmount: 0,
            legProcess: "",
          },
        ],
      }));
      return this.loadAirLeg();
    } else {
      if (
        this.state.values.filter((f) => f.legProcess != "legDelete").length ===
        0
      ) {
        this.setState((prevState) => ({
          values: [
            ...prevState.values,
            {
              airId: 0,
              recordLocator: "",
              legId: 0,
              airlineId: "-1",
              airlineDesc: "",
              travelClassId: "-1",
              travelClassDesc: "",
              travelDate: new Date(new Date().toISOString()),
              fromLocation: "",
              fromTime: "",
              from_AM_PM: "",
              toLocation: "",
              toTime: "",
              to_AM_PM: "",
              scAmount: "",
              scDescription: "",
              BCDGenerated: false,
              BCDValidated: false,
              legProcess: "legInsert",
              totalAmount: 0,
              active: true,
            },
          ],
        }));
        this.setState((prevState) => ({
          legErrors: [
            ...prevState.legErrors,
            {
              airId: "",
              recordLocator: "",
              legId: "",
              airlineId: "",
              airlineDesc: "",
              travelClassId: "",
              travelClassDesc: "",
              travelDate: "",
              fromLocation: "",
              fromTime: "",
              from_AM_PM: "",
              toLocation: "",
              toTime: "",
              to_AM_PM: "",
              scAmount: "",
              scDescription: "",
              BCDGenerated: false,
              BCDValidated: false,
              totalAmount: 0,
              legProcess: "",
            },
          ],
        }));
      }
      return this.loadAirLeg();
    }
  }

  loadAirLeg() {
    return this.state.values.map((el, elkey) => (
      <div
        id={"divLeg" + el.legId}
        key={elkey}
        style={{ display: el.legProcess !== "legDelete" ? "block" : "none" }}
      >
        <div style={{ borderBottom: "1px solid #E3E3E3" }}>
          <Row>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>
                  Airline/Railway
                  <span className="text-danger" style={{ fontSize: "16px" }}>
                    *
                  </span>
                </Form.Label>
                <Form.Control
                  as="select"
                  id="airlineId"
                  name="airlineId"
                  className="form-control form-control-sm"
                  style={{
                    backgroundColor: this.state.fields.isReadOnly
                      ? "yellow"
                      : "#ffffff",
                  }}
                  value={el.airlineId}
                  onChange={(e) => {
                    this.handleSelectMultipleChangeEvent(e, elkey);
                  }}
                >
                  {this.state.AirLineCodes.map((prop, key) => {
                    return <option value={prop["id"]}>{prop["name"]}</option>;
                  })}
                </Form.Control>
                {this.state.legErrors[elkey]["airlineId"] !== "" ? (
                  <div className="text-danger font-size-xs small font-weight-bold">
                    {this.state.legErrors[elkey]["airlineId"]}
                  </div>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>
                  Class
                  <span className="text-danger" style={{ fontSize: "16px" }}>
                    *
                  </span>
                </Form.Label>
                <Form.Control
                  as="select"
                  id="travelClassId"
                  name="travelClassId"
                  className="form-control form-control-sm"
                  style={{
                    backgroundColor: this.state.fields.isReadOnly
                      ? "yellow"
                      : "#ffffff",
                  }}
                  value={el.travelClassId}
                  onChange={(e) => {
                    this.handleSelectMultipleChangeEvent(e, elkey);
                  }}
                >
                  {this.state.TravelClassCodes.map((prop, key) => {
                    return <option value={prop["id"]}>{prop["name"]}</option>;
                  })}
                </Form.Control>
                {this.state.legErrors[elkey]["travelClassId"] !== "" ? (
                  <div className="text-danger font-size-xs small font-weight-bold">
                    {this.state.legErrors[elkey]["travelClassId"]}
                  </div>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Label>
                Depature(MM/DD/YYYY)
                <span className="text-danger" style={{ fontSize: "16px" }}>
                  *
                </span>
              </Form.Label>
              <Form.Group>
                <DatePicker
                  style={{ fontSize: "12px" }}
                  Id="travelDate"
                  name="travelDate"
                  selected={el.travelDate ? new Date(el.travelDate) : null}
                  onChange={(e) => this.handleChangeTravelDateEvent(e, elkey)}
                  isClearable
                  showMonthDropdown
                  showYearDropdown
                  className="datePicker form-control form-control-sm"
                  disabled={this.state.fields.isReadOnly ? true : false}
                />
              </Form.Group>
              {this.state.legErrors[elkey]["travelDate"] !== "" ? (
                <div
                  style={{ marginTop: "-15px" }}
                  className="text-danger font-size-xs small font-weight-bold"
                >
                  {this.state.legErrors[elkey]["travelDate"]}
                </div>
              ) : (
                <></>
              )}
            </Col>
            <Col sm={3}>
              <FormInputs
                properties={{
                  id: "fromLocation",
                  label: "From (Origin)",
                  type: "text",
                  name: "fromLocation",
                  className: "form-control input-sm form-control-sm",
                  maxlength: 50,
                  value: el.fromLocation,
                  onChange: (e) => this.handleChangeEvent(e, elkey),
                  disabled: this.state.fields.isReadOnly ? true : false,
                }}
                fieldRequired={true}
                errorMsg={
                  this.state.legErrors[elkey] !== undefined
                    ? this.state.legErrors[elkey]["fromLocation"]
                    : ""
                }
              />
            </Col>
            <Col sm={1}>
              <FormInputs
                properties={{
                  id: "fromTime",
                  label: "Time (CST)",
                  placeholder: "12:00",
                  type: "text",
                  name: "fromTime",
                  className: "form-control input-sm form-control-sm",
                  maxlength: 15,
                  value: el.fromTime,
                  onChange: (e) => this.handleChangeEvent(e, elkey),
                  onBlur: (e) => this.handleTimeFormat(e, elkey),
                  disabled: this.state.fields.isReadOnly ? true : false,
                }}
                fieldRequired={true}
                errorMsg={
                  this.state.legErrors[elkey] !== undefined
                    ? this.state.legErrors[elkey]["fromTime"]
                    : ""
                }
              />
            </Col>
            <Col sm={2} style={{ alignSelf: "center" }}>
              <Form.Group>
                <Row style={{ alignItems: "center", paddingLeft: "1rem" }}>
                  <Col sm={4} style={{ paddingRight: "1rem" }}>
                    <Form.Check
                      type="radio"
                      label="AM"
                      name={"lOrigAM" + elkey}
                      id="lOrigAM"
                      onChange={(e) => this.handleOptionChangeEvent(e, elkey)}
                      checked={el.from_AM_PM === "AM" ? true : false}
                      disabled={this.state.fields.isReadOnly ? true : false}
                    />
                  </Col>
                  <Col sm={4} style={{ paddingRight: "1rem" }}>
                    <Form.Check
                      type="radio"
                      label="PM"
                      name={"lOrigPM" + elkey}
                      id="lOrigPM"
                      onChange={(e) => this.handleOptionChangeEvent(e, elkey)}
                      checked={el.from_AM_PM === "PM" ? true : false}
                      disabled={this.state.fields.isReadOnly ? true : false}
                    />
                  </Col>
                  <Col sm={4}></Col>
                </Row>
              </Form.Group>
              {this.state.legErrors[elkey]["from_AM_PM"] !== "" ? (
                <div className="text-danger font-size-xs small font-weight-bold">
                  {this.state.legErrors[elkey]["from_AM_PM"]}
                </div>
              ) : (
                <></>
              )}
            </Col>
            <Col sm={1}></Col>
          </Row>
          <Row>
            <Col sm={6} style={{ alignSelf: "flex-end" }}>
              <Form.Group>
                <Button
                  type="button"
                  className="btn btn-primary btn-sm"
                  value="Delete Leg"
                  id="deleteLegButton"
                  name="deleteLegButton"
                  onClick={(e) => this.deleteLeg(e, elkey)}
                  style={{
                    display: this.state.fields.isReadOnly ? "none" : "block",
                  }}
                >
                  Delete Leg
                </Button>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <FormInputs
                properties={{
                  id: "toLocation",
                  label: "To (Destination)",
                  type: "text",
                  name: "toLocation",
                  className: "form-control input-sm form-control-sm",
                  maxlength: 50,
                  value: el.toLocation,
                  onChange: (e) => this.handleChangeEvent(e, elkey),
                  disabled: this.state.fields.isReadOnly ? true : false,
                }}
                fieldRequired={true}
                errorMsg={
                  this.state.legErrors[elkey] !== undefined
                    ? this.state.legErrors[elkey]["toLocation"]
                    : ""
                }
              />
            </Col>
            <Col sm={1}>
              <FormInputs
                properties={{
                  id: "toTime",
                  label: "Time (CST)",
                  placeholder: "12:00",
                  type: "text",
                  name: "toTime",
                  className: "form-control input-sm form-control-sm",
                  maxlength: 15,
                  value: el.toTime,
                  onChange: (e) => this.handleChangeEvent(e, elkey),
                  onBlur: (e) => this.handleTimeFormat(e, elkey),
                  disabled: this.state.fields.isReadOnly ? true : false,
                }}
                fieldRequired={true}
                errorMsg={
                  this.state.legErrors[elkey] !== undefined
                    ? this.state.legErrors[elkey]["toTime"]
                    : ""
                }
              />
            </Col>
            <Col sm={2} style={{ alignSelf: "center" }}>
              <Form.Group>
                <Row style={{ alignItems: "center", paddingLeft: "1rem" }}>
                  <Col sm={4} style={{ paddingRight: "1rem" }}>
                    <Form.Check
                      type="radio"
                      label="AM"
                      name={"lDestAM" + elkey}
                      id="lDestAM"
                      onChange={(e) => this.handleOptionChangeEvent(e, elkey)}
                      title="AM"
                      checked={el.to_AM_PM === "AM" ? true : false}
                      value={el.to_AM_PM === "AM" ? "AM" : ""}
                      disabled={this.state.fields.isReadOnly ? true : false}
                    />
                  </Col>
                  <Col sm={4} style={{ paddingRight: "1rem" }}>
                    <Form.Check
                      type="radio"
                      label="PM"
                      name={"lDestAM" + elkey}
                      id="lDestPM"
                      onChange={(e) => this.handleOptionChangeEvent(e, elkey)}
                      title="PM"
                      checked={el.to_AM_PM === "PM" ? true : false}
                      value={el.to_AM_PM === "PM" ? "PM" : ""}
                      disabled={this.state.fields.isReadOnly ? true : false}
                    />
                  </Col>
                  <Col sm={4}></Col>
                </Row>
              </Form.Group>
              {this.state.legErrors[elkey]["to_AM_PM"] !== "" ? (
                <div className="text-danger font-size-xs small font-weight-bold">
                  {this.state.legErrors[elkey]["to_AM_PM"]}
                </div>
              ) : (
                <></>
              )}
            </Col>
            <Col sm={1}></Col>
          </Row>
        </div>
        {this.state.values.length === elkey + 1 ? (
          <Row style={{ marginTop: "10px", alignItems: "center" }}>
            <Col sm={6} style={{ alignSelf: "flex-end" }}>
              <Form.Group>
                <Button
                  type="button"
                  className="btn btn-primary btn-sm"
                  title="Click to add a leg"
                  value="Add Leg"
                  id="addButton"
                  name="addButton"
                  onClick={this.addLeg.bind(this)}
                  style={{
                    display: this.state.fields.isReadOnly ? "none" : "block",
                  }}
                >
                  Add Leg
                </Button>
              </Form.Group>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </div>
    ));
  }
  displayAirRail = () => {
    //console.log("TATravelerTable: ", this.state.TATravelerTable);
    return (
      <>
        {!this.state.fields.isReadOnly ? (
          <>
            <Row>
              <Col sm={12} style={{ textAlign: "right" }}>
                <Button
                  id="print"
                  type="button"
                  size="sm"
                  onClick={() => window.print()}
                >
                  Print
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <div style={{ fontSize: "7pt", fontWeight: "bold" }}>
                  <b>MANUAL RESERVATIONS ONLY</b>
                  <span className="text-danger" style={{ fontSize: "16px" }}>
                    *
                  </span>
                  <br />
                  Select Traveler
                </div>
                <Form.Group>
                  <Form.Control
                    as="select"
                    multiple={true}
                    id="traveler_id"
                    name="traveler_id"
                    value={this.state.travellerId}
                    className="form-control form-control-sm"
                    onChange={this.handleSelectMultipleChange}
                  >
                    {this.state.TATravelerList.map((prop, key) => {
                      return (
                        <option selected={true} value={prop["travelerId"]}>
                          {prop["name"] + " " + prop["RLN"]}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <div style={{ fontSize: "7pt", fontWeight: "bold" }}>
                    (Ctrl to select multiple)
                  </div>
                  {this.state.errors["travellerId"] !== "" ? (
                    <div className="text-danger font-size-xs small font-weight-bold">
                      {this.state.errors["travellerId"]}
                    </div>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Col>
              <Col sm={9}></Col>
            </Row>
            <div
              style={{
                borderBottom: "1px solid rgb(227, 227, 227)",
                marginBottom: "15px",
              }}
            ></div>
          </>
        ) : (
          <></>
        )}
        {this.createAirRailUI(false)}
        <Row>
          <Col sm={5}>
            <div style={{ color: "#FF4A55" }}>
              <FormCheckBox
                properties={{
                  label: "Add Special Charges",
                  id: "addSplCharges",
                  name: "addSplCharges",
                  type: "checkbox",
                  className: "form-control-md",
                  onChange: this.handleCheckChange,
                  checked: this.state.checkfields.addSplCharges,
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={9}>
            <div style={{ width: "100%", marginTop: "0px" }}>
              <Table id="travelerTable" hover>
                <thead
                  style={{
                    backgroundColor: "#00B0F0",
                    fontSize: "large",
                    height: "11px",
                  }}
                >
                  <tr>
                    <th>Traveler</th>
                    <th>Resv #</th>
                    <th>Comment</th>
                    <th>Airfare</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.TATravelerTable.map((prop, key) => (
                    <tr
                      id={"div" + prop["travelerId"]}
                      style={{ display: !prop["bit"] ? "none" : "" }}
                    >
                      <td style={{ padding: "0px", width: "250px" }}>
                        <FormLabel style={{ height: "auto" }}>
                          {prop["name"]}
                        </FormLabel>
                      </td>
                      <td style={{ padding: "0px", width: "100px" }}>
                        <FormLabel style={{ height: "auto" }}>
                          {prop["RLN"]}
                        </FormLabel>
                      </td>
                      <td style={{ padding: "0px", width: "200px" }}>
                        <FormControl
                          style={{ height: "auto" }}
                          size="sm"
                          type="text"
                          id="comment"
                          name="comment"
                          className="form-control input-sm"
                          value={this.state.fields.comment[key]}
                          onChange={(e) => this.handleFormChange(key, e)}
                        ></FormControl>
                      </td>
                      <td style={{ padding: "0px" }}>
                        <tr>
                          <td
                            style={{
                              padding: "0px",
                              alignSelf: "right",
                              width: "10px",
                              border: "0px",
                            }}
                          >
                            $
                          </td>
                          <td
                            style={{
                              padding: "0px",
                              width: "100px",
                              border: "0px",
                            }}
                          >
                            <FormControl
                              style={{
                                height: "auto",
                                backgroundColor: this.state.fields.isReadOnly
                                  ? "yellow"
                                  : "#ffffff",
                              }}
                              size="sm"
                              id="airfare"
                              type="text"
                              name="airfare"
                              className="form-control input-sm"
                              key={key}
                              value={this.state.fields.airfare[key]}
                              onChange={(e) => this.handleFormChange(key, e)}
                            ></FormControl>
                          </td>
                        </tr>
                      </td>
                      <td style={{ padding: "0px" }}>
                        <tr>
                          <td
                            style={{
                              padding: "0px",
                              alignSelf: "right",
                              width: "10px",
                              border: "0px",
                            }}
                          >
                            $
                          </td>
                          <td
                            style={{
                              padding: "0px",
                              width: "100px",
                              border: "0px",
                            }}
                          >
                            <FormLabel style={{ height: "auto" }}>
                              {prop["totalAmount"]}
                            </FormLabel>
                          </td>
                        </tr>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col sm={3}>
            {this.tableSpecialCharge("(Greeter services, etc.)")}
          </Col>
        </Row>
        {this.buttonControl()}
        {/* commented as iTravellerCount not needed 
            {this.displayTravelerMsg()}*/}
        <div id="divClicktoReview" style={{ marginTop: "15px" }}>
          {this.state.airBooking.length > 0 ? (
            <Row>
              <Col sm={12}>
                <div
                  className="text-yellow font-size-xs small"
                  style={{
                    backgroundColor: "navy",
                    color: "yellow",
                    textAlign: "center",
                  }}
                >
                  Click the Review button to complete each traveler's
                  reservation
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
        {this.loadTravelerReservation()}
        <div
          id="divReviewComplete"
          style={{
            borderTop:
              this.state.airBooking.length > 0 ? "1px solid navy" : "0px",
          }}
        >
          {this.state.airBooking.length > 0 ? (
            <Row>
              <Col sm={12}>
                <div
                  className="text-danger font-size-xs small"
                  style={{ textAlign: "center", fontStyle: "bold" }}
                >
                  REVIEW/COMPLETED TRAVEL SECTION
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  codingTab() {
    if (
      sessionStorage.getItem("page") === "submitter" &&
      parseInt(sessionStorage.getItem("ta_status_id")!) === 1 &&
      (parseInt(sessionStorage.getItem("ta_tier_code")!) !== 0 ||
        sessionStorage.getItem("ta_is_corporate_card") === "true")
    ) {
      return (
        <Tab
          headerClass="my-tab-header"
          activeHeaderClass="my-active-tab-header"
          title="Click for summary page"
          label="Print"
        ></Tab>
      );
    }
    if (
      sessionStorage.getItem("page") === "approver" ||
      sessionStorage.getItem("page") === "submitter"
    ) {
      if (
        parseInt(sessionStorage.getItem("ta_status_id")!) !== 1 ||
        (parseInt(sessionStorage.getItem("ta_status_id")!) === 1 &&
          parseInt(sessionStorage.getItem("ta_tier_code")!) === 0) ||
        sessionStorage.getItem("ta_is_corporate_card") === "false"
      ) {
        return (
          <Tab
            headerClass="my-tab-header"
            activeHeaderClass="my-active-tab-header"
            title="Click for coding page"
            label="Coding"
          ></Tab>
        );
      }
    }
  }
  decisionTabTAHistoryResubmit() {
    //
    if (sessionStorage.getItem("ta_status_desc") === "Re-Submit") {
      return (
        <Tab
          headerClass="my-tab-header"
          activeHeaderClass="my-active-tab-header"
          title="Click for decision page"
          label="Decision"
        ></Tab>
      );
    } else {
      return (
        <Tab
          headerClass="nav-link disabled"
          style={{ color: "white" }}
          disabled
        ></Tab>
      );
    }
  }
  redirectToPage(tabLabel) {
    switch (tabLabel) {
      case "Traveler":
        this.props.history.push("/admin/traveller");
        break;
      case "Air / Rail":
        this.props.history.push("/admin/airrail");
        break;
      case "Hotel":
        this.props.history.push("/admin/hotel");
        break;
      case "Rental Car":
        this.props.history.push("/admin/rentalcar");
        break;
      case "Car Service":
        this.props.history.push("/admin/carservice");
        break;
      case "Coding":
        this.props.history.push({
          pathname: "/admin/codingsummary",
          state: { from: "" },
        });
        break;
      case "Print":
        this.props.history.push({
          pathname: "/admin/codingsummary",
          state: { from: "" },
        });
        break;
      case "Decision":
        this.props.history.push({
          pathname: "/admin/decision",
          state: { from: "tahistory" },
        });
        break;
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="content">
        <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Card border="light" style={{ width: "100%" }}>
                <Card.Body>
                  <Form autoComplete="off">
                    <Alert
                      variant="danger"
                      style={{
                        display: this.state.errMsg ? "inherit" : "none",
                      }}
                    >
                      {this.state.errMsg}
                    </Alert>
                    <AuthStatus />
                    <section style={{ marginTop: "10px" }}>
                      <div style={{ textAlign: "right" }}>
                        <SubmitButton
                          loading={this.state.loading}
                          redirect={this.props.history}
                        />
                      </div>
                      <Tabs
                        onSelect={(index, label) =>
                          this.redirectToPage(`${label}`)
                        }
                        selected={1}
                        mountOnExit
                      >
                        <Tab
                          headerClass="my-tab-header"
                          activeHeaderClass="my-active-tab-header"
                          title="Click for traveler page"
                          label="Traveler"
                        ></Tab>
                        <Tab
                          headerClass="my-tab-header"
                          activeHeaderClass="my-active-tab-header"
                          title="Click for air/rail page"
                          label="Air / Rail"
                        >
                          {this.displayAirRail()}
                        </Tab>
                        <Tab
                          headerClass="my-tab-header"
                          activeHeaderClass="my-active-tab-header"
                          title="Click for hotel page"
                          label="Hotel"
                        ></Tab>
                        <Tab
                          headerClass="my-tab-header"
                          activeHeaderClass="my-active-tab-header"
                          title="Click for rental car page"
                          label="Rental Car"
                        ></Tab>
                        <Tab
                          headerClass="my-tab-header"
                          activeHeaderClass="my-active-tab-header"
                          title="Click for car service page"
                          label="Car Service"
                        ></Tab>
                        {this.codingTab()}
                        {this.decisionTabTAHistoryResubmit()}
                      </Tabs>
                    </section>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
