import React, { Component,useState } from "react";
import {FormLabel,FormGroup,Container,Row,Col,InputGroup,Form,Navbar, Nav, Alert,Button,Card,CardGroup,FormControl,Modal,Table} from "../../../../node_modules/react-bootstrap";
import {Tabs, Tab} from 'react-bootstrap-tabs';
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import '../../../assets/css/Style.css'
import { FormInputs } from "../../CustomComponents/FormInput";
import { AuthStatus } from "../Common/AuthStatus"
import * as Utils from '../../../js/Utils.js';
import "react-datepicker/dist/react-datepicker.css";
import { IRentalCarParameter, Istate } from "../../../Model/IRentalCar";
import { CommonController } from "../../../Controllers/CommonController";
import { RentalCarController } from "../../../Controllers/RentalCarController";
import DatePicker from 'react-datepicker';
import validator from 'validator';
import dateFormat from 'dateformat'
import { alert, confirm } from 'react-bootstrap-confirmation';
import { SubmitButton } from "../Common/SubmitButton";
import {FormCheckBox} from '../../CustomComponents/FormCheckBox';
import { format } from 'date-fns'
export class RentalCar extends Component<IRentalCarParameter,Istate> {
    constructor(props:IRentalCarParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state = {
            fields: {sessionTA: sessionStorage.getItem('ta'), carRentId: 0, carCompany: '', city: '', rate: this.formatCurrency(0), numberOfDays:0, tax: this.formatCurrency(0), otherCosts: this.formatCurrency(0), total: this.formatCurrency(0), scAmount: 0, scDescription: '', bcdGenerated: false, bcdValidated: false, RLN: '', bcdGeneratedEditMode: false, traveller: [], resv: [], comment: [], action:'', review: false, isReadOnly:false},
            checkfields: {addSplCharges:false},
            selectfields: {rentalBillId:'-1', rentalBillDesc:'', rentalTypeId:'-1', rateType:''},
            errors: {travellerId:'',rentalBillId:'', carCompany: '', city: '', pickUp:'', return:'', rentalTypeId:'', rate: '', numberOfDays:'', scAmount:'', scDescription:''},
            loading:false,
            errMsg:"",
            modal: {modalSpinnerShow: false},
            BillingType:[],
            RentalType:[],
            TATravelerList:[],
            TATravelerTable:[],
            travellerId:[],
            Booking:[],
            traveller:[],
            carRentId:[],
            pickUp: new Date(new Date().toISOString()),
            return: new Date(new Date().toISOString()), 
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleChange = this.handleChange.bind(this);    
        this.handleChangePickUpDate = this.handleChangePickUpDate.bind(this);
        this.handleChangeReturnDate = this.handleChangeReturnDate.bind(this);
        this.handleSelectMultipleChange = this.handleSelectMultipleChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        modal['modalSpinnerShow']=true;
        fields['sc_amount'] = this.formatCurrency(0);
        selectfields['rateType'] = "Days";
        this.setState({ modal,selectfields});
        let rentalCarController = new RentalCarController();
        rentalCarController.getBillingType(sessionStorage.getItem("ta_is_corporate_card")!).then(jsonBillingType => {
            this.setState({                           
                BillingType : JSON.parse(JSON.stringify(jsonBillingType))
            });
            //console.log("BillingType:", this.state.BillingType)
            if(sessionStorage.getItem("ta_is_corporate_card") === "true"){
                let selectfields = this.state.selectfields;
                this.state.BillingType.map(prop => {
                    selectfields["rentalBillId"] = prop['id'];
                    selectfields["rentalBillDesc"] = prop["name"];
                })
                this.setState({selectfields});
            }
            modal['modalSpinnerShow']=false;
            this.setState({ modal});
        }).catch(error => {
            console.error("There was an error", error);
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        })
        rentalCarController.getRentalType().then(jsonRentalType => {
            this.setState({                           
                RentalType : JSON.parse(JSON.stringify(jsonRentalType))
            });
            //console.log("RentalType:", this.state.RentalType)
            modal['modalSpinnerShow']=false;
            this.setState({ modal});
        }).catch(error => {
            console.error("There was an error", error);
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        })
        var data={
            id: '',
            view: 'rental_car',
            bInner: 'false',
            sessionTA: this.state.fields.sessionTA
        }
        let commonController = new CommonController();
        commonController.getTATraveler(data).then(jsonTravellers => {
            this.setState({                           
                TATravelerList : JSON.parse(JSON.stringify(jsonTravellers)),
                TATravelerTable : JSON.parse(JSON.stringify(jsonTravellers))
            });
            this.state.TATravelerTable.map((prop, key) => {
                let fields = this.state.fields;
                fields["traveller"][key] = prop['travelerId'];
                fields["resv"][key] = prop["RLN"];
                fields["comment"][key] = prop["specialInstructions"];
                this.setState({fields});
            })
            //console.log("TATravelerList, ",this.state.TATravelerList)
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error loading Traveler..., " + error)
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        });
        this.pageLoad();
    }

    pageLoad(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        this.setState({ Booking:[], traveller:[]})
        let rentalCarController = new RentalCarController();
        let commonController = new CommonController();
        rentalCarController.getRentalCarBookingCarRentId(this.state.fields.carRentId, this.state.fields.sessionTA).then(jsonCarRentId => {
            this.setState({                           
                carRentId:JSON.parse(JSON.stringify(jsonCarRentId))
            });
            //console.log("CarRentId", this.state.carRentId)
            this.state.carRentId.map((prop, key) => {
                console.log ("key",key);
                rentalCarController.getBooking(this.state.fields.sessionTA, prop['carRentId']).then(jsonBooking => {
                this.setState({                           
                    Booking : [...this.state.Booking, ...(JSON.parse(JSON.stringify(jsonBooking)))] 
                });
                //console.log("Booking", this.state.Booking)
                var data1 = {
                    id: prop['carRentId'],
                    view: "rental_car",
                    bInner: true,
                    sessionTA: this.state.fields.sessionTA
                }
                commonController.getTATraveler(data1).then(jsonTravellers => {
                    this.setState({                           
                        traveller : [...this.state.traveller, ...(JSON.parse(JSON.stringify(jsonTravellers)))]
                    });
                    //console.log("traveller", this.state.traveller)
                }).catch(error => {
                    console.error("There was an error loading traveller..., " + error)
                });
            })
        })
        modal['modalSpinnerShow']=false;
        this.setState({ modal });
        }).catch(error => {
            console.error("There was an error loading Traveler..., " + error)
        });
    }

    handleChange(e:any){
        let fields = this.state.fields;
        if (e.currentTarget.name === 'scAmount'){
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        else{
            if (e.currentTarget.value.trim().length > 0){
                fields[e.currentTarget.name] = e.currentTarget.value;
            }
            else{
                fields[e.currentTarget.name] = e.currentTarget.value.trim();
            }
        }
        this.setState({ fields });
    }
    handleCheckChange=(e:any)=>{
        
        let checkfields = this.state.checkfields;
        let fields = this.state.fields;
        if (e.currentTarget.name === "addSplCharges") {
            checkfields[e.currentTarget.name] = !this.state.checkfields.addSplCharges
        }
        const el:HTMLElement = document.getElementById('splCharges') as HTMLElement;
        if(checkfields["addSplCharges"]){
            el.style.display = "block";
        }
        else
        {
            fields["scDescription"] = "";
            fields["scAmount"] = 0;
            el.style.display = "none";
        }
        this.setState({
            checkfields, fields
        });
    }
    handleFormChange = (i, e:any) => {
        let fields = this.state.fields;
        if (e.currentTarget.value.trim().length > 0){
            fields[e.currentTarget.name][i] = e.currentTarget.value;
        }
        else{
            fields[e.currentTarget.name][i] = e.currentTarget.value.trim();
        }
        this.setState({ fields });
    }

    handleChangePickUpDate = date => {
        // var today = new Date();
        this.setState({
          pickUp: date
        });
        if(date!=null){
            const today = new Date(); // Current date
            const selectedDate = new Date(date); // Convert the selected date to a Date object
        
            // Standardize to UTC for date-only comparison (ignores time)
            const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
            const selectedDateUTC = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));
        
        
                  
              

        // if (format(today, "MM/dd/yyyy") > format(date, "MM/dd/yyyy")) {
        //     let errors = this.state.errors;
        //     errors["pickUp"] = "The date you have entered has already past";
        //   } 
          
          
        if (todayUTC > selectedDateUTC) {
            let errors = this.state.errors;
           errors["pickUp"] = "The date you have entered has already past";
        }
          else {
            this.clearError();
          }}
    }

    handleChangeReturnDate = date => {
        // var today = new Date();
        
        this.setState({
          return: date
        });
        if(date!=null){
        // if (format(today, "MM/dd/yyyy") > format(date, "MM/dd/yyyy")) {
        //     let errors = this.state.errors;
        //     errors["return"] = "The date you have entered has already past";
        //   }
          
          
        const today = new Date(); // Current date
        const selectedDate = new Date(date); // Convert the selected date to a Date object
    
        // Standardize to UTC for date-only comparison (ignores time)
        const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
        const selectedDateUTC = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));
    
              
            if (todayUTC > selectedDateUTC) {
                let errors = this.state.errors;
                errors["return"] = "The date you have entered has already past";
            }
          
          else {
            this.clearError();
          }}
    }

    handleSelectChange(e:any) {
        
        let selectfields = this.state.selectfields;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        if(e.currentTarget.name === 'rentalBillId'){
            selectfields["rentalBillDesc"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
            if(e.currentTarget.options[e.currentTarget.selectedIndex].value === "ALL" || e.currentTarget.options[e.currentTarget.selectedIndex].value === "CAR"){
                alert("By selecting Direct Bill, all invoices will be processed regardless of the dollar amount and/or number of days on the TA",{title: "eTA - Rental Car"})
            }
        }
        this.setState({ selectfields });
    }

    handleSelectMultipleChange(e:any){
        var options = e.currentTarget.options;
        var value : string[] = [];
        var element:HTMLElement | null;
        
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                element=document.getElementById("div"+options[i].value) as HTMLElement
                if (element !== null){
                    element.style.display = "";
                }
                value.push(options[i].value);
            }
            else{
                element=document.getElementById("div"+options[i].value) as HTMLElement
                if (element !== null){
                    element.style.display = "none";
                }
            }
        }
        if(e.currentTarget.name === 'travellerId'){
            this.setState({travellerId: value});
        }
    }

    formatCurrency(amount){
        var floatAmount = parseFloat(amount);
        return floatAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    getBooking(carRentId, editMode){
        let modal = this.state.modal;
        let rentalCarController = new RentalCarController();
        rentalCarController.getBooking(this.state.fields.sessionTA, carRentId).then(jsonBooking => {
            //console.log("Review :", jsonBooking);
            this.fillBookingDetails(jsonBooking, editMode);
        }).catch(error => {
            modal['modalSpinnerShow'] = false;
            this.setState(modal);
            console.error("There was an error loading booking details..., " + error)
        });
    }
    retrieveControls(){
        
        var taStatus = sessionStorage.getItem("ta_status_desc");
        var bcdResubmit = false;
        var IsReadonly = true;

        // if BCD generated reservation
        if (this.state.fields.bcdGeneratedEditMode && this.state.fields.action !== "deleteResv" && this.state.fields.hotelId !== 0 && (this.state.fields.action !== "updateResv" && this.state.fields.action !== ""))
        {    
            let fields = this.state.fields;
            let commonController = new CommonController();
            commonController.getApproverHistoryBCD(this.state.fields.sessionTA).then(response => {
                
                bcdResubmit = (response.success)?true:false;
               
                // if TA is in BCD resubmit status 
	            if(taStatus === "Re-Submit" && bcdResubmit){
                    IsReadonly = false;
                    fields['isReadOnly'] = false;
                }

                if(IsReadonly){
                    fields['isReadOnly'] = true;
                }
                this.setState({fields})
            }).catch(error => {
                console.error("There was an error...");
            })
        }
    }
    getTATraveler(carRentId, bInner){
        let modal = this.state.modal;
        var data={
            id: carRentId,
            view:'rental_car',
            bInner: bInner,
            sessionTA: this.state.fields.sessionTA
        }
        let commonController = new CommonController();
        commonController.getTATraveler(data).then(jsonTravellers => {
            //console.log("JsonTravellers", jsonTravellers);
            var travellerId: any= [];
            jsonTravellers.map(prop => {
                travellerId.push(prop["travelerId"]);
            })
            this.setState({travellerId : travellerId, TATravelerTable : JSON.parse(JSON.stringify(jsonTravellers))})
            /*var tempTraveller : Array<{"id": string; "travelerId": string; "isDH": string; "address": string; "city": string; "stateProvinceId": string; "zip": string; "phone": string; "ssn": string; "type": string; "issuer": string; "callLetters": string; "travelPurpose": string; "fedId": string; "radioLetterReceived": string; "overrideReceived": string; "preapprovalReceived": string; "firstName": string; "lastName": string; "name": string; "formOnFile": string; "travelRestrictions": string; "RLN": string; "airfareAmount": string; "feeAmount": string; "totalAmount": string; "bit": boolean; "specialInstructions": string}> = [];
            jsonTravellers.map((item,i) => {
                var count = 0;
                this.state.TATravelerTable.map((prop,key) => {
                    if(item["travelerId"] != prop["travelerId"]){
                        count = count + 1;
                    }
                    if(key + 1 === this.state.TATravelerTable.length){
                        if(count >= 1){
                            tempTraveller.push(prop);
                        }
                    }
                })
            })
            //console.log("tempTraveller", tempTraveller)
            tempTraveller.map(prop => jsonTravellers.push(prop));
            this.setState({                           
                TATravelerTable : jsonTravellers
            });*/
            this.state.TATravelerList.map((item,i) => {
                var filterTraveller = jsonTravellers.filter(f=>f.travelerId == item.travelerId).length;
                
                //console.log("****************-outside Length:"+filterTraveller, i)
                if(filterTraveller === 0){
                    //console.log("****************-inside")
                    //console.log("****************-inside TravellerID:", item.travelerId)
                    this.state.TATravelerTable.push(item);
                }
            });
            this.state.TATravelerTable.map((prop, key) => {
                let fields = this.state.fields;
                fields["traveller"][key] = prop['travelerId'];
                fields["resv"][key] = prop["RLN"];
                fields["comment"][key] = prop["specialInstructions"];
                this.setState({fields});
            })
            modal['modalSpinnerShow'] = false;
            this.setState(modal);
        }).catch(error => {
            modal['modalSpinnerShow'] = false;
            this.setState(modal);
            console.error("There was an error loading Traveler..., " + error)
        });
    }

    fillBookingDetails(booking, editMode:boolean){
        let fields = this.state.fields;
        let selectfields = this.state.fields;
        let checkfields = this.state.checkfields;
        if (booking.length > 0){            
            booking.map((prop, key) => {
                if(key === 0){
                    
                    fields['carRentId'] = prop["carRentId"];
                    selectfields['rentalBillId'] = prop["rentalBillId"];
                    selectfields['rentalBillDesc'] = prop["rentalBillDesc"];
                    selectfields['rentalTypeId'] = prop["rentalTypeId"];
                    fields['carCompany'] = prop["carCompany"];
                    fields['city'] = prop["city"];
                    fields['rate'] = this.formatCurrency(prop["rate"]);
                    fields['tax'] = this.formatCurrency(prop["tax"]);
                    fields['otherCosts'] = this.formatCurrency(prop["otherCosts"]);
                    fields['total'] = this.formatCurrency(prop["total"]);
                    if (prop["scDescription"] !== "" || prop["scAmount"] > 0){
                        checkfields["addSplCharges"] = true;
                    }
                    else
                    {
                        checkfields["addSplCharges"] = false;
                    }
                    fields['scAmount'] = this.formatCurrency(prop["scAmount"]);
                    fields['scDescription'] = prop["scDescription"];
                    fields['bcdGenerated'] = prop["BCDGenerated"];
                    fields['bcdValidated'] = prop["BCDValidated"];
                    fields['RLN'] = prop["RLN"];
                    fields['numberOfDays'] = prop["numberOfDays"];
                    selectfields['rateType'] = prop["rateType"];
                    if (editMode){
                        fields['bcdGeneratedEditMode'] = prop["BCDGenerated"]
                    }
                    //console.log("Return Date: ", prop["returnDate"]);
                    //console.log("Return Format Date:", Utils.FormatDate(prop["returnDate"]));
                    //console.log("Return New Date FormatDate: ", new Date(Utils.FormatDate(prop["returnDate"])));
                    //console.log("Return New Date: ", new Date(prop["returnDate"]));
                    //let d = new Date(Date.parse(prop["returnDate"])).setHours(0, 0, 0, 0);
                    ////console.log("Return timestamp as zero:",d);                 
                    ////console.log("Return New Date: ", d);   
                    var pickupDate, returnDate;
                    pickupDate = prop["pickupDate"].substring(0,10).split('-')
                    pickupDate = pickupDate[1] + '-' + pickupDate[2] + '-' + pickupDate[0]
                    returnDate = prop["returnDate"].substring(0,10).split('-')
                    returnDate = returnDate[1] + '-' + returnDate[2] + '-' + returnDate[0]                 
                    this.setState({ fields, checkfields, selectfields, pickUp: new Date(pickupDate), return: new Date(returnDate)});
                    if (this.state.fields.action === 'review'){
                        this.retrieveControls();
                    }
                    const el:HTMLElement = document.getElementById('splCharges') as HTMLElement;
                    if(checkfields["addSplCharges"]){
                        el.style.display = "block";
                    }
                    else
                    {
                        fields["scDescription"] = "";
                        fields["scAmount"] = 0;
                        el.style.display = "none";
                    }
                }
            })
        }
        else{
            this.clearBookingEntryfields();
            if (editMode){
                fields['bcdGeneratedEditMode'] = 0;
            }
            this.setState({ fields});
        }
    }

    clearBookingEntryfields(){
        let fields = this.state.fields;
        let selectfields = this.state.fields;
        fields['carRentId'] = 0;
        if(sessionStorage.getItem("ta_is_corporate_card") === "false"){
            selectfields['rentalBillId'] = "-1";
            selectfields['rentalBillDesc'] = "";  
        }
        selectfields['rentalTypeId'] = "-1";
        fields['carCompany'] = "";
        fields['city'] = "";
        fields['rate'] = this.formatCurrency(0);
        fields['tax'] = this.formatCurrency(0);
        fields['otherCosts'] = this.formatCurrency(0);
        fields['total'] = this.formatCurrency(0);
        fields['scAmount'] = this.formatCurrency(0);
        fields['scDescription'] = "";
        fields['bcdGenerated'] = false;
        fields['bcdValidated'] = false;
        fields['RLN'] = "";
        fields['numberOfDays'] = 0;
        selectfields['rateType'] = "";
        fields["action"] = "";
        this.setState({ fields, selectfields, pickUp: new Date(new Date().toISOString()), return: new Date(new Date().toISOString())});
    }

    clearError(){
        let errors = this.state.errors;
        errors["travellerId"] = "";
        errors["rentalBillId"] = "";
        errors["carCompany"] = "";
        errors["carCity"] = "";
        errors["pickUp"] = "";
        errors["return"] = "";
        errors["rentalTypeId"] = "";
        errors["rate"] = "";
        errors["numberOfDays"] = "";
        errors["scAmount"] = "";
        errors["scDescription"] = "";
        errors["city"]="";
        this.setState({ errors });
    }

    validateForm() {
        this.clearError();
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        let errors = this.state.errors;
        let modal = this.state.modal;
        let formIsValid = true;
        if(this.state.travellerId.length === 0){
            formIsValid = false;
            this.state.errors["travellerId"] = "Please select traveller";
        }
        if(selectfields["rentalBillId"] === "-1"){
            formIsValid = false;
            errors["rentalBillId"] = "Please Select a Billing Type";
        }
        if(!fields["carCompany"]){
            formIsValid = false;
            errors["carCompany"] = "Please Enter the Car Company";
        }
        if(!fields["city"]){
            formIsValid = false;
            errors["city"] = "Please Enter a City";
        }
        if(!validator.isDate(dateFormat(this.state.pickUp,"yyyy/mm/dd"))){
            formIsValid = false;
            errors["pickUp"] = "Please enter a valid date";
        }
        if(!validator.isDate(dateFormat(this.state.return,"yyyy/mm/dd"))){
            formIsValid = false;
            errors["return"] = "Please enter a valid date";
        }
        // if(validator.isBefore(dateFormat(this.state.pickUp,"yyyy/mm/dd")) && dateFormat(this.state.pickUp,"yyyy/mm/dd") !== dateFormat(Date.now(),"yyyy/mm/dd")){
        //     formIsValid = false;
        //     errors["pickUp"] = "The date you have entered has already past";
        // }
        // if(validator.isBefore(dateFormat(this.state.return,"yyyy/mm/dd")) && dateFormat(this.state.return,"yyyy/mm/dd") !== dateFormat(Date.now(),"yyyy/mm/dd")){
        //     formIsValid = false;
        //     errors["return"] = "The date you have entered has already past";
        // }
        if(!validator.isAfter(dateFormat(this.state.return,"yyyy/mm/dd"),dateFormat(this.state.pickUp,"yyyy/mm/dd")) && dateFormat(this.state.return,"yyyy/mm/dd") !== dateFormat(this.state.pickUp,"yyyy/mm/dd")){
            formIsValid = false;
            errors["return"] = "The Return date must be greater than or equal to the Pick Up date";
        }
        if(selectfields["rentalTypeId"] === "-1"){
            formIsValid = false;
            errors["rentalTypeId"] = "Please Select a Car Type";
        }
        var msg = Utils.validMoneyNotZero(fields["rate"].replace('$',''),"Please enter a valid rate");
        if(msg !== ""){
            formIsValid = false;
            errors["rate"] = msg;
        }
        if(!validator.isNumeric(fields["numberOfDays"].toString()) || fields["numberOfDays"] === 0){
            formIsValid = false;
            errors["numberOfDays"] = "Please Enter # Days/Weeks";
        }
        /*
        if(fields["scDescription"] !== ""){
            msg = Utils.validMoney(fields["scAmount"],"Please Enter a Valid Special Charge");
            if(msg !== ""){
                formIsValid = false;
                errors["scAmount"] = msg;
            }
            else{
                if(parseFloat(fields["scAmount"]) === 0){
                    formIsValid = false;
                    errors["scAmount"] = "Please Enter a Special Charge";
                }
            }
        }
        msg = Utils.validMoney(fields["scAmount"],"Please Enter a Valid Special Charge");
        if(msg !== ""){
            formIsValid = false;
            errors["scAmount"] = msg;
        }
        else{
            if(parseFloat(fields["scAmount"]) !== 0 && fields["scDescription"] === ""){
                formIsValid = false;
                errors["scDescription"] = "Please Enter a Special Charge Explanation";
            }
        }*/
        if (!formIsValid){
            modal['modalSpinnerShow'] = false;
            this.setState({ modal });
        }
        this.setState({ errors });
        return formIsValid;
      }

    onSubmit(e:any){
        
        e.preventDefault();
        let action = e.currentTarget.id;
        let fields = this.state.fields;
        let modal = this.state.modal;
        modal["modalSpinnerShow"] = true;

        fields["action"] = action;       
        this.setState({ fields, modal })
        if(action === "copyResv"){
            this.userProcessCopy();
        }
        else if(action === "addResv"){
            this.cancelReview();
            modal["modalSpinnerShow"] = false;
            this.setState({ modal })
        }
        else{
            if(this.validateForm()){
                let fields = this.state.fields;
                fields["isReadOnly"] = false;
                var rate = parseFloat(fields["rate"].replace('$',''))
                var tax = parseFloat(fields["tax"].substring(1));
                var otherCosts = parseFloat(fields["otherCosts"].substring(1));
                fields["tax"] = rate * 0.2;
                if(fields["tax"] < rate * 0.2){
                    fields["tax"] = fields["tax"] + 1;
                }
                if(this.state.selectfields.rentalBillId === "ALL"){
                    fields["otherCosts"] = rate * 0.3;
                    if(fields["otherCosts"] < rate * 0.3){
                        fields["otherCosts"] = fields["otherCosts"] + 1;
                    }
                }
                else{
                    fields["otherCosts"] = 0;
                }
                fields["total"] = (parseFloat(fields["rate"].replace('$','')) + parseFloat(fields["tax"]) + parseFloat(fields["otherCosts"])) * fields["numberOfDays"];
                //console.log(fields["total"])
                this.setState({ fields });
                if(action === "saveResv"){
                    this.userProcessInsert();
                }
                else if(action === "saveCopyResv"  || action === "updateResv"){
                    this.userProcessUpdate();
                }
            }
        }
    }

    userProcessInsert(){
        let modal = this.state.modal;
        let rentalCarController = new RentalCarController();
            var insertData = {
                authReqId: this.state.fields.sessionTA,
                rentalBillId: this.state.selectfields.rentalBillId,
                rentalTypeId: this.state.selectfields.rentalTypeId,
                carCompany: this.state.fields.carCompany,
                city: this.state.fields.city,
                pickUpDate: format(this.state.pickUp, "MM/dd/yyyy"),
                returnDate:format(this.state.return, "MM/dd/yyyy"),
                rate: parseFloat(this.state.fields.rate.replace('$','')),
                tax: this.state.fields.tax,
                otherCosts: this.state.fields.otherCosts,
                total: this.state.fields.total,
                scAmount: (this.state.checkfields.addSplCharges)?this.state.fields.scAmount:0,
                scDescription: (this.state.checkfields.addSplCharges)?this.state.fields.scDescription:"",
                numberOfDays: this.state.fields.numberOfDays,
                rateType: this.state.selectfields.rateType    
            };
            //console.log("insert Data", insertData);
            rentalCarController.userProcessInsert(insertData).then(response => {
                //console.log("insert response", response);
                let fields = this.state.fields;
                fields["carRentId"] = response.carRentId;
                this.setState({ fields });
                this.bookingMap("");
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error...", error);
            })
    }

    userProcessCopy(){
        let modal = this.state.modal;
        const copyId = this.state.fields.carRentId;
        let rentalCarController = new RentalCarController();
        var copyData = {
            authReqId: this.state.fields.sessionTA,
            copyId: copyId,   
        };
        //console.log("copyResv data", copyData);
        rentalCarController.userProcessCopy(copyData).then(response => {
            //console.log("copy response", response);
            let fields = this.state.fields;
            fields["carRentId"] = response.carRentId;
            this.setState({ fields });
            this.bookingMap(copyId);
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
    }

    userProcessUpdate(){
        let modal = this.state.modal;
        let rentalCarController = new RentalCarController();
        var updateData = {
            rentalBillId: this.state.selectfields.rentalBillId,
            rentalTypeId: this.state.selectfields.rentalTypeId,
            carCompany: this.state.fields.carCompany,
            city: this.state.fields.city,
            pickUpDate: format(this.state.pickUp, "MM/dd/yyyy"),
            returnDate: format(this.state.return, "MM/dd/yyyy"),
            rate: parseFloat(this.state.fields.rate.replace('$','')),
            tax: this.state.fields.tax,
            otherCosts: this.state.fields.otherCosts,
            total: this.state.fields.total,
            scAmount: (this.state.checkfields.addSplCharges)?this.state.fields.scAmount:0,
            scDescription: (this.state.checkfields.addSplCharges)?this.state.fields.scDescription:"",
            numberOfDays: this.state.fields.numberOfDays,
            rateType: this.state.selectfields.rateType,
            carRentId: this.state.fields.carRentId   
        };
        //console.log("update Data", updateData);
        rentalCarController.userProcessUpdate(updateData).then(response => {
            //console.log("update response", response);
            this.bookingMap("");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
    }

    bookingMap(copyId){
        let modal = this.state.modal;
        var travellerIdStr = '';
        this.state.travellerId.map(prop => {
            if(travellerIdStr === ''){
                travellerIdStr = prop;
            }
            else{
                travellerIdStr = travellerIdStr + ',' + prop;
            }
        })
        //console.log("travellerIdStr", travellerIdStr);
        var bookingMapData = {
            travellerId: travellerIdStr,
            carRentId: this.state.fields.carRentId,
            copyId: copyId
        }
        //console.log("Booking map request", bookingMapData);
        let rentalCarController = new RentalCarController();
        rentalCarController.bookingMap(bookingMapData).then(response => {
            //console.log("Booking map response", response);
            if(copyId === ''){
                this.state.travellerId.map((prop,key) => {
                    var i = this.state.fields.traveller.indexOf(parseInt(prop));
                    var updateData = {
                        travellerId: prop,
                        carRentId: this.state.fields.carRentId,
                        specialIns: this.state.fields.comment[i]
                    }
                    //console.log("updatedata", updateData)
                    rentalCarController.updateSpecialIns(updateData).then(response => {
                        //console.log("updateSpecialIns", response)
                        if(this.state.travellerId.length -1 === key){
                            if(this.state.fields.action === "saveCopyResv"){
                                let fields = this.state.fields;
                                fields["action"] = ''
                                this.setState({fields});
                                this.userProcessCopy();
                            }
                            else{
                                //console.log("Rental car Booking Id: ", this.state.fields.carRentId);
                                //alert("Rental Car Booking completed successfully...")
                                this.cancelReview();
                                this.pageLoad();
                                modal["modalSpinnerShow"] = false;
                                this.setState({modal});
                            }
                        }
                    }).catch(error => {
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});
                        console.error("There was an error...", error);
                    })
                })
            }
            else
            {
                this.cancelReview();
                this.pageLoad();
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
            }
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
    }

    loadReview(carRentId){
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        this.clearError();
        let fields = this.state.fields;
        fields["review"] = true;
        fields["carRentId"] = carRentId;
        fields["action"] = "review";
        this.setState({fields, modal});
        this.getBooking(carRentId, true);
        this.getTATraveler(carRentId, true);
    }

    cancelReview(){
        this.clearError();
        let fields = this.state.fields;
        fields["review"] = false;
        fields["isReadOnly"] = false;
        this.setState({fields});
        this.clearBookingEntryfields();
        this.setState({TATravelerTable: this.state.TATravelerList, travellerId:[]})
        this.state.TATravelerList.map((prop, key) => {
            let fields = this.state.fields;
            fields["traveller"][key] = prop['travelerId'];
            fields["resv"][key] = prop["RLN"];
            fields["comment"][key] = prop["specialInstructions"];
            this.setState({fields});
        })
    }

    async deleteBooking(carRentId){
        var flag = await confirm("Do you want to continue?",{title:"Confirm Delete", okText:"Yes", cancelText:"No"})
        //console.log("delete flag", flag);
        if(flag){
            let commonController = new CommonController();
            commonController.deleteAuthReq("","","",carRentId,"").then(response => {
                //console.log("deleteBooking", response)
                //console.log("Delete Action Id: ", carRentId)
                //alert("Delete action completed...")
                this.pageLoad();
            }).catch(error => {
                console.error("There was an error...", error);
            })
        }
    }

    loadTravelerReservation(){
        return <div id="divReservation" style={{width: '100%'}}>
            <Row>
                <Col sm={12}> 
                    {this.state.carRentId.map((prop, key) => {
                        var count = 0;
                        return this.state.fields.carRentId !== prop["carRentId"] ?(
                            <table style={{width:"100%"}}>
                                <tbody>
                                    {this.loadTravelerReservationHeader()}
                                    {this.state.Booking.map((el, i) => {
                                        var name = '';
                                        this.state.traveller.map((item,j) => {
                                            if(el["carRentId"] === item["id"]){
                                                if(name === ''){
                                                    name = item["name"];
                                                }
                                                else{
                                                    name = name +" / "+ item["name"];
                                                }
                                            }
                                        })
                                        if(prop["carRentId"] === el["carRentId"]){
                                            count = count + 1;
                                        }
                                        return prop["carRentId"] === el["carRentId"] && count <= 1 ? this.loadTravelerReservationBody(el,i,name) : <tr></tr>
                                    })}
                                </tbody>
                            </table>
                        )
                        : <></>         
                    })}  
                </Col>
            </Row>
        </div>
    }

    loadTravelerReservationHeader(){
        return <tr style={{width:"100%", borderTop:"1px solid navy"}}>
            <td style={{width:"15%"}}><b>Billing Type</b></td>
            <td style={{width:"10%"}}><b>Car Company</b></td>
            <td style={{width:"10%"}}><b>City</b></td>
            <td style={{width:"10%"}}><b>Pick Up</b></td>
            <td style={{width:"10%"}}><b>Return</b></td>
            <td style={{width:"10%"}}><b>Traveler</b></td>
            <td style={{width:"10%"}}><b>Resv Type</b></td>
            <td style={{width:"10%"}}><b>Resv #</b></td>
            <td style={{width:"15%"}}></td>
        </tr>
    }

    loadTravelerReservationBody(prop, key, name){
        return <tr>
            <td>{prop["rentalBillDesc"]}</td>
            <td>{prop["carCompany"]}</td>
            <td>{prop["city"]}</td>
            <td>{Utils.FormatDate(prop["pickupDate"])}</td>
            <td>{Utils.FormatDate(prop["returnDate"])}</td>          
            <td>{name}</td>
            <td>{!prop["BCDGenerated"] ?"Manual":"CTM"}</td>
            <td>{prop["RLN"]}</td>
            <td style={{textAlign:"right"}}>
                <Button type="button" variant="danger" className="btn btn-danger btn-sm" value="Delete" title="Click to delete this reservation" id="buttonDelete" name="buttonDelete" onClick={(e) => this.deleteBooking(prop["carRentId"])}>Delete</Button> &nbsp;
                <Button type="button" variant="secondary" className="btn btn-secondary btn-sm" style={{marginLeft:"2px"}} value="Review" title="Click to edit this reservation" id="buttonReview" name="buttonReview" onClick={(e) => this.loadReview(prop["carRentId"])}>Review</Button>
            </td>
        </tr>
    }

    displayTravelerMsg(){
        let fields=this.state.fields;
        if (this.state.TATravelerList.length === 0){ fields["iTravelerCnt"] = -1; }
        
        if (fields["iTravelerCnt"] > 0 || fields["iTravelerCnt"] <= 0){
            if(this.state.fields.iTravelerCnt === -1){
                 return <div id="divTravelerMsg" className="text-dark font-size-xs small">Click on the Traveler tab to add a traveler.</div>
            }
            else
            {
                return <div id="divTravelerMsg" style={{color:"red",textAlign:"center"}} className="text-dark font-size-xs small">Click on a traveler to add to reservation and enter airfare amount.</div>
            }
        }
        else
        {
            return <></>
        }
    }

    buttonControl(){
        const { loading } = this.state;
        if(this.state.fields.carRentId !== 0 && this.state.fields.review && !this.state.fields.isReadOnly){
            return <Row>
                <Col sm={3}></Col>
                <Col sm={6}>
                    <Button id="updateResv" style={{ marginRight: "5px"}} type="submit" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Save Resv</span>}
                    </Button>
                    <Button id="addResv" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Add Resv</span>}
                    </Button>
                    <Button id="copyResv" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Copy Resv</span>}
                    </Button>
                    <Button id="saveCopyResv" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Save & Copy Resv</span>}
                    </Button>
                    <Button id="deleteResv" type="button" size="sm" disabled={loading} style={{ display:"none",marginRight: "5px" }} onClick={(e) => this.deleteBooking(this.state.fields.carRentId)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa", display:"none"}}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Delete Resv</span>}
                    </Button>
                    <Button id="cancelReview" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.cancelReview()}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Cancel Review</span>}
                    </Button>
                </Col>
                <Col sm={3}></Col>
            </Row>
        }
        else if(this.state.fields.hotelId !== 0 && this.state.fields.review && this.state.fields.isReadOnly){
            return <Row>
                <Col sm={3}></Col>
                <Col sm={6}>
                    <Button id="updateResv" style={{ marginRight: "5px"}} type="submit" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" , color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{marginRight: "5px", color:"#487afa"}} >wait...</span>}
                        {!loading && <span style={{marginRight: "5px"}}>Save Resv</span>}
                    </Button>
                    <Button id="cancelReview" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.cancelReview()}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Cancel Review</span>}
                    </Button>
                </Col>
                    <Col sm={3}></Col>
                </Row>
        }
        else{
            return <Row>
                <Col sm={5}></Col>
                <Col sm={2}>
                    <Button id="saveResv" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Save Resv</span>}
                    </Button>
                </Col>
                <Col sm={5}></Col>
            </Row>
        } 
    }

    tableSpecialCharge(text){
	    return <Row id="splCharges" style={{display:"none"}}>
            <Col sm={12}>         
            <table style={{border:"1px",padding:"0px"}}>
                <tr>
                    <td>
                        <table style={{border:"1px",padding:"0px",borderSpacing:"2px"}}>
                            <tr>
                                <td colSpan={2} style={{textAlign:"center",fontSize: "12px"}}>FOR SPECIAL CHARGES ONLY</td>
                            </tr>
                            <tr>
                                <td style={{width:"85%",fontSize: "12px"}}>Description {text}</td>
                                <td style={{width:"15%",alignSelf:"right",fontSize: "12px"}}>Amount</td>
                            </tr>
                            <tr>
                                <td style={{alignSelf:"top"}}>
                                    <FormControl style={{height: 'auto'}} size="sm" as="textarea" id="scDescription" name="scDescription" className="form-control input-sm" value={this.state.fields.scDescription} onChange={(e) => this.handleChange(e)} ></FormControl>
                                    {this.state.errors.scDescription !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.scDescription}</div> : <></> }
                                </td>
                                <td style={{alignSelf:"top",textAlign:"right"}}>
                                    <FormControl style={{height: 'auto',textAlign:"right"}} maxLength={13} size="sm" type="text" id="scAmount" name="scAmount" className="form-control input-sm" value={this.state.fields.scAmount} onChange={(e) => this.handleChange(e)} ></FormControl>
                                    {this.state.errors.scAmount !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.scAmount}</div> : <></> }
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            </Col>
        </Row>
    }

    formTraveller(prop, key){ 
        return <tr id={"div"+prop['travelerId']} style={{ display: !prop['bit'] ? "none" : ""}}>
            <td style={{padding: '0px',width:'250px'}}><FormLabel style={{height: 'auto'}}>{prop['name']}</FormLabel></td>
            <td style={{padding: '0px',width:'150px'}}><FormLabel style={{height: 'auto'}}>{prop['RLN']}</FormLabel></td>
            <td style={{padding: '0px',width:'200px'}}><FormControl style={{height: 'auto'}} size="sm" type="text" id="comment" name="comment" className="form-control input-sm" value={this.state.fields.comment[key]} onChange={(e) => this.handleFormChange(key, e)}></FormControl></td>
        </tr>       
    }

    formControl = () => {
        return<div style={{borderBottom:"1px solid #E3E3E3"}}>
            <Row>
                <Col sm={2}>
                    <Form.Group>
                        <Form.Label>Billing Type<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                        <Form.Control as="select" id="rentalBillId" name="rentalBillId" style={{backgroundColor: (this.state.fields.isReadOnly) ? "yellow" : "#ffffff"}} value={this.state.selectfields.rentalBillId} className="form-control form-control-sm" onChange={this.handleSelectChange}>  
                            <option value={-1}>{"Select Billing"}</option>
                            {this.state.BillingType.map((prop, key) => {
                                if(prop["id"] !== "-1"){
                                    return(
                                        <option selected={true} value={prop["id"]}>{prop["name"]}</option>
                                    )
                                }
                            })}
                        </Form.Control>
                        {this.state.errors.rentalBillId !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.rentalBillId}</div> : <></> }
                    </Form.Group>
                </Col>
                <Col sm={2}>
                    <FormInputs
                        properties={{id:"carCompany",label:"Car Company",type:"text",name:"carCompany",className:"form-control input-sm form-control-sm",maxlength:50,value:this.state.fields.carCompany,onChange:this.handleChange,disabled:(this.state.fields.isReadOnly)?true:false}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.carCompany}
                    />
                </Col>
                <Col sm={2}> 
                    <FormInputs
                        properties={{id:"city",label:"City",type:"text",name:"city",className:"form-control input-sm form-control-sm",maxlength:50,value:this.state.fields.city,onChange:this.handleChange}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.city}
                    />
                </Col>
                <Col sm={2}>
                    <Form.Label>Pick Up(MM/DD/YYYY)<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label> 
                    <Form.Group>             
                        <DatePicker style={{fontSize:"12px"}} 
                        Id="pickUp"
                        selected={this.state.pickUp}
                        onChange={this.handleChangePickUpDate}
                        isClearable
                        showMonthDropdown
                        showYearDropdown                            
                        className="datePicker form-control form-control-sm"
                        disabled={(this.state.fields.isReadOnly)?true:false}/>
                    </Form.Group>
                    {this.state.errors.pickUp !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.pickUp}</div> : <></> } 
                </Col>
                <Col sm={2}>
                    <Form.Label>Return(MM/DD/YYYY)<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                    <Form.Group>                         
                        <DatePicker style={{fontSize:"12px"}} 
                        Id="return"
                        selected={this.state.return}
                        onChange={this.handleChangeReturnDate}
                        isClearable
                        showMonthDropdown
                        showYearDropdown                            
                        className="datePicker form-control form-control-sm"
                        disabled={(this.state.fields.isReadOnly)?true:false}/> 
                    </Form.Group>
                    {this.state.errors.return !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.return}</div> : <></> } 
                </Col>
                <Col sm={2}></Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <Form.Group>
                        <Form.Label>Car Type<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                        <Form.Control as="select" id="rentalTypeId" name="rentalTypeId" style={{backgroundColor: (this.state.fields.isReadOnly) ? "yellow" : "#ffffff"}} value={this.state.selectfields.rentalTypeId} className="form-control form-control-sm" onChange={this.handleSelectChange}>
                            {this.state.RentalType.map((prop, key) => {
                                return(
                                    <option selected={true} value={prop["id"]}>{prop["name"]}</option>
                                )
                            })}
                        </Form.Control>
                        {this.state.errors.rentalTypeId !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.rentalTypeId}</div> : <></> }
                    </Form.Group>
                </Col>
                <Col sm={1}>
                    <FormInputs
                        properties={{id:"rate",label:"Rate:",type:"text",name:"rate",className:"form-control input-sm form-control-sm",maxlength:13,value:this.state.fields.rate,onChange:this.handleChange,style:{"backgroundColor": this.state.fields.isReadOnly ? "yellow" : "#ffffff"}}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.rate}
                    />
                </Col>
                <Col sm={2}>
                    <Form.Group>
                    <Form.Label>Rate Type</Form.Label>                                                       
                        <div><Form.Control as="select" name="rateType" className="form-control form-control-sm" style={{backgroundColor: (this.state.fields.isReadOnly) ? "yellow" : "#ffffff"}} value={this.state.selectfields.rateType} onChange={this.handleSelectChange}>
                            <option value="Days" selected>Days</option> 
                            <option value="Weeks">Weeks</option> 
                        </Form.Control></div>
                    </Form.Group>
                </Col>
                <Col sm={1}>
                    <FormInputs
                        properties={{id:"numberOfDays",label:"#Days/Weeks:",type:"text",name:"numberOfDays",className:"form-control input-sm form-control-sm",maxlength:3,value:this.state.fields.numberOfDays,onChange:this.handleChange,style:{"backgroundColor": this.state.fields.isReadOnly ? "yellow" : "#ffffff"}}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.numberOfDays}
                    />
                </Col>
                <Col sm={2}>
                    <FormLabel>Tax (20%)</FormLabel>
                    <div className="text-dark font-size-xs small">{this.state.fields.tax}</div>
                </Col>
                <Col sm={2}>
                    <FormLabel>Other Costs (30%)</FormLabel>
                    <div className="text-dark font-size-xs small">{this.state.fields.otherCosts}</div>
                </Col>
                <Col sm={2}>
                    <FormLabel>Per Car Total</FormLabel>
                    <div className="text-dark font-size-xs small">{this.state.fields.total}</div>
                </Col>
                <Col sm={2}></Col>
            </Row>
        </div>
    }

    displayRentalCar = () => {     
        return <div>
                {(!this.state.fields.isReadOnly)?<>
                <Row>
                  <Col sm={12} style={{textAlign:"right"}}>
                    <Button id="print" type="button" size="sm" onClick={() => window.print()} >
                        Print
                    </Button>
                  </Col>
                </Row>
                <Row>
                    <Col sm={3}>
                        <div style={{fontSize:'7pt',fontWeight:'bold'}}><b>MANUAL RESERVATIONS ONLY</b><span className="text-danger" style={{fontSize:'16px'}}>*</span><br/>Select Traveler</div>
                        <Form.Group>
                            <Form.Control as="select" multiple={true} id="travellerId" name="travellerId" value={this.state.travellerId} className="form-control form-control-sm" onChange={this.handleSelectMultipleChange}>
                                {this.state.TATravelerList.map((prop, key) => {
                                    return(
                                        <option selected={true} value={prop["travelerId"]}>{prop["name"] + ' ' + prop["RLN"]}</option>
                                    )
                                })}
                            </Form.Control>
                            <div style={{fontSize:'7pt',fontWeight:'bold'}} >(Ctrl to select multiple)</div>
                            {this.state.errors["travellerId"] !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors["travellerId"]}</div> : <></> }
                        </Form.Group>
                    </Col>
                    <Col sm={9}></Col>      
                </Row>  
                <div style={{borderBottom: "1px solid rgb(227, 227, 227)",marginBottom:"15px"}}></div></>
                :<></>}
                {this.formControl()}
                <Row>
                    <Col sm={12}>
                        <div style={{color: "#FF4A55",marginTop:"15px"}}>
                            <FormCheckBox
                                properties={{label:"Add Special Charges",id:"addSplCharges",name:"addSplCharges",type:"checkbox",className:"form-control-md",onChange:this.handleCheckChange,checked:this.state.checkfields.addSplCharges}}
                            />
                        </div>
                    </Col>             
                </Row>
                <Row>
                    <Col sm={9}>
                        <div style={{width: '100%'}}>
                            <Table id="travelerTable" hover>                    
                                <thead style={{backgroundColor:'#00B0F0', fontSize: 'large', height:'11px'}}>
                                <tr>
                                    <th>Traveler</th>
                                    <th>Resv #</th>
                                    <th>Comment</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.TATravelerTable.map((prop, key) =>
                                    this.formTraveller(prop, key)
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col sm={3}>
                        {this.tableSpecialCharge("(Greeter services, etc.)")}
                    </Col>
                </Row>
                {this.buttonControl()}
                {/* commented as iTravellerCount not needed 
                {this.displayTravelerMsg()}*/}
                <div id="divClicktoReview" style={{marginTop:"15px"}}>
                    {(this.state.Booking.length > 0) ? 
                        <Row>
                            <Col sm={12}>
                                <div className="text-yellow font-size-xs small" style={{backgroundColor:"navy",color: "yellow",textAlign:"center"}}>Click the Review button to complete each traveler's reservation</div>
                            </Col>
                        </Row> : ""}
                </div>
                {this.loadTravelerReservation()}
                <div id="divReviewComplete" style={{borderTop:(this.state.Booking.length > 0) ?"1px solid navy": "0px"}}>
                    {(this.state.Booking.length > 0) ? 
                        <Row>
                            <Col sm={12}>
                                <div className="text-danger font-size-xs small" style={{textAlign:"center",fontStyle:"bold"}}>REVIEW/COMPLETED TRAVEL SECTION</div>
                            </Col>
                        </Row> : ""}
                </div>
            </div>
    }

    codingTab(){
        if(sessionStorage.getItem("page") === "submitter" && parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && (parseInt(sessionStorage.getItem("ta_tier_code")!) !== 0 || sessionStorage.getItem("ta_is_corporate_card") === "true")){
            return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for summary page" label="Print"></Tab>
        }
        if(sessionStorage.getItem("page") === "approver" || sessionStorage.getItem("page") === "submitter"){
            if(parseInt(sessionStorage.getItem("ta_status_id")!) !== 1 || (parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && parseInt(sessionStorage.getItem("ta_tier_code")!) === 0 || sessionStorage.getItem("ta_is_corporate_card") === "false")){
                return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for coding page" label="Coding"></Tab>
            }    
        }     
    }
    decisionTabTAHistoryResubmit(){
        
        if(sessionStorage.getItem("ta_status_desc") === "Re-Submit"){
           return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision"></Tab>           
        }
        else
        {
           return <Tab headerClass="nav-link disabled" style={{color:"white"}} disabled></Tab>
        }
    }
    redirectToPage(tabLabel){
        switch (tabLabel) {
            case 'Traveler':
                this.props.history.push('/admin/traveller');
                break;
            case 'Air / Rail':
                this.props.history.push('/admin/airrail');
                break;
            case 'Hotel':
                this.props.history.push('/admin/hotel');
                break;
            case 'Rental Car':
                this.props.history.push('/admin/rentalcar');
                break;
            case 'Car Service':
                this.props.history.push('/admin/carservice');
                break;
            case 'Print':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:""}});
                break;
            case 'Coding':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:""}});
                break;
            case 'Decision':
                this.props.history.push({pathname:'/admin/decision',state:{from:"tahistory"}});
                break;
        }
    }

    render() {
        const{ loading } = this.state;
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <Card.Body>
                            <Form autoComplete='off'>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <AuthStatus/>
                                <section style={{marginTop:"10px"}}>
                                    <div style={{textAlign:"right"}}>
                                        <SubmitButton 
                                            loading={this.state.loading} 
                                            redirect={this.props.history}
                                        />
                                    </div>
                                    <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={3} mountOnExit>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for traveler page" label="Traveler"></Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for air/rail page" label="Air / Rail"></Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for hotel page" label="Hotel"></Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for rental car page" label="Rental Car">{this.displayRentalCar()}</Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for car service page" label="Car Service"></Tab>
                                        {this.codingTab()}
                                        {this.decisionTabTAHistoryResubmit()}
                                    </Tabs>
                                </section>
                            </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}