import React, { Component, useState } from "react";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,FormControl,Table,InputGroup,FormGroup} from "../../../../node_modules/react-bootstrap";
import {Tabs, Tab} from 'react-bootstrap-tabs';
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import '../../../assets/css/Style.css'
import {CommonController} from "../../../Controllers/CommonController";
import { FormInputs } from "../../CustomComponents/FormInput";
import { AuthStatus } from "../Common/AuthStatus"
import * as Utils from '../../../js/Utils.js';
import "react-datepicker/dist/react-datepicker.css";
import { ICarServiceParameter, Istate } from "../../../Model/ICarService";
import { CarServiceController } from "../../../Controllers/CarServiceController";
import DatePicker from 'react-datepicker';
import validator from 'validator';
import dateFormat from 'dateformat'
import { alert, confirm } from 'react-bootstrap-confirmation';
import { SubmitButton } from "./SubmitButton";
import {FormCheckBox} from '../../CustomComponents/FormCheckBox';
import { CodingSummary } from "./CodingSummary";
import { format } from "date-fns";
export class CarService extends Component<ICarServiceParameter,Istate> {
    constructor(props:ICarServiceParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: {sessionTA: sessionStorage.getItem('ta'), carServiceId: 0, pickUpTime: '', AM_PM: 'AM', isPhoneIncluded: false, isRateHourly : true, rate: this.formatCurrency(0), estimatedTotal:this.formatCurrency(0), passengerCount:0, scAmount: 0, scDescription: '', bcdGenerated: false, bcdValidated: false, RLN: '', traveller: [], resv: [], comment: [], action: '', review: false},
            checkfields: {addSplCharges:false},
            selectfields: {serviceTypeId: '-1', serviceTypeDesc: '', carCompanyId: '-1', carCompanyDesc: '', carTypeId:'-1', carTypeDesc: ''},
            errors: {travellerId:'', serviceTypeId:'', carCompanyId:'', carTypeId:'', pickUpDate:'', pickUpTime:'', rate:'', estimatedTotal:'', passengerCount: '', scAmount:'', scDescription:'', pickUpLocation:'', dropOffLocation:''},
            loading:false,
            errMsg:"",
            modal: {modalSpinnerShow: false},
            ServiceType:[],
            ServiceCompany:[],
            Type:[],
            TATravelerList:[],
            TATravelerTable:[],
            TATravelerName:[],
            travellerId:[],
            carServiceId:[],
            Booking:[],
            traveller:[],
            pickUpDate:new Date(new Date().toISOString()),
            values: [{carServiceLocationId:0,pickUpLocation1:'',dropOffLocation1:'',pickUpLocation2:'',dropOffLocation2:''}]
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleTimeFormat = this.handleTimeFormat.bind(this);
        this.handleChangePickUpDate = this.handleChangePickUpDate.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);   
        this.handleSelectMultipleChange = this.handleSelectMultipleChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }

    componentWillMount(){      
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        let selectfields = this.state.selectfields;
        if(sessionStorage.getItem("ta_car_service_only") === "true"){
            selectfields["carCompanyId"] = sessionStorage.getItem("ta_car_service_company_id");
            selectfields["carCompanyDesc"] = sessionStorage.getItem("ta_car_service_company");
            selectfields["carServiceCompanyName"] = "Select Car Service Company";
        }
        //console.log(this.state.selectfields.carCompanyDesc)
        this.setState({ modal,selectfields});
        let carServiceController = new CarServiceController();
        carServiceController.getServiceType().then(jsonServiceType => {
            this.setState({                           
                ServiceType : JSON.parse(JSON.stringify(jsonServiceType))
            });
            //console.log("ServiceType:", this.state.ServiceType)
        }).catch(error => {
            console.error("There was an error", error);
        })
        carServiceController.getCarCompany().then(jsonCarCompany => {
            this.setState({                           
                ServiceCompany : JSON.parse(JSON.stringify(jsonCarCompany))
            });
            //console.log("CarCompany:", this.state.ServiceCompany)
        }).catch(error => {
            console.error("There was an error", error);
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        })
        carServiceController.getCarType().then(jsonCarType => {
            this.setState({                           
                Type : JSON.parse(JSON.stringify(jsonCarType))
            });
            //console.log("CarType:", this.state.Type)
        }).catch(error => {
            console.error("There was an error", error);
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        })

        var data={
            id:'',
            view:'car_service',
            bInner:'false',
            sessionTA:this.state.fields.sessionTA
        }
        let commonController = new CommonController();
        commonController.getTATraveler(data).then(jsonTravellers => {
            this.setState({                           
                TATravelerList : JSON.parse(JSON.stringify(jsonTravellers)),
                TATravelerTable : JSON.parse(JSON.stringify(jsonTravellers))
            });
            this.state.TATravelerTable.map((prop, key) => {
                let fields = this.state.fields;
                fields["traveller"][key] = prop['travelerId'];
                fields["resv"][key] = prop["RLN"];
                fields["comment"][key] = prop["specialInstructions"];
                this.setState({fields});
            })
            //console.log("TATravelerList, ", this.state.TATravelerList)
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error loading Traveler..., " + error)
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
		});
        this.pageLoad();
    }

    pageLoad(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        this.setState({ Booking:[], traveller:[]})
        let carServiceController = new CarServiceController();
        let commonController = new CommonController();
        carServiceController.getCarServiceBookingCarServiceId(this.state.fields.carServiceId, this.state.fields.sessionTA).then(jsonCarServiceId => {
            ;
            this.setState({                           
                carServiceId: JSON.parse(JSON.stringify(jsonCarServiceId))
            });
            //console.log("CarServiceId", this.state.carServiceId)
            this.state.carServiceId.map((prop, key) => {
                var data = {
                    "authreqId": this.state.fields.sessionTA,
                    "carServiceId": prop['carServiceId']
                };
                carServiceController.getBooking(data).then(jsonBooking => {
                    this.setState({                           
                        Booking : [...this.state.Booking, ...(JSON.parse(JSON.stringify(jsonBooking)))] 
                    });
                    //console.log("Booking", this.state.Booking)
                    var data1 = {
                        id: prop['carServiceId'],
                        view: "car_service",
                        bInner: true,
                        sessionTA: this.state.fields.sessionTA
                    }
                    commonController.getTATraveler(data1).then(jsonTravellers => {
                        this.setState({                           
                            traveller : [...this.state.traveller, ...(JSON.parse(JSON.stringify(jsonTravellers)))]
                        });
                        //console.log("traveller", this.state.traveller)
                    }).catch(error => {
                        console.error("There was an error loading traveller..., " + error)
                    });
                }).catch(error => {
                    console.error("There was an error loading traveller..., " + error)
                });
            })
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error loading Traveler..., " + error)
        });
    }

    handleChange(e:any){
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        if (e.currentTarget.name === 'name'){
            selectfields["carCompanyDesc"] = e.currentTarget.value;
        }
        if (e.currentTarget.name === 'scAmount'){
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        else{
            if (e.currentTarget.value.trim().length > 0){
                fields[e.currentTarget.name] = e.currentTarget.value;
            }
            else{
                fields[e.currentTarget.name] = e.currentTarget.value.trim();
            }
        }
        this.setState({ fields, selectfields });
    }

    handleTimeFormat(e){
        if(!(this.state.fields.pickUpTime.indexOf(":") > 0) && this.state.fields.pickUpTime !== ''){
            let fields = this.state.fields;
            fields["pickUpTime"] = fields["pickUpTime"] + ":00";
            this.setState({ fields });
        }
    }
    handleCheckChange=(e:any)=>{
        ;
        let checkfields = this.state.checkfields;
        let fields = this.state.fields;
        if (e.currentTarget.name === "addSplCharges") {
            checkfields[e.currentTarget.name] = !this.state.checkfields.addSplCharges
        }
        const el:HTMLElement = document.getElementById('splCharges') as HTMLElement;
        if(checkfields["addSplCharges"]){
            el.style.display = "block";
        }
        else
        {
            fields["scDesc"] = "";
            fields["scAmount"] = 0;
            el.style.display = "none";
        }
        this.setState({
            checkfields, fields
        });
    }
    handleChangeEvent(e, index) {
        ;
        const { name, value } = e.target;
        const values = [...this.state.values];
        values[index][name] = value;
        this.setState({ values });
        //console.log("handleChangeEvent:", this.state.values)
    }

    handleFormChange = (i,e:any) => {
        let fields = this.state.fields;
        if (e.currentTarget.value.trim().length > 0)
        {
            fields[e.currentTarget.name][i] = e.currentTarget.value;
        }
        else
        {
            fields[e.currentTarget.name][i] = e.currentTarget.value.trim();
        }
        this.setState({ fields });
    }

    handleChangePickUpDate = (date) => {
        // ;
        // //console.log("handleChangePickUpDate: ", date);
        // var today = new Date();
        // //console.log("localdatestring: ", date.toLocaleDateString().slice(0, 10));
        this.setState({
          pickUpDate: date,
        });
        if(date!=null){

            const today = new Date(); // Current date
            const selectedDate = new Date(date); // Convert the selected date to a Date object
    
            // Standardize to UTC for date-only comparison (ignores time)
            const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
            const selectedDateUTC = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));
    
            
        // if (format(today, "MM/dd/yyyy") > format(date, "MM/dd/yyyy")) {
        //   let errors = this.state.errors;
        //   errors["pickUpDate"] = "The date you have entered has already past";
        // }
        
        if (todayUTC > selectedDateUTC) {
            let errors = this.state.errors;
            errors["pickUpDate"] = "The date you have entered has already past"; 
        }
        
        
        
        else {
          this.clearError();
        }}
      };

    handleSelectChange(e:any) {
        let selectfields = this.state.selectfields;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        if(e.currentTarget.name === 'serviceTypeId'){
            selectfields["serviceTypeDesc"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
            if(e.currentTarget.options[e.currentTarget.selectedIndex].value === "ASD"){
                alert('By selecting "As Directed" all bills will be processed regardless of the dollar amount on the TA. Please estimate at least 8 hours of usage and budget accordingly.',{title: "eTA - Car Service"})
            }
        }
        else if(e.currentTarget.name === 'carCompanyId'){
            if(e.currentTarget.options[e.currentTarget.selectedIndex].value !== "o~t~h~e~r"){
                selectfields["carCompanyDesc"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
            }
        }
        else if(e.currentTarget.name === 'carTypeId'){
            selectfields["carTypeDesc"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        }
        this.setState({ selectfields });
    }

    handleSelectMultipleChange(e:any){
        var options = e.currentTarget.options;
        var value : string[] = [];
        var element:HTMLElement | null;
        
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                element=document.getElementById("div"+options[i].value) as HTMLElement
                if (element !== null){
                    element.style.display = "";
                }
                value.push(options[i].value);
            }
            else{
                element=document.getElementById("div"+options[i].value) as HTMLElement
                if (element !== null){
                    element.style.display = "none";
                }
            }
        }
        if(e.currentTarget.name === 'travellerId'){
            this.setState({travellerId: value});
        }
    }

    handleOptionChange(e:any) {
        ;
        const fields = this.state.fields;
        let currentTargetId = e.currentTarget.id;
        if (currentTargetId === "bAM") {
            fields['AM_PM'] = 'AM';
        }
        else if (currentTargetId === "bPM"){
            fields['AM_PM'] = 'PM';
        }
        else if (currentTargetId === "bHour"){
            fields['isRateHourly'] = true;
        }
        else if (currentTargetId === "bFlat"){
            fields['isRateHourly'] = false;
        }
        else if (currentTargetId === "bYes") {
            //fields['isPhoneIncluded'] = true;
        }
        else if (currentTargetId === "bNo"){
            //fields['isPhoneIncluded'] = false;
        }
        this.setState({ fields });
        //console.log("handleOptionChange:", this.state.values)
    }

    formatCurrency(amount){
        var floatamount = parseFloat(amount);
        return floatamount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    showRates()
    {
	    window.open("http://umg-hr.umusic.com/files/travel/dav_eimusic_express_rate_comparison.xls", "Rates", "toolbar=no,menubar=no,location=no,titlebar=no");
    }
    
    addLocation(){
        this.setState(prevState => ({ values: [...prevState.values, { carServiceLocationId:'',pickUpLocation1:'',dropOffLocation1:'',pickUpLocation2:'',dropOffLocation2:'' }]}))
        //console.log("addLocation:", this.state.values)
    }

    deleteLocation(i){
        ;
        const values = [...this.state.values];
        values.splice(i,1);
        this.setState({ values });
        //console.log("deleteLocation:", this.state.values)
    }

    getBooking(carServiceId, editMode){
        let modal = this.state.modal;
        let carServiceController = new CarServiceController();
        var data = {
            "authreqId": this.state.fields.sessionTA,
            "carServiceId": carServiceId
        };
        carServiceController.getBooking(data).then(jsonBooking => {
            //console.log("Review :", jsonBooking);
            this.fillBookingDetails(jsonBooking, editMode);
        }).catch(error => {
            modal['modalSpinnerShow'] = false;
            this.setState(modal);
            console.error("There was an error loading booking details..., " + error)
        });
    }

    getTATraveler(carServiceId, bInner){
        let modal = this.state.modal;
        var data={
            id: carServiceId,
            view:'car_service',
            bInner: bInner,
            sessionTA: this.state.fields.sessionTA
        }
        let commonController = new CommonController();
        commonController.getTATraveler(data).then(jsonTravellers => {
            //console.log("JsonTravellers", jsonTravellers);
            var travellerId: any= [];
            jsonTravellers.map(prop => {
                travellerId.push(prop["travelerId"]);
            })
            this.setState({travellerId : travellerId, TATravelerTable : JSON.parse(JSON.stringify(jsonTravellers))})
            /*var tempTraveller : Array<{"id": string; "travellerId": string; "isDH": string; "address": string; "city": string; "stateProvinceId": string; "zip": string; "phone": string; "ssn": string; "type": string; "issuer": string; "callLetters": string; "travelPurpose": string; "fedId": string; "radioLetterReceived": string; "overrideReceived": string; "preapprovalReceived": string; "firstName": string; "lastName": string; "name": string; "formOnFile": string; "travelRestrictions": string; "RLN": string; "airfareAmount": string; "feeAmount": string; "totalAmount": string; "bit": boolean; "specialInstructions": string}> = [];
            jsonTravellers.map((item,i) => {
                var count = 0;
                this.state.TATravelerTable.map((prop,key) => {
                    if(item["travelerId"] != prop["travelerId"]){
                        count = count + 1;
                    }
                    if(key + 1 === this.state.TATravelerTable.length){
                        if(count >= 1){
                            tempTraveller.push(prop);
                        }
                    }
                })
            })
            //console.log("tempTraveller", tempTraveller)
            tempTraveller.map(prop => jsonTravellers.push(prop));
            this.setState({                           
                TATravelerTable : jsonTravellers
            });*/
            this.state.TATravelerList.map((item,i) => {
                var filterTraveller = jsonTravellers.filter(f=>f.travelerId == item.travelerId).length;
                ;
                //console.log("****************-outside Length:"+filterTraveller, i)
                if(filterTraveller === 0){
                    //console.log("****************-inside")
                    //console.log("****************-inside TravellerID:", item.travelerId)
                    this.state.TATravelerTable.push(item);
                }
            });
            this.state.TATravelerTable.map((prop, key) => {
                let fields = this.state.fields;
                fields["traveller"][key] = prop['travelerId'];
                fields["resv"][key] = prop["RLN"];
                fields["comment"][key] = prop["specialInstructions"];
                this.setState({fields});
            })
            modal['modalSpinnerShow'] = false;
            this.setState(modal);
        }).catch(error => {
            modal['modalSpinnerShow'] = false;
            this.setState(modal);
            console.error("There was an error loading Traveler..., " + error)
        });
    }
    
    getCarServiceLocation(carServiceId){
        let modal = this.state.modal;
        let carServiceController = new CarServiceController();
        carServiceController.getCarServiceLocation(carServiceId).then(jsonCarServiceLocation => {
            if(jsonCarServiceLocation.length > 0){
                this.setState(prevState => ({ values: JSON.parse(JSON.stringify(jsonCarServiceLocation))}))
            }
            modal['modalSpinnerShow'] = false;
            this.setState(modal);
            //console.log("carServiceId",this.state.carServiceId);
            //console.log("getCarServiceLocation",this.state.values);
        }).catch(error => {
            modal['modalSpinnerShow'] = false;
            this.setState(modal);
            console.error("There was an error loading car service location..., " + error)
        });
    }

    fillBookingDetails(booking, editMode:boolean){
        ;
        let fields = this.state.fields;
        let selectfields = this.state.fields;
        let checkfields = this.state.fields;
        if (booking.length > 0){            
            booking.map((prop, key) => {
                if(key === 0){
                    fields['carServiceId'] = prop["carServiceId"];
                    selectfields['serviceTypeId'] = prop["serviceTypeId"];
                    selectfields['serviceTypeDesc'] = prop["serviceTypeDesc"];
                    selectfields['carCompanyId'] = prop["carCompanyId"];
                    selectfields['carCompanyDesc'] = prop["carCompanyDesc"];
                    selectfields['carTypeId'] = prop["carTypeId"];
                    selectfields['carTypeDesc'] = prop["carTypeDesc"];
                    fields['pickUpTime'] = prop["pickUpTime"];
                    fields['AM_PM'] = prop["ampm"];
                    fields['isPhoneIncluded'] = prop["isPhoneIncluded"];
                    fields['isRateHourly'] = prop["isRateHourly"];
                    fields['rate'] = this.formatCurrency(prop["rate"]);
                    fields['estimatedTotal'] = this.formatCurrency(prop["total"]);
                    fields['passengerCount'] = prop["passengerCount"];
                    if (prop["scDescription"] !== "" || prop["scAmount"] > 0){
                        checkfields["addSplCharges"] = true;
                    }
                    else
                    {
                        checkfields["addSplCharges"] = false;
                    }
                    fields['scAmount'] = this.formatCurrency(prop["scAmount"]);
                    fields['scDescription'] = prop["scDescription"];
                    fields['bcdGenerated'] = prop["bcdGenerated"];
                    fields['bcdValidated'] = prop["bcdValidated"];
                    fields['RLN'] = prop["RLN"];
                    if (editMode){
                        fields['bcdGeneratedEditMode'] = prop["bcdGenerated"]
                    }
                    //console.log("get database PickUp Date: ", prop["pickUpDate"]);
                    //console.log("covert new date PickUp Date: ", new Date(prop["pickUpDate"]));
                    const str = new Date(prop["pickUpDate"]).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });
                    //console.log("PickUp Date Asia :", str);
                    var date;
                    date = prop["pickUpDate"].substring(0,10).split('-')
                    date = date[1] + '-' + date[2] + '-' + date[0]
                    ////console.log("getFullYear", prop["pickUpDate"].getFullYear())
                    ////console.log("getMonth", prop["pickUpDate"].getMonth())
                    ////console.log("getDate",prop["pickUpDate"].getDate())
                    //console.log("DateFormat with timezone Zero: ", new Date(date));
                    this.setState({ fields, checkfields, selectfields, pickUpDate: new Date(date)});
                    const el:HTMLElement = document.getElementById('splCharges') as HTMLElement;
                    if(checkfields["addSplCharges"]){
                        el.style.display = "block";
                    }
                    else
                    {
                        fields["scDescription"] = "";
                        fields["scAmount"] = 0;
                        el.style.display = "none";
                    }
                    this.setState({ fields, selectfields});
                    //console.log("CarService State", this.state.selectfields)
                }
            })
        }
        else{
            this.clearBookingEntryfields();
            if (editMode){
                fields['bcdGeneratedEditMode'] = 0;
            }
            this.setState({ fields});
        }
        
    }

    clearBookingEntryfields(){
        let fields = this.state.fields;
        let selectfields = this.state.fields;
        fields['carServiceId'] = 0;
        selectfields['serviceTypeId'] = "-1";
        selectfields['serviceTypeDesc'] = "";
        selectfields['carCompanyId'] = "-1";
        selectfields['carCompanyDesc'] = "";
        selectfields['carTypeId'] = "-1";
        selectfields['carTypeDesc'] = "";
        fields['pickUpTime'] = "";
        fields['AM_PM'] = "AM";
        fields['isPhoneIncluded'] = false;
        fields['isRateHourly'] = true;
        fields['rate'] = this.formatCurrency(0);
        fields['estimatedTotal'] = this.formatCurrency(0);
        fields['passengerCount'] = 0;
        fields['scAmount'] = this.formatCurrency(0);
        fields['scDescription'] = "";
        fields['bcdGenerated'] = false;
        fields['bcdValidated'] = false;
        fields['RLN'] = "";
        fields['action'] = "";
        this.setState({ fields, selectfields, pickUpDate: new Date(new Date().toISOString())});
    }

    loadReview(carServiceId){
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        this.clearError();
        let fields = this.state.fields;
        fields["review"] = true;
        this.setState({fields,modal});
        this.getBooking(carServiceId, true);
        this.getTATraveler(carServiceId, true);
        this.getCarServiceLocation(carServiceId);
    }

    cancelReview(){
        this.clearError();
        let fields = this.state.fields;
        fields["review"] = false;
        this.setState({fields});
        this.clearBookingEntryfields();
        this.setState({TATravelerTable: this.state.TATravelerList, travellerId:[]})
        this.state.TATravelerList.map((prop, key) => {
            let fields = this.state.fields;
            fields["traveller"][key] = prop['travelerId'];
            fields["resv"][key] = prop["RLN"];
            fields["comment"][key] = prop["specialInstructions"];
            this.setState({fields});
        })
        this.setState({
            values : [{carServiceLocationId:'',pickUpLocation1:'',dropOffLocation1:'',pickUpLocation2:'',dropOffLocation2:''}]
        })
    }

    async deleteBooking(carServiceId){
        var flag = await confirm("Do you want to continue?",{title:"Confirm Delete", okText:"Yes", cancelText:"No"})
        //console.log("delete flag", flag);
        if(flag){
            let commonController = new CommonController();
            commonController.deleteAuthReq("","","","",carServiceId).then(response => {
                //console.log("deleteBooking", response)
                //console.log("Delete Action Id: ", carServiceId)
                //alert("Delete action completed...")
                this.pageLoad();
            }).catch(error => {
                console.error("There was an error...", error);
            })
        }
    }

    clearError(){
        let errors = this.state.errors;
        errors["travellerId"] = "";
        errors["serviceTypeId"] = "";
        errors["carCompanyId"] = "";
        errors["carTypeId"] = "";
        errors["pickUpDate"] = "";
        errors["pickUpTime"] = "";
        errors["rate"] = "";
        errors["estimatedTotal"] = "";
        errors["passengerCount"] = "";
        errors["scAmount"] = "";
        errors["scDescription"] = "";
        errors["pickUpLocation"] = "";
        errors["dropOffLocation"] = "";
        this.setState({ errors });
    }

    validateForm() {
        this.clearError();
        let fields = this.state.fields;
        let modal = this.state.modal;
        let selectfields = this.state.selectfields;
        let errors = this.state.errors;
        let formIsValid = true;
        if(this.state.travellerId.length === 0){
            formIsValid = false;
            this.state.errors["travellerId"] = "Please select traveller";
        }
        if(selectfields["serviceTypeId"] === "-1"){
            formIsValid = false;
            errors["serviceTypeId"] = "Please Select a Service Type";
        }
        if(selectfields["carCompanyId"] === "-1"){
            formIsValid = false;
            errors["carCompanyId"] = "Please Select a Car Company";
        }
        if(selectfields["carCompanyId"] === "o~t~h~e~r" && selectfields["carCompanyDesc"] === ""){
            formIsValid = false;
            errors["carCompanyId"] = "Please enter car service company name";
        }
        if(selectfields["carTypeId"] === "-1"){
            formIsValid = false;
            errors["carTypeId"] = "Please Select a Car Type";
        }
        if(!validator.isDate(dateFormat(this.state.pickUpDate,"yyyy/mm/dd"))){
            formIsValid = false;
            errors["pickUpDate"] = "Please enter a valid date";
        }
        // if(validator.isBefore(dateFormat(this.state.pickUpDate,"yyyy/mm/dd"))){
        //     formIsValid = false;
        //     errors["pickUpDate"] = "The date you have entered has already past";
        // }
        if(!Utils.validTime(fields["pickUpTime"])){
            formIsValid = false;
            errors["pickUpTime"] = "Please enter a valid time";
        }
        var regEx = new RegExp("(((0[1-9])|(1[0-2])):([0-5][0-9]))");
        if (!regEx.test(fields["pickUpTime"]))
        {
            formIsValid = false;
            errors["pickUpTime"] = "Please enter a valid time";
        }
        var msg = Utils.validMoneyNotZero(fields["rate"].replace('$',''),"Please enter a valid rate");
        if(msg !== ""){
            formIsValid = false;
            errors["rate"] = msg;
        }
        msg = Utils.validMoneyNotZero(fields["estimatedTotal"].replace('$',''),"Please enter a valid estimated total");
        if(msg !== ""){
            formIsValid = false;
            errors["estimatedTotal"] = msg;
        }
        if(!validator.isNumeric(fields["passengerCount"].toString())){
            formIsValid = false;
            errors["passengerCount"] = "Please Enter a Valid Number of Passengers";
        }
        if(fields["passengerCount"] <= 0){
            formIsValid = false;
            errors["passengerCount"] = "Please Enter a Valid Number of Passengers";
        }
        /*
        if(fields["scDescription"] !== ""){
            msg = Utils.validMoney(fields["scAmount"],"Please Enter a Valid Special Charge");
            if(msg !== ""){
                formIsValid = false;
                errors["scAmount"] = msg;
            }
            else{
                if(parseFloat(fields["scAmount"]) === 0){
                    formIsValid = false;
                    errors["scAmount"] = "Please Enter a Special Charge";
                }
            }
        }
        msg = Utils.validMoney(fields["scAmount"],"Please Enter a Valid Special Charge");
        if(msg !== ""){
            formIsValid = false;
            errors["scAmount"] = msg;
        }
        else{
            if(parseFloat(fields["scAmount"]) !== 0 && fields["scDescription"] === ""){
                formIsValid = false;
                errors["scDescription"] = "Please Enter a Special Charge Explanation";
            }
        }*/
        this.state.values.map((prop,key)=>{
            if(prop["pickUpLocation1"] === "" && prop["pickUpLocation2"] === ""){
                formIsValid = false;
                errors["pickUpLocation"] = "Please Enter Pick Up Location";
            }
            if(prop["dropOffLocation1"] === "" && prop["dropOffLocation2"] === ""){
                formIsValid = false;
                errors["dropOffLocation"] = "Please Enter dropoff Location";
            }
        })
        if (!formIsValid){
            modal['modalSpinnerShow'] = false;
            this.setState({ modal });
        }
        this.setState({ errors });
        return formIsValid;
      }

    onSubmit(e:any){
        ;
        e.preventDefault();
        let action = e.currentTarget.id;
        let fields = this.state.fields;
        let modal = this.state.modal;
        modal["modalSpinnerShow"] = true;
        fields["action"] = action;
        let selectfields = this.state.selectfields;
        if(sessionStorage.getItem("ta_car_service_only") === "true"){
            selectfields["carCompanyId"] = sessionStorage.getItem("ta_car_service_company_id");
            selectfields["carCompanyDesc"] = sessionStorage.getItem("ta_car_service_company");
            selectfields["carServiceCompanyName"] = "Select Car Service Company";
        }
        this.setState({ fields, modal,selectfields })
        if(action === "copyResv"){
            this.userProcessCopy();
        }
        else if(action === "addResv"){
            this.cancelReview();
            modal["modalSpinnerShow"] = false;
            this.setState({ modal })
        }
        else{
            if(this.validateForm()){
                if(action === "saveResv"){
                    this.userProcessInsert();
                }
                else if(action === "saveCopyResv"  || action === "updateResv"){
                    this.userProcessUpdate();
                }
            }
        }
    }

    userProcessInsert(){
        let modal = this.state.modal;
        let carServiceController = new CarServiceController();
        var insertData = {
            authReqId: this.state.fields.sessionTA,
            carServiceCompanyId: this.state.selectfields.carCompanyId,
            carServiceCompany: this.state.selectfields.carCompanyDesc,
            serviceTypeId: this.state.selectfields.serviceTypeId,
            carTypeId: this.state.selectfields.carTypeId,
            passengerCount: this.state.fields.passengerCount,
            pickUpDate: format(this.state.pickUpDate, "MM/dd/yyyy"),  
            pickUpTime: this.state.fields.pickUpTime,
            amPm: this.state.fields.AM_PM,
            rate: parseFloat(this.state.fields.rate.replace('$','')),
            isRateHourly: this.state.fields.isRateHourly,
            isPhoneIncluded: this.state.fields.isPhoneIncluded,
            total: parseFloat(this.state.fields.estimatedTotal.replace('$','')),
            scAmount: (this.state.checkfields.addSplCharges)?this.state.fields.scAmount:0,
            scDescription: (this.state.checkfields.addSplCharges)?this.state.fields.scDescription:"",   
        };
        //console.log("saveResv insert data", insertData)
        carServiceController.userProcessInsert(insertData).then(response => {
            //console.log("saveResv insert", response)
            let fields = this.state.fields;
            fields["carServiceId"] = response.carServiceId;
            this.setState({ fields });
            this.bookingMap("","insert");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
    }

    userProcessCopy(){
        let modal = this.state.modal;
        const copyId = this.state.fields.carServiceId;
        let carServiceController = new CarServiceController();
        var copyData = {
            authReqId: this.state.fields.sessionTA,
            copyId: copyId,   
        };
        //console.log("copyResv data", copyData);
        carServiceController.userProcessCopy(copyData).then(response => {
            let fields = this.state.fields;
            fields["carServiceId"] = response.carServiceId;
            this.setState({ fields });
            this.bookingMap(copyId,"");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
    }

    userProcessUpdate(){
        let modal = this.state.modal;
        let carServiceController = new CarServiceController();
        var updateData = {
            carServiceCompanyId: this.state.selectfields.carCompanyId,
            carServiceCompany: this.state.selectfields.carCompanyDesc,
            serviceTypeId: this.state.selectfields.serviceTypeId,
            carTypeId: this.state.selectfields.carTypeId,
            passengerCount: this.state.fields.passengerCount,
            pickUpDate: format(this.state.pickUpDate, "MM/dd/yyyy"),
            pickUpTime: this.state.fields.pickUpTime,
            amPm: this.state.fields.AM_PM,
            rate: parseFloat(this.state.fields.rate.replace('$','')),
            isRateHourly: this.state.fields.isRateHourly,
            isPhoneIncluded: this.state.fields.isPhoneIncluded,
            total: parseFloat(this.state.fields.estimatedTotal.replace('$','')),
            scAmount: (this.state.checkfields.addSplCharges)?this.state.fields.scAmount:0,
            scDescription: (this.state.checkfields.addSplCharges)?this.state.fields.scDescription:"",
            carServiceId: this.state.fields.carServiceId   
        };
        //console.log("Request update: ", updateData);
        carServiceController.userProcessUpdate(updateData).then(response => {
            this.bookingMap("","");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
    }

    bookingMap = (copyId,userProcess) => {
        let modal = this.state.modal;
        var travellerIdStr = '';
        this.state.travellerId.map(prop => {
            if(travellerIdStr === ''){
                travellerIdStr = prop;
            }
            else{
                travellerIdStr = travellerIdStr + ',' + prop;
            }
        })
        //console.log("travellerIdStr", travellerIdStr);
        var bookingMapData = {
            travellerId: travellerIdStr,
            carServiceId: this.state.fields.carServiceId,
            copyId: copyId
        }
        //console.log("BookingMap data", bookingMapData);
        let carServiceController = new CarServiceController();
        carServiceController.bookingMap(bookingMapData).then(response => {
            //console.log("Booking map response", response);
            if(copyId === ''){
                this.state.travellerId.map((prop,key) => {
                    var i = this.state.fields.traveller.indexOf(parseInt(prop));
                    var updateData = {
                        travellerId: prop,
                        carServiceId: this.state.fields.carServiceId,
                        specialIns: this.state.fields.comment[i]
                    }
                    //console.log("updatedata", updateData)
                    carServiceController.updateSpecialIns(updateData).then(response => {
                        //console.log("updateSpecialIns", response)
                        if(this.state.travellerId.length -1 === key){
                            this.carServiceLocation(copyId,userProcess)
                        }
                    }).catch(error => {
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});
                        console.error("There was an error...", error);
                    })
                })
            }
            else{
                this.carServiceLocation(copyId,userProcess)
            }
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
    }

    carServiceLocation = (copyId, userProcess) => {
        let carServiceController = new CarServiceController();
        let modal = this.state.modal;
        if(copyId === ''){
            this.state.values.map((prop,key) => {
                var locationData = {
                    carServiceId: this.state.fields.carServiceId,
                    carServiceLocationId: prop["carServiceLocationId"],
                    p1: prop["pickUpLocation1"],
                    d1: prop["dropOffLocation1"],
                    p2: prop["pickUpLocation2"],
                    d2: prop["dropOffLocation2"],
                    copyId: copyId,
                    userProcess: userProcess
                }
                //console.log("locationData", locationData)
                carServiceController.carServiceLocation(locationData).then(response => {
                    //console.log("locationData response", response);
                    if(this.state.values.length -1 === key){
                        if(this.state.fields.action === "saveCopyResv"){
                            let fields = this.state.fields;
                            fields["action"] = ''
                            this.setState({fields});
                            this.userProcessCopy();
                        }
                        else{
                            //console.log("Car Service Booking Id: ", this.state.fields.carServiceId)
                            //alert("Car Service Booking completed successfully...")
                            this.cancelReview();
                            this.pageLoad();
                            modal["modalSpinnerShow"] = false;
                            this.setState({modal});
                        }
                    }
                }).catch(error => {
                    modal["modalSpinnerShow"] = false;
                    this.setState({modal});
                    console.error("There was an error...", error);
                })
            })
        }
        else{
            var locationData = {
                carServiceId: this.state.fields.carServiceId,
                carServiceLocationId: '',
                p1: '',
                d1: '',
                p2: '',
                d2: '',
                copyId: copyId,
                userProcess: ''
            }
            //console.log("locationData", locationData)
            carServiceController.carServiceLocation(locationData).then(response => {
                //console.log("locationData response", response);
                //console.log("Car Service Booking Id: ", this.state.fields.carServiceId)
                alert("Car Service Booking completed successfully...")
                this.clearBookingEntryfields();
                this.pageLoad();
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error...", error);
            })
        }
    }

    loadTravelerReservation(){
        return <div id="divReservation" style={{width: '100%'}}>
            <Row>
                <Col sm={12}> 
                    {this.state.carServiceId.map((prop, key) => {
                        var count = 0;
                        return this.state.fields.carServiceId !== prop["carServiceId"] ?(
                            <table style={{width:"100%"}}>
                                <tbody>
                                    {this.loadTravelerReservationHeader()}
                                    {this.state.Booking.map((el, i) => {
                                        var name = '';
                                        this.state.traveller.map((item,j) => {
                                            if(el["carServiceId"] === item["id"]){
                                                if(name === ''){
                                                    name = item["name"];
                                                }
                                                else{
                                                    name = name +" / "+ item["name"];
                                                }
                                            }
                                        })
                                        if(prop["carServiceId"] === el["carServiceId"]){
                                            count = count + 1;
                                        }
                                        return prop["carServiceId"] === el["carServiceId"] && count <= 1 ? this.loadTravelerReservationBody(el,i,name) : <></>
                                    })}
                                </tbody>
                            </table>
                        )
                        : <></>        
                    })}  
                </Col>
            </Row>
        </div>
    }

    loadTravelerReservationHeader(){
        return <tr style={{width:"100%", borderTop:"1px solid navy"}}>
            <td style={{width:"13%"}}><b>Service Type</b></td>
            <td style={{width:"12%"}}><b>Car Company</b></td>
            <td style={{width:"10%"}}><b>Car Type</b></td>
            <td style={{width:"10%"}}><b>Pick Up Date</b></td>
            <td style={{width:"10%"}}><b>Pick Up Time</b></td>
            <td style={{width:"10%"}}><b>Traveler</b></td>
            <td style={{width:"10%"}}><b>Resv Type</b></td>
            <td style={{width:"8%"}}><b>Resv #</b></td>
            <td style={{width:"17%"}}></td>
        </tr>
    }

    loadTravelerReservationBody(prop,key, name){
        return <tr style={{width:"100%"}}>
            <td style={{width:"13%"}}>{prop["serviceTypeDesc"]}</td>
            <td style={{width:"12%"}}>{prop["carCompanyDesc"]}</td>
            <td style={{width:"10%"}}>{prop["carTypeDesc"]}</td>
            <td style={{width:"10%"}}>{Utils.FormatDate(prop["pickUpDate"])}</td>
            <td style={{width:"10%"}}>{prop["pickUpTime"]+' '+prop["ampm"]}</td>
            <td style={{width:"10%"}}>{name}</td>
            <td style={{width:"10%"}}>{!prop["bcdGenerated"] ?"Manual":"BCD"}</td>
            <td style={{width:"8%"}}>{prop["RLN"]}</td>
            <td style={{textAlign:"right"}}>
                <Button type="button" variant="danger" className="btn btn-danger btn-sm" value="Delete" title="Click to delete this reservation" id="buttonDelete" name="buttonDelete" onClick={(e) => this.deleteBooking(prop["carServiceId"])}>Delete</Button> &nbsp;
                <Button type="button" variant="secondary" className="btn btn-secondary btn-sm" style={{marginLeft:"2px"}} value="Review" title="Click to edit this reservation" id="buttonReview" name="buttonReview" onClick={(e) => this.loadReview(prop["carServiceId"])}>Review</Button>
            </td>
        </tr>
    }

    displayTravelerMsg(){
        let fields=this.state.fields;
        if (this.state.TATravelerList.length === 0){ fields["iTravelerCnt"] = -1; } 
        if (fields["iTravelerCnt"] > 0 || fields["iTravelerCnt"] <= 0){
            if(this.state.fields.iTravelerCnt === -1){
                 return <div id="divTravelerMsg" className="text-dark font-size-xs small">Click on the Traveler tab to add a traveler.</div>
            }
            else
            {
                return <div id="divTravelerMsg" style={{color:"red",textAlign:"center"}} className="text-dark font-size-xs small">Click on a traveler to add to reservation and enter airfare amount.</div>
            }
        }
        else
        {
            return <></>
        }
    }

    buttonControl(){
        const { loading } = this.state;
        if(this.state.fields.carServiceId !== 0 && this.state.fields.review){
            return <Row>
                <Col sm={3}></Col>
                <Col sm={6}>
                    <Button id="updateResv" style={{ marginRight: "5px"}} type="submit" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Save Resv</span>}
                    </Button>
                    <Button id="addResv" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Add Resv</span>}
                    </Button>
                    <Button id="copyResv" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Copy Resv</span>}
                    </Button>
                    <Button id="saveCopyResv" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Save & Copy Resv</span>}
                    </Button>
                    <Button id="deleteResv" type="button" size="sm" disabled={loading} style={{ display:"none", marginRight: "5px" }} onClick={(e) => this.deleteBooking(this.state.fields.carServiceId)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa", display:"none"}}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Delete Resv</span>}
                    </Button>
                    <Button id="cancelReview" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.cancelReview()}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Cancel Review</span>}
                    </Button>
                </Col>
                <Col sm={3}></Col>
            </Row>
        }
        else{
            return <Row>
                <Col sm={5}></Col>
                <Col sm={2}>
                    <Button id="saveResv" style={{ marginRight: "5px"}} type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{color:"#487afa" }}
                        />
                        )}
                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                        {!loading && <span>Save Resv</span>}
                    </Button>
                </Col>
                <Col sm={5}></Col>
            </Row>
        } 
    }

    tableSpecialCharge(text){
	    return <Row id="splCharges" style={{display:"none"}}>
            <Col sm={12} style={{alignSelf:"right"}}>         
                <table style={{border:"1px",padding:"0px"}}>
                    <tr>
                        <td>
                            <table style={{border:"1px",padding:"0px",borderSpacing:"2px"}}>
                                <tr>
                                    <td colSpan={2} style={{textAlign:"center",fontSize: "12px"}}>FOR SPECIAL CHARGES ONLY</td>
                                </tr>
                                <tr>
                                    <td style={{width:"85%",fontSize: "12px"}}>Description {text}</td>
                                    <td style={{width:"15%",alignSelf:"right",fontSize: "12px"}}>Amount</td>
                                </tr>
                                <tr>
                                    <td style={{alignSelf:"top"}}>
                                        <FormControl style={{height: 'auto'}} size="sm" id="scDescription" as="textarea" name="scDescription" className="form-control input-sm" value={this.state.fields.scDescription} onChange={(e) => this.handleChange(e)} ></FormControl>
                                        {this.state.errors.scDescription !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.scDescription}</div> : <></> } 
                                    </td>
                                    <td style={{alignSelf:"top",textAlign:"right"}}>
                                        <FormControl style={{height: 'auto',textAlign:"right"}} maxLength={13} size="sm" id="scAmount" type="text" name="scAmount" className="form-control input-sm" value={this.state.fields.scAmount} onChange={(e) => this.handleChange(e)} ></FormControl>
                                        {this.state.errors.scAmount !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.scAmount}</div> : <></> } 
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </Col>
        </Row>
    }

    formTraveller(prop, key){ 
        return <tr id={"div"+prop['travelerId']} style={{ display: !prop['bit'] ? "none" : ""}}>
            <td style={{padding: '0px',width:'250px'}}><FormLabel style={{height: 'auto'}}>{prop['name']}</FormLabel></td>
            <td style={{padding: '0px',width:'150px'}}><FormLabel style={{height: 'auto'}}>{prop['RLN']}</FormLabel></td>
            <td style={{padding: '0px',width:'200px'}}><FormControl style={{height: 'auto'}} size="sm" type="text" id="comment" name="comment" className="form-control input-sm" value={this.state.fields.comment[key]} onChange={(e) => this.handleFormChange(key, e)}></FormControl></td>
        </tr>       
    }

    CreateDynamicUI(){
        var cnt = this.state.values.length - 1;
        return this.state.values.map((el, elkey) => 
                <div id={"div"+el.carServiceLocationId} key={elkey}>
                    <Row>
                        <Col sm={2}></Col>
                        <Col sm={1} className="text-dark font-size-xs small">Pick Up {elkey+1}</Col>
                        <Col sm={3} className="text-dark font-size-xs small">
                            <FormControl style={{height: '28px'}} size="sm" id="pickUpLocation1" type="text" name="pickUpLocation1" className="form-control input-sm" value={el.pickUpLocation1} onChange={(e) => this.handleChangeEvent(e, elkey)} ></FormControl>
                        </Col>
                        <Col sm={1} className="text-dark font-size-xs small">Drop Off {elkey+1}</Col>
                        <Col sm={3} className="text-dark font-size-xs small">
                            <FormControl style={{height: '28px'}} size="sm" id="dropOffLocation1" type="text" name="dropOffLocation1" className="form-control input-sm" value={el.dropOffLocation1} onChange={(e) => this.handleChangeEvent(e, elkey)} ></FormControl>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                    <Row style={{marginBottom:"15px"}}>
                        <Col sm={3}></Col>
                        <Col sm={3} className="text-dark font-size-xs small">
                            <FormControl style={{height: '28px'}} size="sm" id="pickUpLocation2" type="text" name="pickUpLocation2" className="form-control input-sm" value={el.pickUpLocation2} onChange={(e) => this.handleChangeEvent(e, elkey)} ></FormControl>
                        </Col>
                        <Col sm={1} className="text-dark font-size-xs small"></Col>
                        <Col sm={3} className="text-dark font-size-xs small">
                            <FormControl style={{height: '28px'}} size="sm" id="dropOffLocation2" type="text" name="dropOffLocation2" className="form-control input-sm" value={el.dropOffLocation2} onChange={(e) => this.handleChangeEvent(e, elkey)} ></FormControl>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                    {(elkey === cnt) ?
                        <Row>
                            <Col sm={3}></Col>
                            <Col sm={3}>{this.state.errors.pickUpLocation !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.pickUpLocation}</div> : <></> } </Col>
                            <Col sm={1} className="text-dark font-size-xs small"></Col>
                            <Col sm={3}>{this.state.errors.dropOffLocation !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.dropOffLocation}</div> : <></> } </Col>
                            <Col sm={2}></Col>
                        </Row>
                    :
                        <div></div>
                    }   
                    {(elkey === cnt) ? 
                        <Row style={{marginTop:"10px"}}><Col sm={6} style={{alignSelf: "flex-end"}}><Form.Group><Button type="button" className="btn btn-primary btn-sm" title="Click to add a Location" value="Add Location" id="addButton" name="addButton"  onClick={this.addLocation.bind(this)}>Add Location</Button></Form.Group></Col></Row> 
                    :
                        <div></div>
                    }
                        
                </div>
            )
    }

    formControl = () => {
        return <div style={{borderBottom:"1px solid #E3E3E3"}}>
            <Row>
                <Col sm={3}>
                    <FormGroup>
                        <FormLabel>Service Type<span className="text-danger" style={{fontSize:'16px'}}>*</span></FormLabel>
                        <InputGroup>
                        <FormControl as="select" id="serviceTypeId" name="serviceTypeId" value={this.state.selectfields.serviceTypeId} className="form-control form-control-sm" onChange={this.handleSelectChange}>
                            {this.state.ServiceType.map((prop, key) => {
                                return(
                                    <option selected={true} value={prop["id"]}>{prop["name"]}</option>
                                )
                            })}
                        </FormControl>
                        <InputGroup.Append>
                            <Button type="button" className="btn btn-primary btn-sm" value="Rates" id="rates" name="rates" onClick={(e) => this.showRates()}>Rates</Button>
                        </InputGroup.Append>
                        </InputGroup>
                        {this.state.errors.serviceTypeId !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.serviceTypeId}</div> : <></> }
                    </FormGroup>
                </Col>
                <Col sm={2}>
                {(sessionStorage.getItem("ta_car_service_only") === "true")?
                        <Form.Group>
                            <Form.Label>Car Company</Form.Label>
                            <Form.Control plaintext readOnly defaultValue={this.state.selectfields.carCompanyDesc}></Form.Control>
                        </Form.Group> 
                        :
                        <Form.Group>
                            <Form.Label>Car Company<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                            <Form.Control as="select" id="carCompanyId" name="carCompanyId" value={this.state.selectfields.carCompanyId} className="form-control form-control-sm" onChange={this.handleSelectChange}>
                                <option selected={true} value="-1">Select Car Company</option>
                                {this.state.ServiceCompany.map((prop, key) => {
                                    return(
                                        <option selected={true} value={prop["id"]}>{prop["name"]}</option>
                                    )
                                })}
                                <option selected={true} value="o~t~h~e~r">Other Car Company</option>
                            </Form.Control>
                            {this.state.selectfields.carCompanyId === "o~t~h~e~r" ? 
                            <FormInputs properties={{label:"Name",type:"text",id:"name",name:"name",className:"form-control input-sm form-control-sm",maxlength:13,value:this.state.selectfields.carServiceDesc,onChange:this.handleChange}}
                            fieldRequired={false}
                            />
                            :<></>}
                            {this.state.errors.carCompanyId !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.carCompanyId}</div> : <></> }
                        </Form.Group>
                    }
                </Col>
                <Col sm={2}>
                    <Form.Group>
                        <Form.Label>Car Type<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                        <Form.Control as="select" id="carTypeId" name="carTypeId" value={this.state.selectfields.carTypeId} className="form-control form-control-sm" onChange={this.handleSelectChange}>
                            {this.state.Type.map((prop, key) => {
                                return(
                                    <option selected={true} value={prop["id"]}>{prop["name"]}</option>
                                )
                            })}
                        </Form.Control>
                        {this.state.errors.carTypeId !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.carTypeId}</div> : <></> }
                    </Form.Group>
                </Col>
                <Col sm={2}>
                    <Form.Label>Pick Up(MM/DD/YYYY)<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label> 
                    <Form.Group>             
                        <DatePicker style={{fontSize:"12px"}} 
                        Id="pickUpDate"
                        selected={this.state.pickUpDate}
                        onChange={this.handleChangePickUpDate}
                        isClearable
                        showMonthDropdown
                        showYearDropdown                            
                        className="datePicker form-control form-control-sm"/>
                        {this.state.errors.pickUpDate !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.pickUpDate}</div> : <></> } 
                    </Form.Group>
                </Col>
                <Col sm={1.5}> 
                    <Form.Group>
                        <FormInputs
                            properties={{id:"pickUpTime", label:"Pick Up Time (CST)",type:"text",placeholder:"12:00",name:"pickUpTime",className:"form-control input-sm form-control-sm",maxlength:5,value:this.state.fields.pickUpTime,onChange:this.handleChange,onBlur:this.handleTimeFormat}}
                            fieldRequired={true}
                            errorMsg={this.state.errors.pickUpTime}
                        />
                    </Form.Group>
                </Col>
                <Col sm={1.5}>
                    <Form.Label></Form.Label> 
                    <Form.Group>
                        <Row style={{alignItems: "flex-end", paddingLeft: "0px"}}>
                            <Col sm={6}><Form.Check type="radio" label="AM" name="bAM"  id="bAM" onChange={(e)=>this.handleOptionChange(e)} title="AM" checked={this.state.fields.AM_PM === "AM" ? true : false} value="AM"/></Col>
                            <Col sm={6}><Form.Check type="radio" label="PM" name="bPM"  id="bPM" onChange={(e)=>this.handleOptionChange(e)} title="PM" checked={this.state.fields.AM_PM === "PM" ? true : false} value="PM"/></Col>
                        </Row>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={1} style={{alignSelf: "flex-end"}}>

                </Col>
                <Col sm={3}></Col>
                <Col sm={2} style={{display:"none"}}>
                    <Form.Label>Phone Usage</Form.Label> 
                    <Form.Group>
                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem"}}>
                            <Col sm={4}><Form.Check type="radio" label="Yes" name="bYes"  id="bYes" onChange={(e)=>this.handleOptionChange(e)} title="Phone Usage Included" checked={this.state.fields.isPhoneIncluded} value="1"/></Col>
                            <Col sm={4}><Form.Check type="radio" label="No" name="bNo"  id="bNo" onChange={(e)=>this.handleOptionChange(e)} title="Phone Usage NOT Included" checked={!this.state.fields.isPhoneIncluded} value="0"/></Col>
                            <Col sm={4}></Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col sm={1}>
                    <FormInputs
                        properties={{id:"rate",label:"Car Rate",type:"text",name:"rate",className:"form-control input-sm form-control-sm",maxlength:13,value:this.state.fields.rate,onChange:this.handleChange}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.rate}
                    />
                </Col>
                <Col sm={2}>
                    <Form.Label></Form.Label> 
                    <Form.Group>
                        <Row style={{alignItems: "flex-end", paddingLeft: "1rem"}}>
                            <Col sm={6}><Form.Check type="radio" label="Hour" name="bHour"  id="bHour" onChange={(e)=>this.handleOptionChange(e)} title="Hour" checked={this.state.fields.isRateHourly} value="1"/></Col>
                            <Col sm={6}><Form.Check type="radio" label="Flat" name="bFlat"  id="bFlat" onChange={(e)=>this.handleOptionChange(e)} title="Flat" checked={!this.state.fields.isRateHourly} value="0"/></Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col sm={2}>
                    <FormInputs
                        properties={{id:"estimatedTotal",label:"Estimated Total",type:"text",name:"estimatedTotal",className:"form-control input-sm form-control-sm",maxlength:13,value:this.state.fields.estimatedTotal,onChange:this.handleChange,style:{"backgroundColor":(this.state.fields.review && (this.state.fields.estimatedTotal==='0.00' || this.state.fields.estimatedTotal==='$0.00' ))?"yellow" : "#ffffff"}}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.estimatedTotal}
                    />
                </Col>
                <Col sm={2}>
                    <FormInputs
                        properties={{id:"passengerCount",label:"Passenger Count",type:"text",name:"passengerCount",className:"form-control input-sm form-control-sm",maxlength:2,value:this.state.fields.passengerCount,onChange:this.handleChange}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.passengerCount}
                    />
                </Col>
            </Row>
        </div>
    }

    displayCarService = () => { 
        return <div> 
            <Row>
                  <Col sm={12} style={{textAlign:"right"}}>
                    <Button id="print" type="button" size="sm" onClick={() => window.print()} >
                        Print
                    </Button>
                  </Col>
            </Row>  
            <Row>
                <Col sm={3}>
                    <div style={{fontSize:'7pt',fontWeight:'bold'}}><b>MANUAL RESERVATIONS ONLY</b><span className="text-danger" style={{fontSize:'16px'}}>*</span><br/>Select Traveler</div>
                    <Form.Group>
                        <Form.Control as="select" multiple={true} id="travellerId" name="travellerId" value={this.state.travellerId} className="form-control form-control-sm" onChange={this.handleSelectMultipleChange}>
                            {this.state.TATravelerList.map((prop, key) => {
                                return(
                                    <option selected={true} value={prop["travelerId"]}>{prop["name"] + ' ' + prop["RLN"]}</option>
                                )
                            })}
                        </Form.Control>
                        <div style={{fontSize:'7pt',fontWeight:'bold'}} >(Ctrl to select multiple)</div>
                        {this.state.errors["travellerId"] !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors["travellerId"]}</div> : <></> }
                    </Form.Group>
                </Col>
                <Col sm={9}></Col>
            </Row>    
            <div style={{borderBottom: "1px solid rgb(227, 227, 227)",marginBottom:"15px"}}></div>  
            {this.formControl()}
            <Row>
                <Col sm={4}></Col>
                <Col sm={4} className="text-dark font-size-xs small font-weight-bold">Pick Up Location<span className="text-danger" style={{fontSize:'16px'}}>*</span></Col>
                <Col sm={4} className="text-dark font-size-xs small font-weight-bold">Drop off Location<span className="text-danger" style={{fontSize:'16px'}}>*</span></Col>
                <Col sm={4}></Col>
            </Row>
            {this.CreateDynamicUI()}
            <div style={{borderBottom:"1px solid #E3E3E3"}}></div>
            <Row>
                <Col sm={5}>
                    <div style={{color: "#FF4A55", marginTop:"15px"}}>
                        <FormCheckBox
                            properties={{label:"Add Special Charges",id:"addSplCharges",name:"addSplCharges",type:"checkbox",className:"form-control-md",onChange:this.handleCheckChange,checked:this.state.checkfields.addSplCharges}}
                        />
                    </div>
                </Col>             
            </Row>
            <Row>
                <Col sm={9}>
                <div style={{width: '100%'}}>
                    <Table id="travelerTable" hover>                    
                        <thead style={{backgroundColor:'#00B0F0', fontSize: 'large', height:'11px'}}>
                        <tr>
                            <th>Traveler</th>
                            <th>Resv #</th>
                            <th>Comment</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.TATravelerTable.map((prop, key) =>
                            this.formTraveller(prop, key) 
                        )}
                        </tbody>
                    </Table>
                </div>
                </Col>
                <Col sm={3}>
                    {this.tableSpecialCharge("(Greeter services, etc.)")}
                </Col>
            </Row>
            {this.buttonControl()}
            {/* commented as iTravellerCount not needed 
            {this.displayTravelerMsg()}*/}
            <div id="divClicktoReview" style={{marginTop:"15px"}}>
                {(this.state.Booking.length > 0) ? 
                    <Row>
                        <Col sm={12}>
                            <div className="text-yellow font-size-xs small" style={{backgroundColor:"navy",color: "yellow",textAlign:"center"}}>Click the Review button to complete each traveler's reservation</div>
                        </Col>
                    </Row> : ""}
            </div>
            {this.loadTravelerReservation()}
            <div id="divReviewComplete" style={{borderTop:(this.state.Booking.length > 0) ?"1px solid navy": "0px"}}>
                {(this.state.Booking.length > 0) ? 
                    <Row>
                        <Col sm={12}>
                            <div className="text-danger font-size-xs small" style={{textAlign:"center",fontStyle:"bold"}}>REVIEW/COMPLETED TRAVEL SECTION</div>
                        </Col>
                    </Row> : ""}
            </div>
        </div>  
    }

    codingTab(){
        if(sessionStorage.getItem("page") === "submitter" && parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && (parseInt(sessionStorage.getItem("ta_tier_code")!) !== 0 || sessionStorage.getItem("ta_is_corporate_card") === "true")){
            return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for summary page" label="Print"></Tab>
        }
        if(sessionStorage.getItem("page") === "approver" || sessionStorage.getItem("page") === "submitter"){
            if(parseInt(sessionStorage.getItem("ta_status_id")!) !== 1 || (parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && parseInt(sessionStorage.getItem("ta_tier_code")!) === 0 || sessionStorage.getItem("ta_is_corporate_card") === "false")){
                return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for coding page" label="Coding"></Tab>
            }    
        }     
    }

    decisionTabTAHistoryResubmit(){
        ;
        if(sessionStorage.getItem("ta_status_desc") === "Re-Submit"){
           return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision"></Tab>           
        }
        else
        {
           return <Tab headerClass="nav-link disabled" style={{color:"white"}} disabled></Tab>
        }
    }

    redirectToPage(tabLabel){
        ;
        //console.log('Page:',tabLabel)
        switch (tabLabel) {
            case 'Traveler':
                this.props.history.push('/admin/traveller');
                break;
            case 'Air / Rail':
                this.props.history.push('/admin/airrail');
                break;
            case 'Hotel':
                this.props.history.push('/admin/hotel');
                break;
            case 'Rental Car':
                this.props.history.push('/admin/rentalcar');
                break;
            case 'Car Service':
                this.props.history.push('/admin/carservice');
                break;
            case 'Print':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:""}});
                break;
            case 'Coding':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:""}});
                break;
            case 'Decision':
                this.props.history.push({pathname:'/admin/decision',state:{from:"tahistory"}});
                break;
        }
    }

    render() {
        const{ loading } = this.state;
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <Card.Body>
                            <Form autoComplete='off'>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <AuthStatus/>
                                <section style={{marginTop:"10px"}}>
                                    <div style={{textAlign:"right"}}>
                                        <SubmitButton 
                                            loading={this.state.loading} 
                                            redirect={this.props.history}
                                        />
                                    </div>
                                    {(sessionStorage.getItem("ta_car_service_only") === "true")?
                                        <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={1} mountOnExit>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for traveler page" label="Traveler"></Tab>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for car service page" label="Car Service">{this.displayCarService()}</Tab>
                                            {this.codingTab()}
                                            {this.decisionTabTAHistoryResubmit()}
                                        </Tabs>
                                        :
                                    <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={4} mountOnExit>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for traveler page" label="Traveler"></Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for air/rail page" label="Air / Rail"></Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for hotel page" label="Hotel"></Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for rental car page" label="Rental Car"></Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for car service page" label="Car Service">{this.displayCarService()}</Tab>
                                        {this.codingTab()}
                                        {this.decisionTabTAHistoryResubmit()}
                                    </Tabs>
                                    }
                                </section>
                            </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}